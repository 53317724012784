import { useFormContext } from "react-hook-form";
import { useCallback } from "react";

import { useCategories } from "#menu/hooks";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "src/@/components/ui/select";
import { CreatePromotion } from "src/api/graphql/generated/types";
import { invariant } from "src/lib/invariant";

import { ItemsCountInput } from "./ItemsCountInput";
import { ConditionScopeInput } from "./ConditionScopeInput";

export type CategoryCountConditionInputProps = {
    index: number;
};

export function CategoryCountConditionInput({
    index,
}: CategoryCountConditionInputProps) {
    const categories = useCategories();
    const { watch, setValue } = useFormContext<CreatePromotion>();

    const condition = watch(`conditions.${index}.categoryCountCondition`);

    invariant(
        condition,
        "CategoryCountConditionInput should be used with a categoryCountCondition",
    );

    const onChangeCategory = useCallback(
        (category: string) =>
            setValue(
                `conditions.${index}.categoryCountCondition.category`,
                category,
            ),
        [index, setValue],
    );

    const onChangeCategoryCount = useCallback(
        (count: number) => {
            setValue(`conditions.${index}.categoryCountCondition.count`, count);
        },
        [index, setValue],
    );

    return (
        <div className="space-y-2">
            <ConditionScopeInput
                condition="categoryCountCondition"
                index={index}
            />
            <div className="flex items-center justify-between">
                <span>Category</span>
                <Select
                    value={condition.category}
                    onValueChange={onChangeCategory}
                >
                    <SelectTrigger className="w-40">
                        <SelectValue placeholder="Select Discount" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="">Select Category</SelectItem>
                        {categories.map(({ name }) => (
                            <SelectItem key={name} value={name}>
                                {name}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
            </div>
            <div className="flex items-center justify-between">
                <span>Limit</span>
                <ItemsCountInput
                    value={condition.count}
                    onChange={onChangeCategoryCount}
                />
            </div>
        </div>
    );
}
