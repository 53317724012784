import { match, P } from "ts-pattern";
import { useFormContext } from "react-hook-form";
import { memo } from "react";

import { FormItem } from "src/@/components/ui/form";
import { CreatePromotion } from "src/api/graphql/generated/types";

import { MaxItemsConditionInput } from "./MaxItemsConditionInput";
import { ItemCountConditionInput } from "./ItemCountConditionInput";
import { CategoryCountConditionInput } from "./CategoryCountConditionInput";

export type SpecialConditionRowProps = {
    index: number;
};

export const SpecialConditionRow = memo(
    ({ index }: SpecialConditionRowProps) => {
        const { watch } = useFormContext<CreatePromotion>();

        const condition = watch(`conditions.${index}`);

        return (
            <FormItem>
                {match(condition)
                    .with({ maxItemsCondition: P.not(P.nullish) }, () => (
                        <MaxItemsConditionInput index={index} />
                    ))
                    .with({ itemCountCondition: P.not(P.nullish) }, () => (
                        <ItemCountConditionInput index={index} />
                    ))
                    .with({ categoryCountCondition: P.not(P.nullish) }, () => (
                        <CategoryCountConditionInput index={index} />
                    ))
                    .otherwise(() => {
                        throw new Error(`Invalid condition: ${condition}`);
                    })}
            </FormItem>
        );
    },
);
