import { ColumnDef } from "@tanstack/react-table";
import { ControllerRenderProps } from "react-hook-form";
import { TrashIcon } from "@radix-ui/react-icons";
import { memo, useCallback } from "react";

import { DataTable } from "src/@/components/ui/data-table";
import {
    CreatePromotionCondition,
    CreatePromotion,
} from "src/api/graphql/generated/types";
import { Button } from "src/@/components/ui/button";

import { SpecialConditionRow } from "./SpecialConditionRow";
import { AddConditionButton } from "./AddConditionButton";
import { ConditionHeader } from "./ConditionHeader";

export type SpecialConditionsInputProps = ControllerRenderProps<
    CreatePromotion,
    "conditions"
>;

export const SpecialConditionsInput = memo(
    ({ value, onChange }: SpecialConditionsInputProps) => {
        const addCondition = useCallback(
            (condition: CreatePromotionCondition) => {
                onChange([...value, condition]);
            },
            [onChange, value],
        );

        const removeCondition = useCallback(
            (index: number) => {
                onChange(value.toSpliced(index, 1));
            },
            [onChange, value],
        );

        const columns: ColumnDef<CreatePromotionCondition>[] = [
            {
                header: "Condition",
                id: "name",
                accessorKey: "name",
                cell: ConditionHeader,
            },
            {
                header: "Configurations",
                id: "configuration",
                accessorKey: "configuration",
                cell: ({ row }) => <SpecialConditionRow index={row.index} />,
            },
            {
                header: "",
                id: "actions",
                cell: ({ row }) => (
                    <div className="flex justify-end">
                        <Button
                            className="bg-critical-light"
                            type="button"
                            onClick={() => removeCondition(row.index)}
                        >
                            <TrashIcon className="h-4 w-4" />
                        </Button>
                    </div>
                ),
            },
        ];

        return (
            <div>
                <DataTable
                    columns={columns}
                    data={value}
                    emptyText="No Conditions"
                />
                <AddConditionButton onAddCondition={addCondition} />
            </div>
        );
    },
);
