import { PaymentProvider } from "@snackpass/snackpass-types";
import { PaymentMethod } from "#sales-report/types";

export const MAX_DATE_RANGE = 10;

// this is a temporary mapping until we update the backend reports
// to use the new payment method names
type Null = "null";
export function mapPaymentMethodToPaymentProviderId(
    paymentMethods: PaymentMethod[],
): (PaymentProvider | Null)[] {
    return paymentMethods.flatMap((e): (PaymentProvider | Null)[] => {
        if (e === PaymentMethod.cash) {
            return [PaymentProvider.inStore];
        }
        if (e === PaymentMethod.creditDebit) {
            return [
                PaymentProvider.stripe,
                PaymentProvider.checkout,
                PaymentProvider.giftCards,
                // many purchases are labeled null. they are unpaid orders and should be considered credit card
                "null",
                // these are legacy pre 2018 fields
                // PaymentProvider.paypal,
                // PaymentProvider.adjustment,
                // PaymentProvider.square,
                // PaymentProvider.ocmp,
            ];
        }
        if (e === PaymentMethod.unpaid) {
            return [PaymentProvider.unpaid];
        }
        if (e === PaymentMethod.other) {
            return [PaymentProvider.thirdParty];
        }
        return [e];
    });
}
