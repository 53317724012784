import { IProduct } from "@snackpass/snackpass-types";
import { ColumnDef } from "@tanstack/react-table";
import { match, P } from "ts-pattern";

import { DataTableColumnHeader } from "src/@/components/ui/data-table/table-column-header";
import { PromotionFieldsFragment } from "src/api/graphql/generated/types";
import { DeletePromotionButton } from "#new-promotions/components/DeletePromotionButton";
import { datetimeFormat } from "#utils/datetime-format";

import { formatAdjustment } from "./formatAdjustment";

export type BuildColumnsParams = {
    productMap: Record<string, Pick<IProduct, "name">>;
};

export const buildColumns = ({
    productMap,
}: BuildColumnsParams): ColumnDef<PromotionFieldsFragment>[] => [
    {
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Name" />
        ),
        id: "name",
        cell: (props) => props.row.original.name,
        filterFn: (row, _, value) =>
            row.original.name.toLowerCase().includes(value.toLowerCase()) ||
            row.original.adjustments
                .map(formatAdjustment)
                .join(", ")
                .toLowerCase()
                .includes(value.toLowerCase()),
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Discount" />
        ),
        id: "adjustments",
        cell: (props) =>
            props.row.original.adjustments.map(formatAdjustment).join(", "),
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Trigger" />
        ),
        id: "trigger",
        cell: (props) =>
            match(props.row.original.trigger)
                .with(
                    { productId: P.string },
                    ({ productId }) =>
                        productMap[productId]?.name ?? "Unknown Product",
                )
                .otherwise(() => "Unknown trigger"),
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Status" />
        ),
        id: "isRunning",
        cell: (props) => (props.row.original.isRunning ? "Active" : "Inactive"),
    },
    {
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Created At" />
        ),
        id: "createdAt",
        cell: (props) =>
            datetimeFormat(props.row.original.createdAt, "short-medium"),
    },
    {
        id: "actions",
        cell: ({ row }) => (
            <div className="flex justify-end">
                <DeletePromotionButton
                    id={row.original.id}
                    name={row.original.name}
                />
            </div>
        ),
    },
];
