import {
    IProduct,
    PrinterWithPrepStation,
    IProductCategory,
} from "@snackpass/snackpass-types";
import { AxiosResponse } from "axios";
import {
    EsperCommandResponseType,
    EsperDeviceInfo,
} from "src/api/rest/esper/types";
import {
    BaseStoreDevice,
    DeviceType,
    MDMProvider,
    NetworkReportPeriod,
    StoreDevice,
} from "#devices/utils/deviceTypes";
import { ErrorWithCause } from "src/utils/errors";
import { RotationState } from "#devices/utils/deviceTypes/DeviceStats";

import { client } from "../client";

export const REPORTS_REQUEST_TIMEOUT = 120 * 1000;

export type BaseStoreDeviceWithNetworkStatus = BaseStoreDevice & {
    latestNetworkStatus?: NetworkReportPeriod["status"];
};

type ListStoreDevicesResponse = {
    data: {
        devices: BaseStoreDeviceWithNetworkStatus[];
    };
};

export type SummarizedDevice = Pick<
    BaseStoreDevice,
    "id" | "name" | "storeId" | "serial" | "snackId" | "deviceType"
>;
type ListDevicesForInternalResponse = {
    data: {
        devices: SummarizedDevice[];
    };
};
type GetDeviceNetworkConnectivityReportResponse = {
    data: { report: NetworkReportPeriod[] };
};
type GetStoreDeviceResponse = { data: { device: StoreDevice } };
type GetPrepStationFieldsResponse = {
    data: {
        printers: PrinterWithPrepStation[];
        categories: IProductCategory[];
        products: IProduct[];
        productToCategoriesMap: { [productId: string]: string };
    };
};
type CheckValidIdResponse = { data: { valid: boolean } };
type CreateStoreDeviceResponse = { data: { device: BaseStoreDevice } };
type UpdateStoreDeviceResponse = {
    data: { device: StoreDevice; rebootSuccess?: boolean };
};
type DeleteStoreDeviceResponse = { data: { device: BaseStoreDevice } };
type RestartStoreDeviceResponse = { data: boolean };
export class StoreDevices {
    static async createStoreDevice(params: {
        id: string; // snackId, or printerId for cash drawer
        deviceType: DeviceType;
        name: string;
        storeId: string;
        mdmProvider: MDMProvider | null;
    }): Promise<CreateStoreDeviceResponse> {
        return client.post(`/devices/store`, params).catch((cause) => {
            throw new ErrorWithCause(
                `api.StoreDevices.createStoreDevice: POST to /devices/store failed`,
                cause,
            );
        });
    }

    static async createStripeTerminal(params: {
        deviceName: string;
        storeId: string;
        registrationCode: string;
    }): Promise<CreateStoreDeviceResponse> {
        return client.post(`/devices/store/stripe`, params).catch((cause) => {
            throw new ErrorWithCause(
                `api.StoreDevices.createStripeTerminal: POST to /devices/store/stripe failed`,
                cause,
            );
        });
    }

    static async createSnackOSDevice(params: {
        deviceName: string;
        storeId: string;
        registrationCode: string;
    }): Promise<CreateStoreDeviceResponse> {
        return client.post(`/devices/store/assign`, params).catch((cause) => {
            throw new ErrorWithCause(
                `api.StoreDevices.createSnackOSDevice: POST to /devices/store/assign failed`,
                cause,
            );
        });
    }

    static async listStoreDevices(
        storeId: string,
    ): Promise<ListStoreDevicesResponse> {
        return client
            .get(`/devices/store`, { params: { storeId } })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.StoreDevices.listStoreDevices: GET to /devices/store failed`,
                    cause,
                );
            });
    }

    static async listDevicesForInternal(): Promise<ListDevicesForInternalResponse> {
        return client.get("/devices/store/internal").catch((cause) => {
            throw new ErrorWithCause(
                "api.StoreDevices.listDevicesForInternal: GET to /devices/store/internal failed",
                cause,
            );
        });
    }

    static async getStoreDevice(id: string): Promise<GetStoreDeviceResponse> {
        return client.get(`/devices/store/${id}`).catch((cause) => {
            throw new ErrorWithCause(
                `api.StoreDevices.getStoreDevice: GET to /devices/store/${id} failed`,
                cause,
            );
        });
    }

    static async getDeviceNetworkConnectivityReport(
        id: string,
        storeId: string,
        start: Date,
        end: Date,
    ): Promise<GetDeviceNetworkConnectivityReportResponse> {
        return client
            .get(`/devices/store/${id}/network-connectivity-report`, {
                params: { storeId, start, end },
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.StoreDevices.getDeviceNetworkConnectivityReport: GET to /devices/store/${id}/network-connectivity-report failed`,
                    cause,
                );
            });
    }

    static async getPrepStationFields(
        storeId: string,
    ): Promise<GetPrepStationFieldsResponse> {
        return client
            .get(`/devices/store/prep-stations/store/${storeId}`)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.StoreDevices.getPrepStationFields: GET to /devices/store/prep-stations/store/${storeId} failed`,
                    cause,
                );
            });
    }

    static async updateStoreDevice(
        deviceId: string,
        updateParams: Record<string, unknown>,
    ): Promise<UpdateStoreDeviceResponse> {
        return client
            .put(`/devices/store/${deviceId}`, { ...updateParams })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.StoreDevices.updateStoreDevice: PUT to /devices/store/${deviceId} failed`,
                    cause,
                );
            });
    }

    static async deleteStoreDevice(
        deviceId: string,
    ): Promise<DeleteStoreDeviceResponse> {
        return client.delete(`/devices/store/${deviceId}`).catch((cause) => {
            throw new ErrorWithCause(
                `api.StoreDevices.deleteStoreDevice: DELETE to /devices/store/${deviceId} failed`,
                cause,
            );
        });
    }

    static async rebootStoreDevice(
        deviceId: string,
    ): Promise<DeleteStoreDeviceResponse> {
        return client
            .post(`/devices/store/${deviceId}/reboot`)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.StoreDevices.rebootStoreDevice: POST to /devices/store/${deviceId}/reboot failed`,
                    cause,
                );
            });
    }

    static async restartStoreDevice(
        deviceId: string,
    ): Promise<RestartStoreDeviceResponse> {
        return client
            .post(`/devices/store/${deviceId}/restart`)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.StoreDevices.restartStoreDevice: POST to /devices/store/${deviceId}/restart failed`,
                    cause,
                );
            });
    }

    static async setRotationState(
        deviceId: string,
        rotateState: RotationState,
    ): Promise<AxiosResponse<EsperCommandResponseType>> {
        return client
            .post(`/devices/store/${deviceId}/rotate`, {
                rotateState,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.StoreDevices.setRotationState: POST to /devices/store/${deviceId}/rotate failed`,
                    cause,
                );
            });
    }

    // TODO: update to use /devices rather than /snacktv once it's available
    static async getEsperDeviceInfoBySerial(
        serial: string,
    ): Promise<AxiosResponse<EsperDeviceInfo>> {
        return client.get(`/snacktv/${serial}/device-info`).catch((cause) => {
            throw new ErrorWithCause(
                `api.StoreDevices.getEsperDeviceInfoBySerial: GET to /snacktv/${serial}/device-info failed`,
                cause,
            );
        });
    }

    static async checkValidId(
        snackId: string,
        deviceType: string,
    ): Promise<CheckValidIdResponse> {
        return client
            .get(`/devices/store/check-valid-id/${snackId}/type/${deviceType}`)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.StoreDevices.checkValidId: GET to /devices/store/check-valid-id/${snackId}/type/${deviceType} failed`,
                    cause,
                );
            });
    }
}
