import { z } from "zod";
import {
    SharableBrandRegistration,
    TwilioBrandRegistrationStatus,
} from "#sms-campaigns/CampaignBrandRegistration/CampaignBrandRegistrationAtoms";
import {
    ShareTwilioBrandCheckSchema,
    SharedTwilioBrandFormSchema,
} from "#sms-campaigns/CampaignBrandRegistration/CampaignBrandRegistrationSMSForm/campaignBrandRegistrationSMSFormSchema";

import { client } from "../client";

export class Twilio {
    static async createTwilioBrandRegistration(
        storeId: string,
        formData: object,
    ) {
        return client.post<{ status: TwilioBrandRegistrationStatus }>(
            `/twilio/brand/register`,
            {
                storeId,
                ...formData,
            },
            { withCredentials: true },
        );
    }

    static async deleteTwilioBrandRegistration(storeId: string) {
        return client.delete(`/twilio/brand/${storeId}`);
    }

    static async fetchTwilioBrandRegistrationStatus(storeId: string) {
        return client.get<{
            status: TwilioBrandRegistrationStatus | null | undefined;
            displayName: string | null | undefined;
        }>(`/twilio/brand/status`, {
            params: { storeId },
            withCredentials: true,
        });
    }

    static async fetchTwilioBrandRegistrationChainStores(storeId: string) {
        return client.get<{ brands: SharableBrandRegistration[] }>(
            `/twilio/brand/shared/chain-stores`,
            {
                params: { storeId },
                withCredentials: true,
            },
        );
    }

    static async checkSharedTwilioBrand(
        storeId: string,
        formData: z.infer<typeof ShareTwilioBrandCheckSchema>,
    ) {
        return client.post<void>(
            `/twilio/brand/shared/check`,
            {
                storeId,
                ...formData,
            },
            { withCredentials: true },
        );
    }

    static async createSharedTwilioBrand(
        storeId: string,
        formData: z.infer<typeof SharedTwilioBrandFormSchema>,
    ) {
        return client.post<{
            status: TwilioBrandRegistrationStatus;
            displayName: string | undefined;
            phoneNumber: string | undefined;
        }>(
            `/twilio/brand/shared/register`,
            {
                storeId,
                ...formData,
            },
            { withCredentials: true },
        );
    }
}
