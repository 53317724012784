export enum FreshnessType {
    SALES = "SALES",
    MENU = "MENU",
    CUSTOMER = "CUSTOMER",
    PROMOTION = "PROMOTION",
}

export enum ReportType {
    SALES_SUMMARY = "SALES_SUMMARY",
    SALES_CHANNELS = "SALES_CHANNELS",
    MENU_ITEM_INSIGHTS = "MENU_ITEM_INSIGHTS",
    MENU_CATEGORY_INSIGHTS = "MENU_CATEGORY_INSIGHTS",
    LOCATION_SALES_REPORT = "LOCATION_SALES_REPORT",
    LOCATION_MENU_CATEGORY_REPORT = "LOCATION_MENU_CATEGORY_REPORT",
    LOCATION_MENU_ITEM_REPORT = "LOCATION_MENU_ITEM_REPORT",
    CUSTOMER_DIRECTORY = "CUSTOMER_DIRECTORY_INSIGHTS",
    PROMOTIONS_REPORT = "PROMOTIONS_REPORT",
    GIFT_CARD_PURCHASE_REPORT = "GIFT_CARD_PURCHASE_REPORT",
    GIFT_CARD_BALANCE_REPORT = "GIFT_CARD_BALANCE_REPORT",
    GIFT_CARD_TRANSACTION_REPORT = "GIFT_CARD_TRANSACTION_REPORT",
}

export type SourceFilter =
    | "App"
    | "Kiosk"
    | "Register"
    | "Online"
    | "ThirdParty";
export type ChannelFilter =
    | "Snackpass"
    | "Doordash"
    | "Grubhub"
    | "UberPostmates"
    | "UberEats"
    | "Fantuan"
    | "Easi"
    | "Other";
export type FulfillmentFilter = "Pickup" | "Delivery" | "DineIn";
export type PaymentFilter =
    | "CreditCard"
    | "GiftCard"
    | "Cash"
    | "Other"
    | "InStore"
    | "Paypal";
export interface QueryFilter {
    source: SourceFilter[];
    channel: ChannelFilter[];
    fulfillment: FulfillmentFilter[];
    payment: PaymentFilter[];
    storeIds: string[];
}

export enum ComparisonType {
    NONE = "NONE",
    WEEK = "WEEK",
    PERIOD = "PERIOD",
    MONTH = "MONTH",
    YEAR = "YEAR",
}

export enum GranularityType {
    DAILY = "Daily",
    WEEKLY = "Weekly",
    MONTHLY = "Monthly",
}

export interface SummaryTilesData {
    netSales: string;
    netSalesComparedTo: string;
    netSalesChange: string;
    orders: string;
    ordersComparedTo: string;
    ordersChange: string;
    averageOrderSize: string;
    averageOrderSizeComparedTo: string;
    averageOrderSizeChange: string;
    items: string;
    itemsComparedTo: string;
    itemsChange: string;

    laborCost?: string;
    laborCostComparedTo?: string;
    laborCostChange?: string;

    laborCostPercentage?: string;
    laborCostPercentageComparedTo?: string;
    laborCostPercentageChange?: string;
}

export interface GiftCardLiabilitiesRow {
    storeId: string;
    storeName: string;
    storeHeadstore: boolean;
    otherStoreId: string;
    otherStoreName: string;
    otherStoreHeadstore: boolean;
    payableAmountCents: number;
    receivableAmountCents: number;
    netReceivableAmountCents: number;
    payableAmountCentsUsageStoreCovers: number;
    receivableAmountCentsUsageStoreCovers: number;
    netReceivableAmountCentsUsageStoreCovers: number;
}

export interface GiftCardPurchaseStats {
    totalCount: number;
    totalAmountCents: number;
    netSalesCents: number;
    outstandingBalanceCents: number;
    physicalCount: number;
    digitalCount: number;
    physicalSalesCents: number;
    digitalSalesCents: number;
}

export interface GiftCardPurchaseStatEvents {
    purchaseTotalCount: number;
    purchaseTotalAmountCents: number;
    purchasePhysicalCount: number;
    purchasePhysicalAmountCents: number;
    purchaseDigitalCount: number;
    purchaseDigitalAmountCents: number;
    usageTotalAmountCents: number;
    usageTotalCount: number;
    usagePhysicalAmountCents: number;
    usagePhysicalCount: number;
    usageDigitalAmountCents: number;
    usageDigitalCount: number;
    refundCount: number;
    refundTotalAmountCents: number;
    refundPhysicalCount: number;
    refundDigitalCount: number;
    refundPhysicalAmountCents: number;
    refundDigitalAmountCents: number;
}

export interface TableSummaryDataRow {
    date: string;
    netSales: SalesByChannelGroup;
    subtotal: SalesByChannelGroup;
    orders: SalesByChannelGroup;
    tips: SalesByChannelGroup;
    processingFees: SalesByChannelGroup;
    snackpassFees: SalesByChannelGroup;
    customerToSnackpassFees: SalesByChannelGroup;
    taxesYouOwe: SalesByChannelGroup;
    taxesSnackpassOwes: SalesByChannelGroup;
    taxesRemittedForSnackpass: SalesByChannelGroup;
    taxesRemittedForStore: SalesByChannelGroup;
    expectedCash: SalesByChannelGroup;
    expectedPayout: SalesByChannelGroup;
    actualPayout: SalesByChannelGroup;
    giftCardPurchases: SalesByChannelGroup;
    storeCredit: SalesByChannelGroup;
    refundedAmount: SalesByChannelGroup;
    amountDiscounted: SalesByChannelGroup;
}

export interface TableLocationData {
    netSales: number;
    orders: number;
    items: number;
    avgOrder: number;
}

export type SalesByChannelGroup = {
    total: number;
    snackpass: number;
    thirdParty: number;
};

export interface SalesHourlyAggregateReport {
    hour: string;
    netSales: number;
    orders: number;
}

export interface SalesDailyAggregateReport {
    day: string;
    netSales: number;
    orders: number;
}
