import { ApolloProvider } from "@apollo/client";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactNode } from "react";
import { Provider as ReduxProvider } from "react-redux";
import { ThemeProvider } from "next-themes";

import { apolloClient } from "src/api/graphql/apollo";
import { reactQueryClient } from "src/lib/clients";
import { store } from "src/redux/store";
import { StatsigReduxProvider } from "src/statsig/StatsigReduxProvider";
import { MenuServiceProvider } from "#menu/MenuServiceProvider";
import CacheBusting from "#cache-busting";
import OnboardingProvider from "#onboarding/utils/OnboardingContext";

/**
 * Global state providers for the RDB app.
 */
// only put _global_ providers here!
export function GlobalProviders({
    children,
}: {
    children: ReactNode | ReactNode[];
}) {
    const IS_DEV = process.env.NODE_ENV === "development";
    return (
        <ThemeProvider
            attribute="class"
            defaultTheme={IS_DEV ? "system" : "light"}
            enableSystem={IS_DEV}
        >
            <QueryClientProvider client={reactQueryClient}>
                <ApolloProvider client={apolloClient}>
                    <ReduxProvider store={store}>
                        <StatsigReduxProvider>
                            <MenuServiceProvider>
                                <OnboardingProvider>
                                    <CacheBusting>{children}</CacheBusting>
                                </OnboardingProvider>
                            </MenuServiceProvider>
                        </StatsigReduxProvider>
                    </ReduxProvider>
                </ApolloProvider>
            </QueryClientProvider>
        </ThemeProvider>
    );
}
