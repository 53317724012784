import { ErrorWithCause } from "src/utils/errors";
import { SaasAccount } from "#billing/domain/types";

import { client } from "../client";

export type HasBillingMethodsData = {
    hasExistingMethods: boolean;
    portalUrl?: string;
};

export class Billing {
    static async getSaasAccount(
        storeId: string,
    ): Promise<{ data: { account: SaasAccount; success: boolean } }> {
        return client.get(`/billing/${storeId}/saas-account`).catch((cause) => {
            throw new ErrorWithCause(
                `api.Billing.getSaasAccount: GET to /billing/${storeId}/saas-account failed`,
                cause,
            );
        });
    }

    static async getPortal(
        storeId: string,
    ): Promise<{ data: { portalUrl: string; success: boolean } }> {
        return client.get(`/billing/${storeId}/portal`).catch((cause) => {
            throw new ErrorWithCause(
                `api.Billing.getPortal: GET to /billing/${storeId}/portal failed`,
                cause,
            );
        });
    }

    static async getOnboardingForm(
        storeId: string,
    ): Promise<{ data: { url: string; success: boolean } }> {
        return client
            .get(`/verifications/${storeId}/account/onboarding`)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Billing.getOnboardingForm: GET to /billing/${storeId}/account/onboarding failed`,
                    cause,
                );
            });
    }

    static async getCheckout(
        storeId: string,
    ): Promise<{ data: { clientSecret: string; success: boolean } }> {
        return client.get(`/billing/${storeId}/checkout`).catch((cause) => {
            throw new ErrorWithCause(
                `api.Billing.getCheckout: GET to /billing/${storeId}/checkout failed`,
                cause,
            );
        });
    }

    static async getHasBillingMethods(
        storeId: string,
    ): Promise<{ data: HasBillingMethodsData }> {
        return client
            .get(`/billing/${storeId}/payment-methods`)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Billing.getHasBillingMethods: GET to /billing/${storeId}/payment-methods failed`,
                    cause,
                );
            });
    }
}
