import { ChevronRight } from "lucide-react";
import { useNavigate } from "react-router-dom";

import { cn } from "src/@/lib/utils";

interface BreadcrumbItem {
    title: string;
    url: string | null;
}

interface BreadcrumbProps {
    items: BreadcrumbItem[];
    className?: string;
}

export function Breadcrumb({ items, className }: BreadcrumbProps) {
    const navigate = useNavigate();

    const renderBreadcrumbItem = (
        { title, url }: BreadcrumbItem,
        index: number,
    ) => {
        // The last item in breadcrumbs is the current page, we do not need to surface navigation, and we style it differently
        if (index === items.length - 1) {
            return <span className="font-medium text-foreground">{title}</span>;
        }

        // If the breadcrumb item doesn't correspond to an actual page, we should not surface navigation
        if (!url) {
            return <span className="text-muted-foreground">{title}</span>;
        }

        // Navigable breadcrumbs for everything else :-)
        return (
            <button
                onClick={() => navigate(url)}
                className="text-muted-foreground transition-colors hover:text-foreground"
            >
                {title}
            </button>
        );
    };

    return (
        <nav
            className={cn(
                "flex items-center space-x-1 text-sm text-muted-foreground",
                className,
            )}
        >
            {items.map((item, index) => (
                <div
                    key={`${item.url}-${item.title}`}
                    className="flex items-center"
                >
                    {index > 0 && <ChevronRight className="mx-2 h-4 w-4" />}
                    {renderBreadcrumbItem(item, index)}
                </div>
            ))}
        </nav>
    );
}
