import { ColumnDef } from "@tanstack/react-table";
import { ControllerRenderProps } from "react-hook-form";
import { PlusCircledIcon, TrashIcon } from "@radix-ui/react-icons";

import { DataTable } from "src/@/components/ui/data-table";
import {
    CreatePromotionAdjustment,
    CreatePromotion,
} from "src/api/graphql/generated/types";
import { Button } from "src/@/components/ui/button";

import { ScopeInput } from "./ScopeInput";
import { AdjustmentRow } from "./AdjustmentRow";

export type AdjustmentsInputProps = ControllerRenderProps<
    CreatePromotion,
    "adjustments"
>;

export function AdjustmentsInput({ value, onChange }: AdjustmentsInputProps) {
    const columns: ColumnDef<CreatePromotionAdjustment>[] = [
        {
            header: "Apply Discounts To",
            id: "scope",
            accessorKey: "scope",
            cell: ({ row }) => (
                <ScopeInput
                    value={row.original.scope}
                    onChange={(scope) =>
                        onChange(
                            value.toSpliced(row.index, 1, {
                                ...row.original,
                                scope,
                            }),
                        )
                    }
                />
            ),
        },
        {
            header: "Discount",
            id: "adjustment",
            accessorKey: "adjustment",
            cell: ({ row }) => (
                <AdjustmentRow
                    value={row.original}
                    onChange={(adjustment) =>
                        onChange(value.toSpliced(row.index, 1, adjustment))
                    }
                />
            ),
        },
        {
            header: "",
            id: "actions",
            cell: ({ row }) => (
                <div className="flex justify-end">
                    <Button
                        className="bg-critical-light"
                        type="button"
                        onClick={() => onChange(value.toSpliced(row.index, 1))}
                    >
                        <TrashIcon className="h-4 w-4" />
                    </Button>
                </div>
            ),
        },
    ];

    return (
        <div>
            <DataTable
                columns={columns}
                data={value}
                emptyText="No Discounts"
            />
            <Button
                className="max-w-60"
                type="button"
                onClick={() =>
                    onChange([
                        ...value,
                        { scope: { menu: true }, adjustment: { price: 0 } },
                    ])
                }
            >
                <PlusCircledIcon className="mr-1 h-4 w-4" />
                <span>Add Discount</span>
            </Button>
        </div>
    );
}
