import { PromotionForm } from "#new-promotions/components";
import { Page } from "src/@/components/ui/page";

export function CreatePromotionScreen() {
    return (
        <Page title="Create Promotion">
            <PromotionForm />
        </Page>
    );
}
