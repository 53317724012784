import * as React from "react";
import {
    ColumnDef,
    ColumnFiltersState,
    SortingState,
    VisibilityState,
    ExpandedState,
    getCoreRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
    getExpandedRowModel,
    PaginationState,
    RowSelectionState,
    TableOptions,
} from "@tanstack/react-table";

import {
    DataTableComponent,
    DataTableComponentProps,
} from "src/@/components/ui/data-table/data-table-component";

interface DataTableProps<TData, TValue>
    extends Omit<DataTableComponentProps<TData>, "table"> {
    columns: ColumnDef<TData, TValue>[];
    data: TData[];
    hiddenColumns?: string[];
    manualPagination?: boolean;
    customPageSize?: number;
    /**
     * A list of column ids to be "pinned" to the left of the table by default.
     */
    defaultPinned?: string[];

    /**
     * Default sorting state for the table to begin in.
     * See https://tanstack.com/table/v8/docs/api/features/sorting
     */
    defaultSorting?: SortingState;

    /**
     * Whether or not rows should begin expanded or not.
     * This can just be a "true" if you want all rows to be expanded by default.
     * See https://tanstack.com/table/v8/docs/api/features/expanding
     */
    defaultExpanded?: ExpandedState;

    /**
     * Whether or not rows should begin selected or not.
     * Record of rowId (string) to boolean.
     * You may need to override getRowId to do this more easily.
     * See https://tanstack.com/table/v8/docs/guide/row-selection
     */
    defaultSelected?: RowSelectionState;
}

interface PaginatedDataTableProps<TData, TValue>
    extends DataTableProps<TData, TValue> {
    manualFiltering?: boolean;
    manualSorting?: boolean;
    pageCount?: number;
    sorting?: SortingState;
    setSorting?: (
        sort: SortingState | ((prevState: SortingState) => SortingState),
    ) => void;
    pagination?: PaginationState;
    // Must specify setPagination for server side pagination. For client side pagination, use the default datatables
    setPagination: (
        pagination:
            | PaginationState
            | ((prevState: PaginationState) => PaginationState),
    ) => void;
    globalFilter?: string;
    setGlobalFilter?: (filter: string) => void;
}

/**
 * A wrapper around the data table component which calls the tanstack useTable hook for you.
 */
export function DataTable<TData, TValue>({
    columns,
    data,
    manualPagination = false,
    customPageSize = undefined,
    hiddenColumns,
    onPaginationChange,
    defaultPinned = [],
    dragAndDropOptions,
    defaultSorting,
    ...props
}: DataTableProps<TData, TValue>) {
    const table = useDataTable({
        columns,
        data,
        manualPagination,
        customPageSize,
        hiddenColumns,
        onPaginationChange,
        defaultPinned,
        dragAndDropOptions,
        defaultSorting,
    });

    return (
        <DataTableComponent
            table={table}
            dragAndDropOptions={dragAndDropOptions}
            {...props}
        />
    );
}

export function PaginatedDataTable<TData, TValue>({
    columns,
    data,
    manualPagination = false,
    manualFiltering = false,
    manualSorting = false,
    customPageSize = undefined,
    hiddenColumns,
    onPaginationChange,
    defaultPinned = [],
    dragAndDropOptions,
    pageCount,
    sorting,
    setSorting,
    pagination,
    setPagination,
    globalFilter,
    setGlobalFilter,

    ...props
}: PaginatedDataTableProps<TData, TValue>) {
    const table = usePaginatedDataTable({
        columns,
        data,
        manualPagination,
        manualFiltering,
        manualSorting,
        customPageSize,
        hiddenColumns,
        onPaginationChange,
        defaultPinned,
        dragAndDropOptions,
        pageCount,
        sorting,
        setSorting,
        pagination,
        setPagination,
        globalFilter,
        setGlobalFilter,
    });

    return (
        <DataTableComponent
            table={table}
            manualPagination={manualPagination}
            dragAndDropOptions={dragAndDropOptions}
            {...props}
        />
    );
}

export type UseDataTableParams<TData, TValue> =
    // omitting onPaginationChange here because we define the type slightly differently in our props.
    Partial<Omit<TableOptions<TData>, "onPaginationChange">> &
        Pick<
            DataTableProps<TData, TValue>,
            | "columns"
            | "data"
            | "manualPagination"
            | "customPageSize"
            | "hiddenColumns"
            | "onPaginationChange"
            | "defaultPinned"
            | "dragAndDropOptions"
            | "defaultSorting"
            | "defaultExpanded"
            | "defaultSelected"
        >;

export type UsePaginatedDataTableParams<TData, TValue> =
    // omitting onPaginationChange here because we define the type slightly differently in our props.
    Partial<Omit<TableOptions<TData>, "onPaginationChange">> &
        Pick<
            PaginatedDataTableProps<TData, TValue>,
            | "columns"
            | "data"
            | "manualPagination"
            | "customPageSize"
            | "hiddenColumns"
            | "onPaginationChange"
            | "defaultPinned"
            | "dragAndDropOptions"
            | "defaultSorting"
            | "defaultExpanded"
            | "defaultSelected"
            | "sorting"
            | "setSorting"
            | "pagination"
            | "setPagination"
            | "globalFilter"
            | "setGlobalFilter"
        >;

/**
 * a wrapper function around useReactTable that initializes a table object with the data table's default values.
 * Also allows you to pass in any react table props you need as well.
 * Needed when you need to lift table state, then you can pass this into a <DataTableComponent />
 */
export function useDataTable<TData, TValue>({
    columns,
    data,
    manualPagination = false,
    customPageSize = undefined,
    hiddenColumns,
    onPaginationChange,
    defaultPinned = [],
    dragAndDropOptions,
    defaultSorting,
    defaultExpanded,
    defaultSelected,
    ...props
}: UseDataTableParams<TData, TValue>) {
    const [rowSelection, setRowSelection] = React.useState<RowSelectionState>(
        defaultSelected ?? {},
    );

    const [columnVisibility, setColumnVisibility] =
        React.useState<VisibilityState>(
            () =>
                hiddenColumns?.reduce(
                    (acc, curr) => {
                        acc[curr] = false;
                        return acc;
                    },
                    {} as Record<string, boolean>,
                ) ?? {},
        );

    const [columnFilters, setColumnFilters] =
        React.useState<ColumnFiltersState>([]);

    const [sorting, setSorting] = React.useState<SortingState>(
        defaultSorting ?? [],
    );

    const [expanded, setExpanded] = React.useState<ExpandedState>(
        defaultExpanded ?? {},
    );

    const [pagination, setPagination] = React.useState<PaginationState>({
        pageIndex: 0,
        pageSize: customPageSize ?? 10,
    });

    const table = useReactTable({
        data,
        columns,
        initialState: {
            columnPinning: {
                left: defaultPinned,
            },
        },
        state: {
            sorting,
            columnVisibility,
            rowSelection,
            columnFilters,
            pagination,
            expanded,
        },
        enableRowSelection: true,
        filterFromLeafRows: true,
        onRowSelectionChange: setRowSelection,
        getRowId: dragAndDropOptions
            ? (row) => dragAndDropOptions.getRowId(row).toString()
            : undefined,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        onColumnVisibilityChange: setColumnVisibility,
        onExpandedChange: setExpanded,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getExpandedRowModel: getExpandedRowModel(),
        getSubRows: (row) => (row as object & { subRows?: TData[] }).subRows,
        onPaginationChange: (updater) => {
            setPagination((old) => {
                const newPaginationValue =
                    updater instanceof Function ? updater(old) : updater;
                onPaginationChange?.(newPaginationValue);
                return newPaginationValue;
            });
        },
        manualPagination,
        ...props,
    });

    return table;
}

export function usePaginatedDataTable<TData, TValue>({
    columns,
    data,
    manualPagination = false,
    manualFiltering = false,
    manualSorting = false,
    hiddenColumns,
    onPaginationChange,
    defaultPinned = [],
    defaultSelected,
    defaultExpanded,
    dragAndDropOptions,
    sorting,
    setSorting,
    pagination,
    setPagination,
    globalFilter,
    setGlobalFilter,
    ...props
}: UsePaginatedDataTableParams<TData, TValue>) {
    const [rowSelection, setRowSelection] = React.useState<RowSelectionState>(
        defaultSelected ?? {},
    );

    const [columnVisibility, setColumnVisibility] =
        React.useState<VisibilityState>(
            () =>
                hiddenColumns?.reduce(
                    (acc, curr) => {
                        acc[curr] = false;
                        return acc;
                    },
                    {} as Record<string, boolean>,
                ) ?? {},
        );

    const [columnFilters, setColumnFilters] =
        React.useState<ColumnFiltersState>([]);

    const [expanded, setExpanded] = React.useState<ExpandedState>(
        defaultExpanded ?? {},
    );

    const table = useReactTable({
        data,
        columns,
        initialState: {
            columnPinning: {
                left: defaultPinned,
            },
        },
        state: {
            sorting,
            columnVisibility,
            rowSelection,
            columnFilters,
            pagination,
            expanded,
            globalFilter,
        },
        enableRowSelection: true,
        filterFromLeafRows: true,
        onRowSelectionChange: setRowSelection,
        getRowId: dragAndDropOptions
            ? (row) => dragAndDropOptions.getRowId(row).toString()
            : undefined,
        manualSorting,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        onColumnVisibilityChange: setColumnVisibility,
        onExpandedChange: setExpanded,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getExpandedRowModel: getExpandedRowModel(),
        getSubRows: (row) => (row as object & { subRows?: TData[] }).subRows,
        onPaginationChange: (updater) => {
            setPagination((old) => {
                const newPaginationValue =
                    updater instanceof Function ? updater(old) : updater;
                onPaginationChange?.(newPaginationValue);
                return newPaginationValue;
            });
        },
        manualFiltering,
        onGlobalFilterChange: setGlobalFilter,
        manualPagination,
        ...props,
    });

    return table;
}
