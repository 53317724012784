import { Cross2Icon } from "@radix-ui/react-icons";
import { Table } from "@tanstack/react-table";
import { ChangeEvent, useEffect, useMemo } from "react";
import { Merge } from "type-fest";

import { Button } from "src/@/components/ui/button";
import { Input } from "src/@/components/ui/input";
import { DataTableViewOptions } from "src/@/components/ui/data-table/table-view-options";
import {
    DataTableDateFilter,
    DataTableDateFilterOptions,
} from "src/@/components/ui/data-table/table-date-filter";
import { cn } from "src/@/lib/utils";
import { logAndSendError } from "src/utils/errors";

import { DataTableFacetedFilter } from "./table-faceted-filter";
import { LucideIcon } from "lucide-react";

export type DataTableToolbarOptions = {
    className?: string;
    /**
     * Configuration options for searching. Key required if global is false or undeclared, if global is true key cannot be declared.
     * @type {{
     *   placeholder?: string;
     *   defaultSearchValue?: string | null;
     *   inputClassName?: string;
     * } & (
     *   | { key: string; global?: false; }
     *   | { global: true; }
     * )}
     */
    search?: SearchOptions;
    filters?: Array<
        | {
              /**
               * The id of the column to search on.
               * Make sure to implement "filterFn" in the column that you are searching on, as the default filter function in react table does not support these filters.
               * e.g. filterFn: (row, id, value) => value.includes(row.getValue(id)),
               */
              key: string;
              title: string;
              icon?: LucideIcon;
              options: {
                  label: string;
                  value: string;
                  icon?: React.ComponentType<{ className?: string }>;
              }[];
          }
        | Merge<DataTableDateFilterOptions, { key: string }>
    >;
    showColumnFilter?: boolean;
    customComponent?: React.ReactNode;
    customRightComponent?: React.ReactNode;
};

type SearchOptions = {
    placeholder?: string;
    defaultSearchValue?: string | null;
    inputClassName?: string;
} & (
    | {
          /**
           * The id of the column to search on. Use when trying to search for 1 specific column.
           */
          key: string;
          /**
           * Set to true to search all columns.
           * In order to custom choose multiple specific columns to filter on, set global: true, and then for unwanted columns,
           * set enableGlobalFilter: false when declaring the columns (in lib.tsx).
           */
          global?: false;
      }
    | {
          /**
           * Set to true to search all columns.
           * In order to custom choose multiple specific columns to filter on, set global: true, and then for unwanted columns,
           * set enableGlobalFilter: false when declaring the columns (in lib.tsx).
           */
          global: true;
      }
);

interface DataTableToolbarProps<TData> {
    table: Table<TData>;
    toolbar?: DataTableToolbarOptions;
}

export function DataTableToolbar<TData>({
    table,
    toolbar,
}: DataTableToolbarProps<TData>) {
    const tableState = table.getState();
    const isFiltered = tableState.columnFilters.length > 0;

    function isGlobalSearch(search: SearchOptions): search is { global: true } {
        return search.global === true;
    }

    useEffect(() => {
        if (toolbar?.search?.defaultSearchValue) {
            if (isGlobalSearch(toolbar.search)) {
                table.setGlobalFilter(toolbar.search.defaultSearchValue);
            } else {
                table
                    .getColumn(toolbar.search.key!)
                    ?.setFilterValue(toolbar.search.defaultSearchValue);
            }
        }
    }, [
        table,
        toolbar?.search,
        toolbar?.search?.defaultSearchValue,
        toolbar?.search?.global,
    ]);

    const value = useMemo(() => {
        if (toolbar?.search && isGlobalSearch(toolbar.search)) {
            return (tableState.globalFilter as string) ?? "";
        } else if (toolbar?.search && !isGlobalSearch(toolbar.search)) {
            return (
                (table
                    .getColumn(toolbar.search.key)
                    ?.getFilterValue() as string) ?? ""
            );
        } else {
            return "";
        }
    }, [table, toolbar?.search, tableState]);

    const onSearchTextChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (toolbar?.search && isGlobalSearch(toolbar.search)) {
            table.setGlobalFilter(value);
        } else if (toolbar?.search && !isGlobalSearch(toolbar.search)) {
            table.getColumn(toolbar?.search?.key || "")?.setFilterValue(value);
        } else {
            return;
        }
    };

    return (
        <div
            className={cn(
                "flex items-center justify-between gap-2",
                toolbar?.className,
            )}
        >
            <div className="flex flex-1 items-center gap-2">
                {toolbar?.search && (
                    <Input
                        placeholder={toolbar?.search.placeholder || "Search"}
                        value={value}
                        onChange={onSearchTextChanged}
                        className={cn(
                            "h-8 w-[300px]",
                            toolbar.search.inputClassName,
                        )}
                    />
                )}
                {toolbar?.filters && toolbar.filters.length > 0 && (
                    <div className="flex items-center gap-2">
                        {toolbar.filters.map((filter) => {
                            const column = table.getColumn(filter.key);
                            if (!column?.getIsVisible()) {
                                return null;
                            }
                            if ("defaultDateComparison" in filter) {
                                return (
                                    <DataTableDateFilter
                                        column={column}
                                        {...filter}
                                    />
                                );
                            }
                            if ("options" in filter) {
                                return (
                                    <DataTableFacetedFilter
                                        column={column}
                                        {...filter}
                                        icon={filter.icon}
                                    />
                                );
                            }
                            logAndSendError("Invalid filter");
                            throw new Error("Invalid filter");
                        })}
                        {isFiltered && (
                            <Button
                                variant="ghost"
                                onClick={() => table.resetColumnFilters()}
                                className="h-8 px-2 lg:px-3"
                            >
                                Reset
                                <Cross2Icon className="ml-2 h-4 w-4" />
                            </Button>
                        )}
                    </div>
                )}
                {toolbar?.showColumnFilter && (
                    <DataTableViewOptions table={table} />
                )}
                {toolbar?.customComponent && toolbar.customComponent}
            </div>

            <div className="flex shrink-0 items-center justify-end gap-2">
                {toolbar?.customRightComponent && toolbar.customRightComponent}
            </div>
        </div>
    );
}
