import {
    PurchaseReportData,
    PaymentProvider,
    InStorePaymentType,
    PurchaseStatus,
} from "@snackpass/snackpass-types";
import moment from "moment-timezone";
import {
    formatPurchaseProvider,
    formatPurchaseChannel,
    nameToFirstNameLastInitial,
} from "#utils/helpers";
import { formatCurrency } from "src/@/lib/format-currency";
import { FULFILLMENT_MAP, Fulfillment } from "#sales-report/types";

export const formatItems = (p: PurchaseReportData): string => {
    const names = p.items.map((item) => {
        const { name } = item;
        let itemText = name;
        if (item.weight) {
            const { amount, unit } = item.weight;
            itemText = `${name}(${amount}${unit})`;
        }
        return itemText;
    });
    return names.join("; ");
};

export const formatPromos = (p: PurchaseReportData): string =>
    [...p.promoNames, ...p.rewardNames].join("; ");

export const formatNotes = (p: PurchaseReportData): string =>
    p.notes.join("; ");

export const formatRefund = (p: PurchaseReportData): string =>
    p.refundedAmount ? formatCurrency(p.refundedAmount) : "";

export const formatStatus = (p: PurchaseReportData): string => {
    switch (p.status) {
        case PurchaseStatus.received:
            return "Received";
        case PurchaseStatus.started:
            return "Started";
        case PurchaseStatus.canceled:
            return p.refundedAmount > 0 ? "Refunded" : "Canceled";
        case PurchaseStatus.completed:
            return "Completed";
        default:
            return p.status ?? "Received";
    }
};

export const formatPaymentMethod = (p: PurchaseReportData): string => {
    switch (p.paymentMethod) {
        case PaymentProvider.inStore:
            switch (p.inStorePaymentType) {
                case InStorePaymentType.other:
                    return "In-Store (Other)";
                case InStorePaymentType.giftCards:
                    return "In-Store (Gift Card)";
                default:
                    return "In-Store (Cash)";
            }
        case PaymentProvider.thirdParty:
            return "Third Party";
        case PaymentProvider.square:
        case PaymentProvider.stripe:
            return "Card";
        case PaymentProvider.giftCards:
            return "Gift Card Credit";
        case undefined:
            return p.hasSpecialGiftAccounting
                ? "Card"
                : p.giftCardCredit
                  ? "Gift Card Credit"
                  : p.storeCredit
                    ? "Store Credit"
                    : p.snackpassCredit
                      ? "Snackpass Credit"
                      : "-";
        default:
            return p.paymentMethod;
    }
};

export const getTwoDigitDateString = (
    timezone: string,
    date: Date | string | null,
): string => {
    if (!date) return "";
    const momentDate = moment(date);
    return timezone
        ? momentDate.tz(timezone).format("h:mm a M/D/YYYY")
        : momentDate.format("h:mm a M/D/YYYY");
};

export enum OrderField {
    // Core Order Info
    Details = "details",
    OrderNumber = "receiptNumber",
    DateReceived = "dateReceived",
    Status = "status",

    // Customer Info
    CustomerName = "customerName",
    // CustomerPhone = "phone",
    TableNumber = "tableNumber",

    // Order Details
    Items = "items",
    Promotions = "promotions",
    Notes = "notes",
    ScheduledFor = "scheduledFor",
    Fulfillment = "fulfillment",

    // Order Source
    Channel = "channel",
    Provider = "provider",

    // Base Pricing
    Subtotal = "subtotal",
    CustomSurcharge = "customSurcharge",
    CustomDiscounts = "customDiscounts",
    UpCharge = "upCharge",

    // Delivery & Service Fees
    DeliveryCharge = "deliveryCharge",
    ThirdPartyDeliveryCharge = "thirdPartyDeliveryCharge",
    // FaxFee = "faxFee",
    SnackpassFee = "snackpassFee",
    ProcessingFee = "processingFee",
    ThirdPartyFees = "thirdPartyFees",
    CustomerToStoreFees = "customerToStoreFees",

    // Taxes
    TaxesYouOwe = "taxesYouOwe",
    TaxesRemitted = "taxesRemitted",
    ThirdPartyTaxes = "thirdPartyTaxes",

    // Payment Info
    PaymentMethod = "paymentMethod",
    GiftCardCredit = "giftCardCredit",
    StoreCredit = "storeCredit",
    Tip = "tip",
    Total = "total",

    // Sales Calculations
    NetSales = "netSales",
    GrossSales = "grossSales",
    ThirdPartyPayout = "thirdPartyPayout",
    ExpectedCash = "expectedCash",

    // Modification Info
    RefundedBy = "refundedBy",
    RefundedAmount = "refundedAmount",
    UpChargedBy = "upChargedBy",
    CashAcceptedBy = "cashAcceptedBy",
    CreatedBy = "createdBy",

    // System IDs (SnackTeam Only)
    ID = "id",
    PayoutID = "payoutId",
}

type FormatterValue = string | number | Date | null | boolean | unknown;
type FormatterFn = (
    value: FormatterValue,
    data?: PurchaseReportData | null,
    timezone?: string,
) => {
    value: FormatterValue;
    readable: string;
};

type PhoneValue = {
    raw: string;
    formatted: string;
};

export type ColumnConfig = {
    key: OrderField;
    label: string;
    tooltip?: string;
    accessor: (data: PurchaseReportData) => FormatterValue | PhoneValue;
    formatter: FormatterFn;
    snackTeamOnly?: boolean;
    enableGlobalFilter?: boolean;
    filterFn?: (
        row: { getValue: (columnId: string) => any },
        columnId: string,
        filterValue: string,
    ) => boolean;
};

const moneyFormatter: FormatterFn = (value) => ({
    value,
    readable: formatCurrency(value as number),
});

const dateFormatter: FormatterFn = (value) => ({
    value,
    readable: value ? moment(value).format("h:mm A M/D/YYYY") : "—",
});

const stringFormatter: FormatterFn = (value) => ({
    value,
    readable: (value ?? "").toString(),
});

export const COLUMNS: Record<OrderField, ColumnConfig> = {
    [OrderField.Details]: {
        key: OrderField.Details,
        label: "Details",
        accessor: (data) => data.purchaseId,
        formatter: () => ({ value: "", readable: "View" }),
    },
    [OrderField.OrderNumber]: {
        key: OrderField.OrderNumber,
        label: "Order #",
        accessor: (data) => data.receiptNumber,
        formatter: (value) => ({
            value,
            readable: value ? `#${value}` : "—",
        }),
        enableGlobalFilter: true,
    },
    [OrderField.DateReceived]: {
        key: OrderField.DateReceived,
        label: "Ordered At",
        accessor: (data) => data.dateReceived,
        formatter: dateFormatter,
    },
    [OrderField.Status]: {
        key: OrderField.Status,
        label: "Status",
        accessor: (data) => data.status,
        formatter: (_, data) => ({
            value: data?.status ?? "",
            readable: data ? formatStatus(data) : "",
        }),
    },

    // Customer Info
    [OrderField.CustomerName]: {
        key: OrderField.CustomerName,
        label: "Customer",
        accessor: (data) => data.customerName,
        formatter: (_, data) => ({
            value: data?.customerName ?? "",
            readable: data?.customerName
                ? nameToFirstNameLastInitial(data?.customerName)
                : "—",
        }),
        enableGlobalFilter: true,
    },
    // hide for now for security for user
    // [OrderField.CustomerPhone]: {
    //     key: OrderField.CustomerPhone,
    //     label: "Phone",
    //     accessor: (data: PurchaseReportData): PhoneValue => {
    //         const rawNumber = data.customerNumber?.toString() ?? "";
    //         const formattedNumber = formatPhone(rawNumber) ?? "";
    //         return {
    //             raw: rawNumber,
    //             formatted: formattedNumber,
    //         };
    //     },
    //     formatter: (
    //         value: FormatterValue,
    //     ): { value: string; readable: string } => ({
    //         value: (value as PhoneValue)?.raw ?? "",
    //         readable: (value as PhoneValue)?.formatted ?? "—",
    //     }),
    //     enableGlobalFilter: true,
    // },
    [OrderField.Fulfillment]: {
        key: OrderField.Fulfillment,
        label: "Fulfillment",
        accessor: (data) => data.fulfillment,
        formatter: (_, data) => ({
            value: data?.fulfillment ?? "",
            readable: data
                ? FULFILLMENT_MAP[data.fulfillment as Fulfillment].readable
                : "",
        }),
    },
    [OrderField.TableNumber]: {
        key: OrderField.TableNumber,
        label: "Table",
        accessor: (data) => data.tableNumber,
        formatter: stringFormatter,
    },

    // Order Details
    [OrderField.Items]: {
        key: OrderField.Items,
        label: "Items",
        accessor: (data) => data.items,
        formatter: (_, data) => ({
            value: "",
            readable: data ? formatItems(data) : "",
        }),
    },
    [OrderField.Promotions]: {
        key: OrderField.Promotions,
        label: "Promotions",
        accessor: (data) => [...data.promoNames, ...data.rewardNames],
        formatter: (_, data) => ({
            value: "",
            readable: data ? formatPromos(data) : "",
        }),
    },
    [OrderField.Notes]: {
        key: OrderField.Notes,
        label: "Notes",
        accessor: (data) => data.notes,
        formatter: (_, data) => ({
            value: "",
            readable: data ? formatNotes(data) : "",
        }),
    },
    [OrderField.ScheduledFor]: {
        key: OrderField.ScheduledFor,
        label: "Scheduled For",
        accessor: (data) => data.scheduledFor,
        formatter: dateFormatter,
    },

    // Order Source
    [OrderField.Channel]: {
        key: OrderField.Channel,
        label: "Channel",
        accessor: (data) => data.source,
        formatter: (_, data) => ({
            value: data?.source ?? "",
            readable: formatPurchaseChannel(
                data?.source,
                data?.purchase?.isPOSMode,
            ),
        }),
    },
    [OrderField.Provider]: {
        key: OrderField.Provider,
        label: "Provider",
        accessor: (data) => data.purchase,
        formatter: (_, data) => ({
            value: "",
            readable: formatPurchaseProvider(data?.purchase),
        }),
    },

    // Base Pricing
    [OrderField.Subtotal]: {
        key: OrderField.Subtotal,
        label: "Subtotal",
        accessor: (data) => data.subtotal,
        formatter: moneyFormatter,
    },
    [OrderField.CustomSurcharge]: {
        key: OrderField.CustomSurcharge,
        label: "Custom Surcharge",
        accessor: (data) => data.customSurchargeTotal || 0,
        formatter: moneyFormatter,
    },
    [OrderField.CustomDiscounts]: {
        key: OrderField.CustomDiscounts,
        label: "Custom Discounts",
        accessor: (data) => data.customDiscountsTotal || 0,
        formatter: moneyFormatter,
    },
    [OrderField.UpCharge]: {
        key: OrderField.UpCharge,
        label: "Up Charge",
        accessor: (data) => data.upChargeAmount,
        formatter: moneyFormatter,
    },

    // Delivery & Service Fees
    [OrderField.DeliveryCharge]: {
        key: OrderField.DeliveryCharge,
        label: "Delivery Charge",
        accessor: (data) => data.delivery1PFee,
        formatter: moneyFormatter,
    },
    [OrderField.ThirdPartyDeliveryCharge]: {
        key: OrderField.ThirdPartyDeliveryCharge,
        label: "3P Delivery Charge",
        accessor: (data) => data.delivery3PFee,
        formatter: moneyFormatter,
    },

    [OrderField.SnackpassFee]: {
        key: OrderField.SnackpassFee,
        label: "Snackpass Fee",
        accessor: (data) => data.commission,
        formatter: moneyFormatter,
    },
    [OrderField.ProcessingFee]: {
        key: OrderField.ProcessingFee,
        label: "Processing Fee",
        accessor: (data) => data.processingFee,
        formatter: moneyFormatter,
    },
    [OrderField.ThirdPartyFees]: {
        key: OrderField.ThirdPartyFees,
        label: "Estimated Third Party Fees",
        accessor: (data) => data.thirdPartyFees,
        formatter: moneyFormatter,
    },
    [OrderField.CustomerToStoreFees]: {
        key: OrderField.CustomerToStoreFees,
        label: "Cust. To Store Fees",
        accessor: (data) => data.allCustomerToStoreFees,
        formatter: moneyFormatter,
    },

    // Taxes
    [OrderField.TaxesYouOwe]: {
        key: OrderField.TaxesYouOwe,
        label: "Tax",
        accessor: (data) => data.storeTaxesToRemit,
        formatter: moneyFormatter,
    },
    [OrderField.TaxesRemitted]: {
        key: OrderField.TaxesRemitted,
        label: "Taxes Remitted On Your Behalf",
        accessor: (data) => data.storeTaxesWithheld,
        formatter: moneyFormatter,
        tooltip:
            "Taxes that Snackpass will remit to the government on your behalf",
    },
    [OrderField.ThirdPartyTaxes]: {
        key: OrderField.ThirdPartyTaxes,
        label: "Estimated Third-Party Taxes",
        accessor: (data) => data.store3PTaxesToRemit,
        formatter: moneyFormatter,
    },

    [OrderField.Tip]: {
        key: OrderField.Tip,
        label: "Tips",
        accessor: (data) => data.tip,
        formatter: moneyFormatter,
    },
    [OrderField.Total]: {
        key: OrderField.Total,
        label: "Total",
        accessor: (data) => data.totalSales,
        formatter: moneyFormatter,
    },

    // Sales Calculations
    [OrderField.NetSales]: {
        key: OrderField.NetSales,
        label: "Net Sales",
        accessor: (data) => data.netSales,
        formatter: moneyFormatter,
    },
    [OrderField.GrossSales]: {
        key: OrderField.GrossSales,
        label: "Gross Sales",
        accessor: (data) => data.grossSalesAmount,
        formatter: moneyFormatter,
    },
    [OrderField.ThirdPartyPayout]: {
        key: OrderField.ThirdPartyPayout,
        label: "Estimated Third-Party Payout",
        accessor: (data) => data.expected3PPayout,
        formatter: moneyFormatter,
    }, // Payment Info
    [OrderField.PaymentMethod]: {
        key: OrderField.PaymentMethod,
        label: "Payment Method",
        accessor: (data) => data.paymentMethod,
        formatter: (_, data) => ({
            value: data?.paymentMethod ?? "",
            readable: data ? formatPaymentMethod(data) : "",
        }),
    },
    [OrderField.ExpectedCash]: {
        key: OrderField.ExpectedCash,
        label: "Cash",
        accessor: (data) => data.expectedCashCollected,
        formatter: moneyFormatter,
    },

    [OrderField.GiftCardCredit]: {
        key: OrderField.GiftCardCredit,
        label: "Gift Card Redemp.",
        accessor: (data) => data.giftCardCredit,
        formatter: moneyFormatter,
    },
    [OrderField.StoreCredit]: {
        key: OrderField.StoreCredit,
        label: "Store Credit Redemp.",
        accessor: (data) => data.storeCredit,
        formatter: moneyFormatter,
    },
    // Modification Info
    [OrderField.RefundedBy]: {
        key: OrderField.RefundedBy,
        label: "Refunded By",
        accessor: (data) => data.refundedBy,
        formatter: stringFormatter,
    },
    [OrderField.RefundedAmount]: {
        key: OrderField.RefundedAmount,
        label: "Refunded Amount",
        accessor: (data) => data.refundedAmount,
        formatter: moneyFormatter,
    },
    [OrderField.UpChargedBy]: {
        key: OrderField.UpChargedBy,
        label: "Up-Charged By",
        accessor: (data) => data.upchargedBy,
        formatter: stringFormatter,
    },
    [OrderField.CashAcceptedBy]: {
        key: OrderField.CashAcceptedBy,
        label: "Cash Accepted By",
        accessor: (data) => data.cashAcceptedBy,
        formatter: stringFormatter,
    },
    [OrderField.CreatedBy]: {
        key: OrderField.CreatedBy,
        label: "Created By",
        accessor: (data) => data.orderCreatedBy,
        formatter: stringFormatter,
    },

    // System IDs (SnackTeam Only)
    [OrderField.ID]: {
        key: OrderField.ID,
        label: "ID",
        accessor: (data) => data.purchaseId,
        formatter: stringFormatter,
        snackTeamOnly: true,
    },
    [OrderField.PayoutID]: {
        key: OrderField.PayoutID,
        label: "Payout ID",
        accessor: (data) => data.payoutId,
        formatter: stringFormatter,
        snackTeamOnly: true,
    },
};

export const formatForCSV = (
    purchases: PurchaseReportData[],
    timezone: string,
    isSnackTeam = false,
    showRemittance = false,
): Record<string, string>[] => purchases.map((purchase) => {
        const columns = Object.values(COLUMNS).filter(
            (col) =>
                (!col.snackTeamOnly || isSnackTeam) &&
                (col.key !== OrderField.TaxesRemitted || showRemittance),
        );

        return columns.reduce<Record<string, string>>(
            (acc, col) => ({
                ...acc,
                [col.label]: col.formatter(
                    col.accessor(purchase),
                    purchase,
                    timezone,
                ).readable,
            }),
            {},
        );
    });
