import { useCallback, useMemo } from "react";

import {
    GetPromotionsDocument,
    useDeletePromotionMutation,
} from "src/api/graphql/generated/types";

export function useDeletePromotion() {
    const [trigger, { loading, error, reset, data }] =
        useDeletePromotionMutation();

    const deletePromotion = useCallback(
        async (id: string) =>
            trigger({
                variables: { id },
                refetchQueries: [GetPromotionsDocument],
            }).finally(reset),
        [reset, trigger],
    );

    return useMemo(
        () => ({
            deletePromotion,
            loading,
            error,
            data,
        }),
        [deletePromotion, loading, error, data],
    );
}
