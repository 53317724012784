// This is legacy modules that have not been moved to the new API modules structure yet.
import {
    IChain,
    IGift,
    InventoryUnit,
    IProduct,
    Printer,
    PrinterWithPrepStation,
    IPromotion,
    IKiosk,
    AddonGroup,
    Addon,
} from "@snackpass/snackpass-types";
import { AxiosResponse } from "axios";
import { FetchTokenResponse } from "@snackpass/conversations.hooks";
import { SortingState } from "@tanstack/react-table";
import { PartialDeep } from "type-fest";

import { UserInvite } from "src/core/types";
import { ErrorWithCause } from "src/utils/errors";
import {
    GetChannelLinksRequest,
    GetChannelLinksResponse,
} from "#settings-integrations/modules/deliverect";

import { client } from "../client";

export class Tables {
    static async delete(tableId: string) {
        return client.delete(`/tables/${tableId}`).catch((cause) => {
            throw new ErrorWithCause(
                `api.Tables.delete: DELETE to /tables/${tableId} failed`,
                cause,
            );
        });
    }
}

export class Logs {
    static send = async (body: Object) =>
        client.post(`/logs`, body).catch((cause) => {
            throw new ErrorWithCause(
                `api.Logs.send: POST to /logs failed`,
                cause,
            );
        });
}

export class Products {
    static async update(productId: string, body: Partial<IProduct>) {
        return client.put(`/products/${productId}`, body).catch((cause) => {
            throw new ErrorWithCause(
                `api.Products.update: PUT to /products/${productId} failed`,
                cause,
            );
        });
    }

    static async updateAddonGroups(body: {
        storeId: string;
        addonGroup: Omit<PartialDeep<AddonGroup>, "addons"> & {
            addons: Omit<Addon, "_id">[];
        };
        groupIDs: string[];
    }): Promise<AxiosResponse<{ products: Array<IProduct> }>> {
        return client
            .put(`/products/addonGroups/batchUpdate`, body)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Products.update: PUT to /products/addonGroups failed`,
                    cause,
                );
            });
    }

    static async create(body: Object) {
        return client.post(`/products`, body).catch((cause) => {
            throw new ErrorWithCause(
                `api.Products.create: POST to /products failed`,
                cause,
            );
        });
    }

    static async remove(productId: string) {
        return client.delete(`/products/${productId}`).catch((cause) => {
            throw new ErrorWithCause(
                `api.Products.remove: DELETE to /products/${productId} failed`,
                cause,
            );
        });
    }

    static async getTemplates(storeId: string) {
        return client
            .get<{ templateProducts: IProduct[] }>(`/products/templates`, {
                params: { storeId },
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Products.getTemplates: GET to /products/templates failed`,
                    cause,
                );
            });
    }

    static async createGiftCard(storeId: string, isDigital: boolean) {
        return client
            .post(`/products/createGiftCard`, { storeId, isDigital })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Products.createGiftCard: POST to /products/createGiftCard failed`,
                    cause,
                );
            });
    }
}

export class Promotions {
    static async list(
        storeId: string,
    ): Promise<AxiosResponse<{ promotions: Array<IPromotion> }>> {
        return client.get(`/promotions?storeId=${storeId}`).catch((cause) => {
            throw new ErrorWithCause(
                `api.Promotions.list: GET to /promotions?storeId=${storeId} failed`,
                cause,
            );
        });
    }

    static async create(
        body: Partial<IPromotion>,
    ): Promise<AxiosResponse<{ promotion: IPromotion }>> {
        return client.post(`/promotions`, body).catch((cause) => {
            throw new ErrorWithCause(
                `api.Promotions.create: POST to /promotions failed`,
                cause,
            );
        });
    }

    static async remove(_promoId: string): Promise<AxiosResponse<boolean>> {
        console.log("Not implemented yet.");
        //@ts-expect-error Improper return type while endpoint is not active.
        return;
        // return client.delete(`/promotions/${promoId}`);
    }

    static async update(
        promoId: string,
        body: Partial<IPromotion>,
    ): Promise<AxiosResponse<{ promotion: IPromotion }>> {
        return client.put(`/promotions/${promoId}`, body).catch((cause) => {
            throw new ErrorWithCause(
                `api.Promotions.update: PUT to /promotions/${promoId} failed`,
                cause,
            );
        });
    }
}

export class Auth {
    static async sendVerification(phoneNumber: string) {
        return client
            .post("phoneAuthentication/sendVerification", {
                phoneNumber,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Auth.sendVerification: POST to phoneAuthentication/sendVerification failed`,
                    cause,
                );
            });
    }

    static async verify(phoneNumber: string, code: string, createUser = false) {
        return client
            .post("phoneAuthentication/verifyPhone", {
                phoneNumber,
                code,
                createUser,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Auth.verify: Post to phoneAuthentication/verifyPhone failed`,
                    cause,
                );
            });
    }

    static async verifyWithPhoneTakeover(
        phoneNumber: string,
        code: string,
        storeId: string,
    ) {
        return client.post(
            "phoneAuthentication/verify-phone-with-take-phone-number",
            {
                phoneNumber,
                code,
                storeId,
            },
        );
    }
}

export class Inventory {
    static async create({
        storeId,
        name,
        sku,
        baseUnit,
        costPerUnitCents,
    }: {
        storeId: string;
        name: string;
        sku: string;
        baseUnit: InventoryUnit;
        costPerUnitCents: number;
    }) {
        return client
            .post(`/inventory/items`, {
                storeId,
                name,
                sku,
                baseUnit,
                costPerUnitCents,
                isHidden: false,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Inventory.create: POST to /inventory/items failed`,
                    cause,
                );
            });
    }

    static async update(
        id: string,
        {
            costPerUnitCents,
            name,
            sku,
            isHidden,
        }: {
            costPerUnitCents?: number;
            name?: string;
            sku?: string;
            isHidden?: boolean;
        },
    ) {
        return client.patch(`/inventory/items/${id}`, {
            costPerUnitCents,
            name,
            sku,
            isHidden,
        });
    }

    static async get(storeId: string) {
        return client.get(`/inventory/stores/${storeId}/items`);
    }

    static async getReports(
        storeId: string,
        startDate: string,
        endDate: string,
    ) {
        return client.get(
            `/inventory/stores/${storeId}/item-reports?startDate=${startDate}&endDate=${endDate}`,
        );
    }

    static async adjust(id: string, unitChange: number) {
        return client.put(`/inventory/items/${id}/adjust`, {
            unitChange,
        });
    }
}

export class Chains {
    static async get(chainId: string) {
        return client
            .get<{ chain: IChain }>(`/chains/${chainId}`)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Chains.get: GET to /chains/${chainId} failed`,
                    cause,
                );
            });
    }
}

export class Kiosks {
    static async getKiosksForStore(
        storeId: string,
    ): Promise<{ data: { devices: IKiosk[] } }> {
        return client.get(`/kiosks/store/${storeId}`).catch((cause) => {
            throw new ErrorWithCause(
                `api.Kiosks.getKiosksForStore: GET to /kiosks/store/:storeId failed`,
                cause,
            );
        });
    }
}

export class Printers {
    static async getPrinters(
        storeId: string,
    ): Promise<AxiosResponse<{ printers: Array<PrinterWithPrepStation> }>> {
        return client.get(`/printers?storeId=${storeId}`).catch((cause) => {
            throw new ErrorWithCause(
                `api.Printers.getPrinters: GET to /printers?storeId=${storeId} failed`,
                cause,
            );
        });
    }

    static async updatePrinter(
        printerId: string,
        printerData: unknown,
    ): Promise<AxiosResponse<{ printer: Printer }>> {
        return client
            .patch(`/printers/${printerId}`, printerData)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Printers.updatePrinter: PATCH to /printers/${printerId} failed`,
                    cause,
                );
            });
    }

    static async registerPrinter(
        storeId: string,
        serial: string,
    ): Promise<AxiosResponse<{ printer: Printer }>> {
        return client
            .post("/printers/register", { storeId, serial })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Printers.registerPrinter: POST to /printers/register failed`,
                    cause,
                );
            });
    }
}

export class Gifts {
    static async createStoreGift(params: {
        toUserId: string;
        storeId: string;
        message: string;
        rewardTemplate: {
            name: string;
            nameForStore: string;
            expirationDate: Date;
            productIds: string[];
            categories: string[];
            storewide: boolean;
            discount: {
                percentOff: number;
            };
        };
        hideOnClient?: boolean;
    }): Promise<AxiosResponse<IGift>> {
        return client.post<IGift>("/gifts/store", params).catch((cause) => {
            throw new ErrorWithCause(
                `api.Gifts.createStoreGift: POST to /gifts/store failed`,
                cause,
            );
        });
    }
}

type RequestIntegrationRequest = {
    storeId: string;
    name: string;
    message: string;
};

type RequestIntegrationResponse = {
    data: {
        success: boolean;
    };
};

type GetIntegrationResponse = {
    data: {
        integrations: {
            [key: string]: {
                enabled: boolean;
            };
        };
    };
} & RequestIntegrationResponse;

export class IntegrationsRequest {
    static async requestIntegration(
        request: RequestIntegrationRequest,
    ): Promise<RequestIntegrationResponse> {
        return client
            .post(`/integrations/request/${request.storeId}`, {
                integration: request,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.IntegrationsRequest.requestIntegration: POST to /integrations/request/${request.storeId} failed`,
                    cause,
                );
            });
    }

    static async getIntegrations(
        storeId: string,
    ): Promise<GetIntegrationResponse> {
        return client.get(`/integrations/${storeId}`).catch((cause) => {
            throw new ErrorWithCause(
                `api.IntegrationsRequest.requestIntegration: GET to /integrations/${storeId} failed`,
                cause,
            );
        });
    }
}

export class Deliverect {
    static async getChannelLinks({
        storeId,
    }: GetChannelLinksRequest): Promise<GetChannelLinksResponse> {
        return client.get(`/deliverect/channels/${storeId}`).catch((cause) => {
            throw new ErrorWithCause(
                `api.deliverect.getChannelLinks: GET to deliverect/channels/${storeId} failed`,
                cause,
            );
        });
    }
}

export class Conversations {
    static async fetchToken(uuid: string): Promise<FetchTokenResponse> {
        return client
            .post(
                `/conversations/store/token`,
                { uuid },
                { headers: { Accept: "application/json" } },
            )
            .then((res) => res.data)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Conversations.fetchToken: GET to /conversations/store/token failed`,
                    cause,
                );
            });
    }

    static async generateResponse(chatChannel: string): Promise<string | null> {
        return client
            .get("/conversations/suggested-response", {
                params: { chatChannel },
            })
            .then((res) => res.data.response)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Conversations.generateResponse: GET to /conversations/suggested-response failed`,
                    cause,
                );
            });
    }
}

type newUser = {
    name: string;
    phoneNumber?: string;
    email?: string;
    pointsBalance: number;
    birthday?: string;
};

export class PunchCards {
    static async getPunchCardForUser(storeId: string, userId: string) {
        return client
            .get(`/punchcards`, {
                params: { storeId, userId },
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.PunchCards.getPunchCardForUser: GET to /punchcards failed`,
                    cause,
                );
            });
    }
    static async createPunchcard(storeId: string, user: newUser) {
        return client.post(`/punchcards`, { storeId, user }).catch((cause) => {
            throw new ErrorWithCause(
                `api.PunchCards.createPunchcard: POST to /punchcards failed`,
                cause,
            );
        });
    }
    static async update(punchcardId: string, updates: Object) {
        return client.put(`/punchcards/${punchcardId}`, updates);
    }
    static async getPaginatedPunchcards(
        storeId: string,
        pageSize: number,
        pageIndex: number,
        sorting: SortingState,
        search: string,
        query: string,
    ) {
        return client
            .post(`/punchcards/pagination`, {
                storeId,
                pageSize,
                pageIndex,
                sorting,
                search,
                query,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.PunchCards.getPaginatedPunchcards: POST to /punchcards/paginated failed`,
                    cause,
                );
            });
    }
    static async getStorePunchcardChanges(storeId: string) {
        return client
            .get(`/punchcards/store-changes`, {
                params: { storeId },
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.PunchCards.getPunchCardForUser: GET to /punchcards/store-changes failed`,
                    cause,
                );
            });
    }
}

export class AdminUserInvite {
    static async getInvite(nonce: string) {
        return client.get<{ invite: UserInvite }>(`/admin-invite/${nonce}`);
    }

    static async getInvites(storeId: string) {
        return client.get<{ invites: UserInvite[] }>("/admin-invite/invites", {
            params: { storeId },
        });
    }

    static async respond(nonce: string, accept: boolean) {
        return client.post("/admin-invite/respond", {
            nonce,
            accept,
        });
    }

    static async cancelInvite(storeId: string, inviteId: string) {
        return client.delete(`/admin-invite/${inviteId}`, {
            params: { storeId },
        });
    }

    static async resendInvite(storeId: string, inviteId: string) {
        return client.post(`/admin-invite/resend`, {
            storeId,
            inviteId,
        });
    }

    static async createAccount(
        nonce: string,
        firstName: string,
        lastName: string,
        password: string,
    ) {
        return client.post("/admin-invite/accept-and-create-account", {
            nonce,
            firstName,
            lastName,
            password,
        });
    }

    static async upgradeAccountToAcceptInvite(
        nonce: string,
        email: string,
        storeId: string,
    ) {
        return client.post("/admin-invite/upgrade-account-to-accept", {
            nonce,
            email,
            storeId,
        });
    }
}

export class Payouts {
    static async createPayout(
        id: string,
        input: {
            amountCents: number;
            description: string;
        },
    ) {
        return client
            .post<{ payoutId: string }>(`/payouts/${id}/one-off`, input)
            .catch((cause) => {
                throw new ErrorWithCause(
                    "POST /api/v4/payouts/:id/one-off failed",
                    cause,
                );
            });
    }
}
