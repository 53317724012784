import { ColumnDef } from "@tanstack/react-table";
import { PurchaseReportData } from "@snackpass/snackpass-types";
import { DataTableColumnHeader } from "src/@/components/ui/data-table/table-column-header";
import { OrderHistoryDetailsButton } from "./button";
import { Status } from "./order-status";
import { COLUMNS, OrderField } from "../../lib";

export type OrderColumnDef = ColumnDef<PurchaseReportData>;

export const createOrderColumns = (isSnackTeam: boolean): OrderColumnDef[] =>
    Object.values(COLUMNS)
        .filter((col) => !col.snackTeamOnly || isSnackTeam)
        .map((col) => ({
            id: col.key,
            header: ({ column }) => (
                <DataTableColumnHeader
                    buttonClassName="bg-transparent"
                    hideIconUntilSorted
                    column={column}
                    title={col.label}
                />
            ),
            accessorFn: (row) => col.accessor(row),
            cell: ({ row }) => {
                const data = row.original;

                if (col.key === OrderField.Details) {
                    return (
                        <OrderHistoryDetailsButton
                            text="View"
                            value={data?.purchaseId ?? ""}
                        />
                    );
                }

                if (col.key === OrderField.Status) {
                    return <Status purchaseReportData={data} />;
                }

                const formatted = col.formatter(col.accessor(data), data);
                return (
                    <span className="whitespace-nowrap">
                        {formatted.readable}
                    </span>
                );
            },
        }));
