import { useNavigate } from "react-router-dom";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
} from "src/@/components/ui/dialog";
import { Button } from "src/@/components/ui/button";
import { Routes as AppRoutes } from "#navigation/routes";
import { useStoreOnboardingStatus } from "#onboarding/hooks/use-store-onboarding-status";
import { useContext, useEffect, useState } from "react";
import { CheckCircle2, CircleDashed, ArrowRight } from "lucide-react";
import { OnboardingContext } from "#onboarding/utils/OnboardingContext";

interface OnboardingModalProps {
    open?: boolean;
    onOpenChange?: (open: boolean) => void;
}

export const OnboardingModal = ({
    open: controlledOpen,
    onOpenChange,
}: OnboardingModalProps) => {
    const navigate = useNavigate();
    const { missingFields, isFullyOnboarded } = useStoreOnboardingStatus();
    const { verificationFetched } = useContext(OnboardingContext);
    const [internalOpen, setInternalOpen] = useState(false);

    const isOpen = controlledOpen ?? internalOpen;
    const setIsOpen = onOpenChange ?? setInternalOpen;

    useEffect(() => {
        if (verificationFetched && !isFullyOnboarded) {
            setIsOpen(true);
        }
    }, [isFullyOnboarded, setIsOpen, verificationFetched]);

    const handleNavigateToOnboarding = () => {
        setIsOpen(false);
        navigate(AppRoutes.Onboarding);
    };

    return (
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogContent className="sm:max-w-[500px] p-4 sm:p-6">
                <DialogHeader className="space-y-3 text-left">
                    <DialogTitle className="flex items-center gap-2.5">
                        <CircleDashed className="w-5 h-5 sm:w-6 sm:h-6 text-foreground flex-shrink-0" />
                        <span className="text-lg sm:text-xl font-semibold text-foreground">
                            Snackpass Onboarding
                        </span>
                    </DialogTitle>
                    <DialogDescription className="space-y-4">
                        <p className="text-xs sm:text-sm text-muted-foreground text-left">
                            Let's get your store ready for success! We just need
                            a few more details to ensure the best experience for
                            your customers.
                        </p>
                        <div className="space-y-2 sm:space-y-3">
                            <h4 className="font-medium text-xs text-muted-foreground/70 text-left">
                                Required setup items:
                            </h4>
                            <ul className="space-y-2.5 sm:space-y-3">
                                {missingFields.map(({ section, fields }) => (
                                    <li
                                        key={section}
                                        className="flex items-start gap-2"
                                    >
                                        <CheckCircle2 className="w-4 h-4 sm:w-5 sm:h-5 text-primary flex-shrink-0 mt-1" />
                                        <div className="flex-1">
                                            <div className="font-medium text-xs sm:text-sm text-foreground">
                                                {section}
                                            </div>
                                            <div className="text-[11px] sm:text-xs text-muted-foreground">
                                                {fields.join(", ")}
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="pt-1">
                            <Button
                                onClick={handleNavigateToOnboarding}
                                className="w-full flex items-center justify-center gap-1.5 h-9 sm:h-10"
                                size="lg"
                            >
                                <span className="text-sm">
                                    Complete Store Setup
                                </span>
                                <ArrowRight className="w-3.5 h-3.5 sm:w-4 sm:h-4" />
                            </Button>
                        </div>
                    </DialogDescription>
                </DialogHeader>
            </DialogContent>
        </Dialog>
    );
};
