import { client } from "../client";
import { ErrorWithCause } from "src/utils/errors";

const REPORTS_REQUEST_TIMEOUT = 120 * 1000;

// Response Types
export interface CustomerResponse {
    userId: string;
    name: string;
    rank: number;
    lifetimeRank: number;
    email: string;
    phone: string;
    lastOrder: string;
    customerSince: string;
    repeatCustomer: number;
    newCustomer: number;
    lifetimeOrders: number;
    lifetimeSpent: number;
    frequency: number;
    appOrders: number;
    onlineOrders: number;
    kioskOrders: number;
    thirdPartyOrders: number;
    totalOrders: number;
    totalSpent: number;
    points: number;
    giftcard: number;
}

export type CustomerTotals = {
    new: number;
    repeat: number;
    app: number;
};

export interface AggregateResponse {
    current: CustomerTotals;
    previous: CustomerTotals;
    change: CustomerTotals;
}

export interface CustomerSummary {
    totalPurchases: number;
    appPurchases: number;
    uniqueCustomers: number;
    uniqueAppCustomers: number;
    newCustomers: number;
    repeatCustomers: number;
}

export interface CustomerSummaryResponse {
    storeId: string;
    startDate: string;
    endDate: string;
    customersSummary: CustomerSummary;
    success: boolean;
}

// Request Types
export interface DateRangeParams {
    startDate?: string;
    endDate: string;
}

export interface ComparisonDateRangeParams extends DateRangeParams {
    compareToStartDate?: string;
    compareToEndDate?: string;
}

export class Customers {
    static async getCustomers(
        storeId: string,
        params: ComparisonDateRangeParams & { numRows?: number },
    ) {
        return client
            .get<{
                customers: CustomerResponse[];
                aggregate: AggregateResponse;
            }>("/rdb-reports/customers", {
                params: { storeId, ...params },
                timeout: REPORTS_REQUEST_TIMEOUT,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    "api.reports.customers.getCustomers: GET to /rdb-reports/customers failed",
                    cause,
                );
            });
    }

    static async getCustomersSummary(storeId: string, params: DateRangeParams) {
        return client
            .get<CustomerSummaryResponse>("/rdb-reports/customers/summary", {
                params: { storeId, ...params },
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    "api.reports.customers.getCustomersSummary: GET to /rdb-reports/customers/summary failed",
                    cause,
                );
            });
    }

    static async getCustomersMonthly(storeId: string, params: DateRangeParams) {
        return client
            .get<{
                success: boolean;
                summary: CustomerSummary[];
            }>("/rdb-reports/customers/monthly", {
                params: { storeId, ...params },
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    "api.reports.customers.getCustomersMonthly: GET to /rdb-reports/customers/monthly failed",
                    cause,
                );
            });
    }
}

export default Customers;
