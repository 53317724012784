import { TrashIcon } from "@radix-ui/react-icons";
import { memo, useCallback } from "react";
import { toast } from "sonner";

import { useDeletePromotion } from "#new-promotions/hooks";
import { Button } from "src/@/components/ui/button";

export type DeletePromotionButtonProps = {
    id: string;
    name: string;
};

export const DeletePromotionButton = memo(
    ({ id, name }: DeletePromotionButtonProps) => {
        const { deletePromotion } = useDeletePromotion();

        const trigger = useCallback(
            async () =>
                deletePromotion(id).then(() => toast.success(`Delete ${name}`)),
            [deletePromotion, id, name],
        );

        return (
            <Button
                className="bg-critical-light"
                type="button"
                onClick={trigger}
            >
                <TrashIcon className="h-4 w-4" />
            </Button>
        );
    },
);
