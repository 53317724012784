import { useNavigate, useMatches } from "react-router-dom";

import { SidebarTrigger } from "src/@/components/ui/sidebar";
import { Separator } from "src/@/components/ui/separator";
import { Breadcrumb } from "src/@/components/ui/breadcrumb";
import { RouteHandle } from "#navigation/types";

export function NavigationHeader() {
    const navigate = useNavigate();
    const matches = useMatches();
    const bcItems = matches
        .filter(({ handle }) => Boolean((handle as RouteHandle)?.title)) // if the match doesn't have a title, ignore it
        .map(({ pathname, handle }) => ({
            url: pathname === "/" ? null : pathname, // if pathname is "/", there is not a page or component to navigate to
            title: (handle as RouteHandle).title ?? "...",
        }));

    // Check if we can go back in history and the previous page was from our site
    const canGoBack =
        window.history.state?.idx > 0 &&
        document.referrer.startsWith(window.location.origin);

    return (
        <header className="sticky top-0 z-10 flex h-16 shrink-0 items-center gap-2 rounded-xl bg-background">
            <div className="flex items-center gap-2 px-4">
                {/* not working well. comment out for now  */}
                {/* {canGoBack ? (
                    <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => navigate(-1)}
                    >
                        <ArrowLeft className="h-4 w-4" />
                    </Button>
                ) : null} */}
                <SidebarTrigger className="-ml-1" />
                <Separator orientation="vertical" className="mr-2 h-4" />
                <Breadcrumb items={bcItems} />
            </div>
        </header>
    );
}
