import { AxiosResponse } from "axios";
import {
    CashDrawer,
    CashDrawerWithPrinter,
    PrepStation,
} from "@snackpass/snackpass-types";
import { ErrorWithCause } from "src/utils/errors";
import { client } from "../client";

export class PrepStations {
    static async getPrepStations(
        storeId: string,
    ): Promise<AxiosResponse<{ prepStations: Array<PrepStation> }>> {
        return client
            .get(`/prep-stations?storeId=${storeId}`)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.PrepStations.getPrepStations: GET to /prep-stations?storeId=${storeId} failed`,
                    cause,
                );
            });
    }

    static async updatePrepStation(
        prepStationId: string,
        data: Partial<PrepStation & { printers: Array<string> }>,
    ): Promise<{ data: { prepStation: PrepStation } }> {
        return client
            .patch(`/prep-stations/${prepStationId}`, {
                ...data,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.PrepStations.updatePrepStations: PATCH to /prep-stations/${prepStationId} failed`,
                    cause,
                );
            });
    }

    static async createPrepStation(
        data: Partial<PrepStation & { printers: Array<string> }>,
    ): Promise<AxiosResponse<{ prepStation: PrepStation }>> {
        return client
            .post("/prep-stations", {
                ...data,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.PrepStations.createPrepStation: POST to /prep-stations failed`,
                    cause,
                );
            });
    }

    static async deletePrepStation(
        prepStationId: string,
    ): Promise<AxiosResponse<null>> {
        return client
            .delete(`/prep-stations/${prepStationId}`)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.PrepStations.deletePrepStations: DELETE to /prep-stations/${prepStationId} failed`,
                    cause,
                );
            });
    }
}

export class CashDrawers {
    static async getCashDrawers(
        storeId: string,
    ): Promise<AxiosResponse<{ cashDrawers: CashDrawerWithPrinter[] }>> {
        return client.get(`/cash-drawers/store/${storeId}`).catch((cause) => {
            throw new ErrorWithCause(
                `api.CashDrawers.getCashDrawers: GET to /cash-drawers/store/${storeId} failed`,
                cause,
            );
        });
    }

    static async createCashDrawer(
        storeId: string,
        data: Partial<CashDrawer>,
    ): Promise<AxiosResponse> {
        return client
            .post(`/cash-drawers/store/${storeId}`, data)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.CashDrawers.createCashDrawer: POST to /cash-drawers/store/${storeId} failed`,
                    cause,
                );
            });
    }

    static async updateCashDrawer(
        cashDrawerId: string,
        data: Partial<CashDrawer>,
    ): Promise<AxiosResponse> {
        return client
            .patch(`/cash-drawers/${cashDrawerId}`, data)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.CashDrawers.updateCashDrawer: PATCH to /cash-drawers/${cashDrawerId} failed`,
                    cause,
                );
            });
    }

    static async deleteCashDrawer(
        cashDrawerId: string,
    ): Promise<AxiosResponse> {
        return client.delete(`/cash-drawers/${cashDrawerId}`).catch((cause) => {
            throw new ErrorWithCause(
                `api.CashDrawers.deleteCashDrawer: DELETE to /cash-drawers/${cashDrawerId} failed`,
                cause,
            );
        });
    }
}
