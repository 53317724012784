import { useCallback } from "react";
import { useFormContext } from "react-hook-form";

import { useProducts } from "#menu/hooks";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "src/@/components/ui/select";
import { CreatePromotion } from "src/api/graphql/generated/types";
import { invariant } from "src/lib/invariant";

export function CartItemTriggerInput() {
    const products = useProducts();
    const { watch, setValue, formState } = useFormContext<CreatePromotion>();

    const trigger = watch("trigger.cartItemTrigger");

    const error = formState.errors.trigger?.cartItemTrigger;

    invariant(
        trigger,
        "CartItemTriggerInput should be used with cartItemTrigger",
    );

    const onChangeProductId = useCallback(
        (productId: string) =>
            setValue("trigger.cartItemTrigger.productId", productId),
        [setValue],
    );

    return (
        <div className="flex items-center justify-between">
            <div>
                <p>Product</p>
                {error?.productId?.message ? (
                    <span className="text-sm text-critical-light">
                        {error.productId.message}
                    </span>
                ) : null}
            </div>
            <Select value={trigger.productId} onValueChange={onChangeProductId}>
                <SelectTrigger className="w-40">
                    <SelectValue placeholder="Select Product" />
                </SelectTrigger>
                <SelectContent>
                    <SelectItem value="">Select Product</SelectItem>
                    {products.map(({ _id, name }) => (
                        <SelectItem key={_id} value={_id}>
                            {name}
                        </SelectItem>
                    ))}
                </SelectContent>
            </Select>
        </div>
    );
}
