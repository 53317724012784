import { PlusCircledIcon } from "@radix-ui/react-icons";

import { Button } from "src/@/components/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "src/@/components/ui/dropdown-menu";
import {
    ConditionScope,
    CreatePromotionCondition,
} from "src/api/graphql/generated/types";

export type AddConditionButtonProps = {
    onAddCondition: (condition: CreatePromotionCondition) => void;
};

export function AddConditionButton({
    onAddCondition,
}: AddConditionButtonProps) {
    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button>
                    <PlusCircledIcon />
                    Add Condition
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start" sideOffset={4}>
                <DropdownMenuItem
                    onClick={() =>
                        onAddCondition({
                            maxItemsCondition: {
                                scope: ConditionScope.Session,
                                maxItems: 0,
                            },
                        })
                    }
                >
                    <span>Max Items</span>
                </DropdownMenuItem>
                <DropdownMenuItem
                    onClick={() =>
                        onAddCondition({
                            itemCountCondition: {
                                scope: ConditionScope.Session,
                                productId: "",
                                count: 0,
                            },
                        })
                    }
                >
                    <span>Item Limit</span>
                </DropdownMenuItem>
                <DropdownMenuItem
                    onClick={() =>
                        onAddCondition({
                            categoryCountCondition: {
                                scope: ConditionScope.Session,
                                category: "",
                                count: 0,
                            },
                        })
                    }
                >
                    <span>Category Limit</span>
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    );
}
