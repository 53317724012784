// import { TrendingUp } from "lucide-react";
import { LineChart, Line, XAxis, CartesianGrid, YAxis } from "recharts";
import moment from "moment";
import {
    ChartConfig,
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
    ChartLegend,
    ChartLegendContent,
} from "src/@/components/ui/chart";
import { useQueryCustomersMonthly } from "#dashboard/hooks/use-query-customers-monthly";
import { ChartCard } from "./chart-card";
import { Routes } from "#navigation/routes";
import { TrendingUp } from "lucide-react";

const chartConfig = {
    total: {
        label: "Total Unique Customers",
        color: "hsl(var(--chart-4))",
    },
    new: {
        label: "New Customers",
        color: "hsl(var(--chart-1))",
    },
    repeat: {
        label: "Returning Customers",
        color: "hsl(var(--chart-2))",
    },
    app: {
        label: "App Customers",
        color: "hsl(var(--chart-3))",
    },
} satisfies ChartConfig;

export const CustomerInsightsMonthlyChart = () => {
    const { summary, isLoading } = useQueryCustomersMonthly();
    // Transform the summary data into the format expected by the chart
    const chartData =
        summary
            ?.map((item, index) => {
                const date = moment()
                    .subtract(13 - index, "months")
                    .startOf("month");
                return {
                    month: date.format("MMMM"),
                    total: item.uniqueCustomers,
                    new: item.newCustomers,
                    repeat: item.repeatCustomers,
                    app: item.uniqueAppCustomers,
                };
            })
            .filter((_, index, array) => index < array.length - 1) ?? [];

    // Calculate year-over-year growth
    const latestMonth = chartData[chartData.length - 1];
    const sameMonthLastYear = chartData[0]; // Last November

    const currentTotal = latestMonth ? latestMonth.new + latestMonth.repeat : 0;
    const lastYearTotal = sameMonthLastYear
        ? sameMonthLastYear.new + sameMonthLastYear.repeat
        : 0;
    const growth = lastYearTotal
        ? ((currentTotal - lastYearTotal) / lastYearTotal) * 100
        : 0;

    const footer = (
        <>
            <div className="flex gap-2 font-medium leading-none">
                {growth !== 0 && (
                    <>
                        {growth > 0 ? "+" : "-"}
                        {Math.abs(growth).toFixed(1)}% YoY{" "}
                        <TrendingUp className="h-4 w-4" />
                    </>
                )}
            </div>
            <div className="leading-none text-muted-foreground">
                Total Customers:{" "}
                {summary
                    ?.reduce((acc, item) => acc + item.uniqueCustomers, 0)
                    ?.toLocaleString()}
            </div>
        </>
    );

    return (
        <ChartCard
            title="Customer Insights"
            description="Last 12 Complete Months"
            footer={footer}
            isLoading={isLoading}
            to={Routes.ReportsCustomerDirectoryInsights}
        >
            <ChartContainer config={chartConfig}>
                <LineChart
                    data={chartData}
                    height={600}
                    margin={{
                        top: 20,
                        right: 20,
                        left: 60,
                        bottom: 20,
                    }}
                >
                    <CartesianGrid vertical={false} strokeDasharray="3 3" />
                    <XAxis
                        dataKey="month"
                        tickLine={false}
                        axisLine={false}
                        tickMargin={8}
                        tickFormatter={(value) => value.slice(0, 3)}
                        interval={1}
                    />
                    <YAxis
                        tickLine={false}
                        axisLine={false}
                        tickMargin={8}
                        width={50} // Set fixed width for numbers
                        tickFormatter={(value) => value.toLocaleString()} // Format large numbers
                    />
                    <ChartTooltip
                        cursor={false}
                        content={<ChartTooltipContent />}
                    />
                    <Line
                        dataKey="total"
                        type="monotone"
                        stroke={chartConfig.total.color}
                        strokeWidth={2}
                        dot={false}
                    />
                    <Line
                        dataKey="app"
                        type="monotone"
                        stroke={chartConfig.app.color}
                        strokeWidth={2}
                        dot={false}
                    />
                    <Line
                        dataKey="repeat"
                        type="monotone"
                        stroke={chartConfig.repeat.color}
                        strokeWidth={2}
                        dot={false}
                    />
                    <Line
                        dataKey="new"
                        type="monotone"
                        stroke={chartConfig.new.color}
                        strokeWidth={2}
                        dot={false}
                    />
                    <ChartLegend content={<ChartLegendContent />} />
                </LineChart>
            </ChartContainer>
        </ChartCard>
    );
};
