import { FormItem } from "src/@/components/ui/form";
import {
    CreatePromotionAdjustment,
    CreatePromotionAdjustmentScope,
    CreatePromotionPriceAdjustment,
} from "src/api/graphql/generated/types";
import { Separator } from "src/@/components/ui/separator";

import { ScopeInput } from "./ScopeInput";
import { AdjustmentInput } from "./AdjustmentInput";

export type AdjustmentRowProps = {
    value: CreatePromotionAdjustment;
    onChange: (value: CreatePromotionAdjustment) => void;
};

export function AdjustmentRow({ value, onChange }: AdjustmentRowProps) {
    const onChangeScope = (scope: CreatePromotionAdjustmentScope) =>
        onChange({ ...value, scope });

    const onChangeAdjustment = (adjustment: CreatePromotionPriceAdjustment) =>
        onChange({ ...value, adjustment });

    return (
        <FormItem>
            <ScopeInput value={value.scope} onChange={onChangeScope} />
            <Separator />
            <AdjustmentInput
                value={value.adjustment}
                onChange={onChangeAdjustment}
            />
        </FormItem>
    );
}
