import {
    IAttendanceSettings,
    IProduct,
    IStore,
    ThirdPartySource,
    PayoutPolicy,
    FeePolicy,
    IConvenienceFeePolicy,
    ICommissionPolicy,
    IFixedChargeFeePolicy,
} from "@snackpass/snackpass-types";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { z } from "zod";
import { EmployeeData } from "#core/types";
import { ErrorWithCause } from "src/utils/errors";

import { client } from "../client";
import { DeliveryFeeSettingsFormValues } from "#settings/settings-fees/utils/types";

export const REPORTS_REQUEST_TIMEOUT = 120 * 1000;
export type AnalyticsDashboard = "analytics" | "promos" | "crm" | "social";

export type PayoutHistoryItemStatus =
    | "initiated"
    | "in_transit"
    | "paid"
    | "unknown";
export type PayoutHistoryItem = {
    // One-off payments (e.g. reimbursement for incident, true-up for failed
    // payouts, etc.) will have a null periodStart and periodEnd.
    periodStart: Date | null;
    periodEnd: Date | null;

    /** Date the payout was sent. */
    payoutDate: Date;

    /** Estimated date the payout will arrive. Only supported for Stripe. */
    arrivalDate?: Date | null;

    netPayout: number;
    status: PayoutHistoryItemStatus;
    description: string;
};

export class Stores {
    static async getOne(storeId: string) {
        return client.get<{ store: IStore; success: boolean }>(
            `/stores/${storeId}?serviceFeeEnabled=true`,
        );
    }

    /**
     * This is a snackpass employee protected endpoint that returns all stores.
     * Don't depend on this for non-internal routes!
     */
    static async get() {
        // TODO: this seems dangerous
        // selectively return keys; usage: encodeURI(["name", "emoji"...].toString())
        return client
            .get(`/stores`, {
                params: {
                    _select: [
                        "_id",
                        "name",
                        "isArchived",
                        "region",
                        // Note: `address` is no longer the most up to date location info.
                        "address",
                        "addressComponents",
                    ].toString(),
                },
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.get: GET to /stores failed`,
                    cause,
                );
            });
    }

    static async getChainStores(
        chainId: string,
    ): Promise<AxiosResponse<{ stores: IStore[] }>> {
        return client
            .get(`/stores/chain/${chainId}`, {
                timeout: REPORTS_REQUEST_TIMEOUT,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.getChainStores: GET to /stores/chain/${chainId} failed`,
                    cause,
                );
            });
    }

    static async checkSlug(
        storeId: string,
        slug: string,
    ): Promise<{ data: { success: boolean; available: boolean } }> {
        return client
            .get(`/onboarding/${storeId}/slug/${slug}`)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.checkSlug: GET to /onboarding/${storeId}/slug/${slug} failed`,
                    cause,
                );
            });
    }

    static async getAnalyticsUrl(
        storeId: string,
        dashboard: AnalyticsDashboard,
    ) {
        return client
            .get(`/stores/${storeId}/analytics-url`, {
                params: { dashboard },
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.getAnalyticsUrl: GET to /stores/${storeId}/analytics-url failed`,
                    cause,
                );
            });
    }

    static async update(storeId: string, body: Object) {
        return client
            .put<{
                store: IStore;
                success?: boolean;
            }>(`/stores/${storeId}`, body)
            .catch((cause) => {
                if (axios.isAxiosError(cause)) {
                    throw cause;
                }

                throw new ErrorWithCause(
                    `api.Stores.update: PUT to /stores/${storeId} failed`,
                    cause,
                );
            });
    }

    static async updateActiveMenus(storeId: string, activeMenus: string[]) {
        return client
            .put<{
                store: IStore;
                success?: boolean;
            }>(`/stores/${storeId}/activeMenus`, {
                activeMenus,
            })
            .catch((cause) => {
                if (axios.isAxiosError(cause)) {
                    throw cause;
                }

                throw new ErrorWithCause(
                    `api.Stores.updateActiveMenus: PUT to /stores/${storeId}/activeMenus failed`,
                    cause,
                );
            });
    }

    static async updateCategory(
        storeId: string,
        categoryId: string,
        body: Object,
    ) {
        return client
            .put(`/stores/${storeId}/productCategories/${categoryId}`, body)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.updateCategory: PUT to /stores/${storeId}/productCategories/${categoryId} failed`,
                    cause,
                );
            });
    }

    static async updateManualThirdPartySources(
        storeId: string,
        thirdPartySources: ThirdPartySource[],
    ) {
        return client
            .put(`/stores/${storeId}/manualThirdPartySources`, {
                thirdPartySources,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.updateManualThirdPartySources: PUT to /stores/${storeId}/manualThirdPartySources failed`,
                    cause,
                );
            });
    }

    static async removeProductCategory(storeId: string, categoryId: string) {
        return client
            .delete(`/stores/${storeId}/productCategories/${categoryId}`)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.removeProductCategory: DELETE to /stores/${storeId}/productCategories/${categoryId} failed`,
                    cause,
                );
            });
    }

    static async getProducts(
        storeId: string,
        includeCateringProducts = true,
        includePayByWeight = true,
        includeSpecialProducts = true,
    ) {
        return client
            .get<{ products: IProduct[]; topProducts: string[] }>(
                `/stores/${storeId}/products`,
                {
                    params: {
                        includeCateringProducts,
                        includePayByWeight,
                        includeSpecialProducts,
                    },
                },
            )
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.getProducts: GET to /stores/${storeId}/products failed`,
                    cause,
                );
            });
    }

    static async listTables(storeId: string) {
        return client.get(`/stores/${storeId}/tables`).catch((cause) => {
            throw new ErrorWithCause(
                `api.Stores.listTables: GET to /stores/${storeId}/tables failed`,
                cause,
            );
        });
    }

    static async createTable(storeId: string, body: Object) {
        return client.post(`/stores/${storeId}/tables`, body).catch((cause) => {
            throw new ErrorWithCause(
                `api.Stores.createTable: POST to /stores/${storeId}/tables failed`,
                cause,
            );
        });
    }

    static async deleteTable(storeId: string, tableId: string) {
        return client
            .delete(`/stores/${storeId}/tables/${tableId}`)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.deleteTable: DELETE to /stores/${storeId}/tables/${tableId} failed`,
                    cause,
                );
            });
    }

    /**
     * returns an array of items indicating payment history entries
     */
    static async getPayoutHistory(
        storeId: string,
        since: Date,
        until: Date,
        config?: AxiosRequestConfig,
    ): Promise<PayoutHistoryItem[]> {
        return client
            .get<{ payouts: PayoutHistoryItem[] }>(
                `/stores/${storeId}/payout-history`,
                {
                    ...(config || {}),
                    params: {
                        ...config?.params,
                        since,
                        until,
                        useUnifiedFormat: true,
                    },
                },
            )
            .then((res) => res.data.payouts)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.getPayoutHistory failed`,
                    cause,
                );
            });
    }

    static async getImportStatus(storeId: string) {
        return client.get(`/stores/${storeId}/import-status`).catch((cause) => {
            throw new ErrorWithCause(
                `api.Stores.getImportStatus: GET to /stores/${storeId}/import-status failed`,
                cause,
            );
        });
    }

    static async importMenuV2({
        storeId,
        isLargeMenu,
        url,
        catalogId,
    }: {
        storeId: string;
        isLargeMenu: boolean;
        url: string;
        catalogId: string;
    }) {
        return client
            .post<{
                status: string;
            }>(`/stores/${storeId}/import-menu-v2`, {
                catalogId,
                isLargeMenu,
                url,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.importMenuV2: POST to /stores/${storeId}/import-menu-v2 failed`,
                    cause,
                );
            });
    }

    static async getEmployees(
        storeId: string,
    ): Promise<AxiosResponse<{ employees: EmployeeData[] }>> {
        return client.get(`/stores/${storeId}/employees`).catch((cause) => {
            throw new ErrorWithCause(
                `api.Stores.getEmployees: GET to /stores/${storeId}/employees failed`,
                cause,
            );
        });
    }

    static async updateEmployee(
        storeId: string,
        employeeData: EmployeeData,
    ): Promise<AxiosResponse<{ employee: EmployeeData }>> {
        return client
            .post(`/stores/${storeId}/employees/${employeeData.id}/update`, {
                ...employeeData,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.updateEmployee: POST to /stores/${storeId}/employees/${employeeData.id}/update failed`,
                    cause,
                );
            });
    }

    static async addEmployee(
        storeId: string,
        employeeData: EmployeeData,
    ): Promise<AxiosResponse<{ employee: EmployeeData }>> {
        return client
            .post(`/stores/${storeId}/employees/add`, {
                ...employeeData,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.addEmployee: POST to /stores/${storeId}/employees/add failed`,
                    cause,
                );
            });
    }

    static async deleteEmployee(
        storeId: string,
        employeeId: string,
    ): Promise<AxiosResponse<null>> {
        return client
            .delete(`/stores/${storeId}/employees/${employeeId}`)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.deleteEmployee: DELETE to /stores/${storeId}/employees/${employeeId} failed`,
                    cause,
                );
            });
    }

    static async sendPinToEmployee(
        storedId: string,
        employee: EmployeeData,
    ): Promise<AxiosResponse<null>> {
        return client
            .post(`/ems/${storedId}/forgot-employee-pin`, {
                phone: employee.phone,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.sendPinToEmployee: POST to /ems/${storedId}/forgot-employee-pin failed`,
                    cause,
                );
            });
    }

    static async resetEmployeePin(
        storeId: string,
        employeeId: string,
        pin?: string,
    ): Promise<AxiosResponse<{ pin: string }>> {
        return client
            .put(`/ems/${storeId}/update-employee-pin`, {
                employeeId,
                pin,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.resetEmployeePin: POST to /ems/${storeId}/update-employee-pin failed`,
                    cause,
                );
            });
    }

    static async getUnusedPin(
        storeId: string,
    ): Promise<AxiosResponse<{ pin: string }>> {
        return client.get(`/ems/${storeId}/get-unused-pin`).catch((cause) => {
            throw new ErrorWithCause(
                `api.Stores.getUnusedPin: GET to /ems/${storeId}/get-unused-pin failed`,
                cause,
            );
        });
    }

    static async setAttendanceSettings(
        storeId: string,
        attendanceSettings: IAttendanceSettings,
    ): Promise<AxiosResponse<{ store: IStore }, unknown>> {
        return client
            .post(`/stores/attendance/${storeId}/settings`, attendanceSettings)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.setAttendanceSettings: POST to /stores/attendance/${storeId}/settings failed`,
                    cause,
                );
            });
    }

    static async getPurchaseChannels(
        storeId: string,
    ): Promise<AxiosResponse<{ channels: ThirdPartySource[] }>> {
        return client.get(`/stores/${storeId}/channels`).catch((cause) => {
            throw new ErrorWithCause(
                `api.Stores.getPurchaseChannels: GET to /stores/${storeId}/channels failed`,
                cause,
            );
        });
    }

    static async duplicateMenu(id: string, sourceStoreId: string | null) {
        return client
            .post(`/stores/${id}/duplicate-menu-from/${sourceStoreId}`)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.duplicateMenu: POST to /stores/${id}/duplicate-menu-from/${sourceStoreId} failed`,
                    cause,
                );
            });
    }

    static async getMyAdminStores(): Promise<
        AxiosResponse<{ stores: IStore[] }>
    > {
        return client
            .get(`/users/me/admin-stores`, {
                timeout: REPORTS_REQUEST_TIMEOUT,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.getMyAdminStores: GET to /users/me/admin-stores failed`,
                    cause,
                );
            });
    }

    static async getAdmins(storeId: string) {
        return client.get(`/stores/${storeId}/admins`).catch((cause) => {
            throw new ErrorWithCause(
                `api.Stores.getStoreAdmins: GET to /stores/${storeId}}/admins failed`,
                cause,
            );
        });
    }

    static async scheduleOwnershipTransfer(
        storeId: string,
        body: {
            mode: "entity_transfer" | "ownership_transfer";
            activeAt: Date;
            ownerEmail?: string;
        },
    ) {
        return client
            .post(`/stores/${storeId}/schedule-ownership-transfer`, body)
            .catch((e) => {
                if (axios.isAxiosError(e)) {
                    const parseResult = z
                        .object({
                            data: z.object({ message: z.string() }),
                        })
                        .safeParse(e.response);
                    if (parseResult.success) {
                        throw new Error(parseResult.data.data.message);
                    }
                }
                throw e;
            });
    }

    static async updatePendingPayoutPolicy(
        storeId: string,
        body: { type: PayoutPolicy["__t"] | null },
    ) {
        return client
            .patch(`/stores/${storeId}/pending-payout-policy`, body)
            .catch((e) => {
                if (axios.isAxiosError(e)) {
                    const parseResult = z
                        .object({
                            data: z.object({ message: z.string() }),
                        })
                        .safeParse(e.response);
                    if (parseResult.success) {
                        throw new Error(parseResult.data.data.message);
                    }
                }
                throw e;
            });
    }

    static async finishOnboarding(storeId: string) {
        return client.post(`/onboarding/${storeId}/finish`).catch((cause) => {
            throw new ErrorWithCause(
                `api.Stores.finishOnboarding: POST to ${`/onboarding/${storeId}/finish`}`,
                cause,
            );
        });
    }

    static async updateBagFeePolicy(storeId: string, bagFeePolicy?: FeePolicy) {
        return client
            .put(`/stores/fee-settings/${storeId}/bagFeePolicy`, {
                bagFeePolicy,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.updateBagFeePolicy: PUT to ${`/stores/${storeId}/bagFeePolicy`}`,
                    cause,
                );
            });
    }

    static async updateCustomFeeLabels(
        storeId: string,
        params: Pick<IStore, "customFeeLabels">,
    ) {
        return client
            .put(`/stores/fee-settings/${storeId}/customFeeLabels`, params)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.updateCustomFeeLabels: PUT to ${`/stores/${storeId}/customFeeLabels`}`,
                    cause,
                );
            });
    }

    static async updateMinimumChargeAmount(
        storeId: string,
        minimumChargeAmountCents: number,
    ) {
        return client
            .put(`/stores/fee-settings/${storeId}/minimumChargeAmount`, {
                minimumChargeAmount: minimumChargeAmountCents,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.minimumChargeAmount: PUT to ${`/stores/${storeId}/minimumChargeAmount`}`,
                    cause,
                );
            });
    }

    static async updateConvenienceFeePolicy(
        storeId: string,
        convenienceFeePolicies: IConvenienceFeePolicy[],
    ) {
        return client
            .put(
                `/stores/fee-settings/${storeId}/storeConvenienceFeePolicies`,
                {
                    convenienceFeePolicies,
                },
            )
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.updateConvenienceFeePolicy: PUT to ${`/stores/${storeId}/storeConvenienceFeePolicies`}`,
                    cause,
                );
            });
    }

    static async updateServiceFeePolicy(
        storeId: string,
        feePolicies: FeePolicy[],
    ) {
        return client
            .put(
                `/stores/fee-settings/${storeId}/snackpassServiceFeePolicies`,
                {
                    feePolicies,
                },
            )
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.updateServiceFeePolicy: PUT to ${`/stores/${storeId}/snackpassServiceFeePolicies`}`,
                    cause,
                );
            });
    }

    static async updateDeliveryFeeSettings(
        storeId: string,
        body: DeliveryFeeSettingsFormValues,
    ) {
        return client
            .put(`/stores/fee-settings/${storeId}/deliveryFeeSettings`, body)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.updateDeliveryFeeSettings: PUT to ${`/stores/${storeId}/deliveryFeeSettings`}`,
                    cause,
                );
            });
    }

    static async updateCommissionPolicy(
        storeId: string,
        body: {
            defaultCommissionPolicy: { fixed: number; percent: number };
            customCommissionPolicies: ICommissionPolicy[];
        },
    ) {
        return client
            .put(`/stores/fee-settings/${storeId}/commissionPolicies`, body)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.updateCommissionPolicy: PUT to ${`/stores/${storeId}/commissionPolicies`}`,
                    cause,
                );
            });
    }

    static async updateChargePolicy(
        storeId: string,
        body: {
            chargeFeePolicy: Pick<IFixedChargeFeePolicy, "percentage" | "flat">;
            customChargeFeePolicies: IFixedChargeFeePolicy[];
        },
    ) {
        return client
            .put(`/stores/fee-settings/${storeId}/chargeFeePolicies`, body)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Stores.updateChargePolicy: PUT to ${`/stores/${storeId}/chargeFeePolicies`}`,
                    cause,
                );
            });
    }
}
