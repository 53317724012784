import * as React from "react";
import { Check, X, ChevronsUpDown, Search } from "lucide-react";
import { cn } from "src/@/lib/utils";
import { Badge } from "src/@/components/ui/badge";
import { Button } from "src/@/components/ui/button";
import { Input } from "src/@/components/ui/input";
import {
    Dialog,
    DialogContent,
    DialogTrigger,
} from "src/@/components/ui/dialog";
import { ScrollArea } from "src/@/components/ui/scroll-area";

export type MultiSelectOption = {
    label: string;
    value: string;
    disabled?: boolean;
};

interface MultiSelectProps {
    options: MultiSelectOption[];
    selected: string[];
    onChange: (values: string[]) => void;
    placeholder?: string;
    searchPlaceholder?: string;
    emptyMessage?: string;
    disabled?: boolean;
    className?: string;
}

export function MultiSelect({
    options,
    selected,
    onChange,
    placeholder = "Select options...",
    searchPlaceholder = "Search options...",
    emptyMessage = "No options found.",
    disabled = false,
    className,
}: MultiSelectProps) {
    const [open, setOpen] = React.useState(false);
    const [search, setSearch] = React.useState("");

    const selectedOptions = options.filter((option) =>
        selected.includes(option.value),
    );

    const filteredOptions = React.useMemo(() => options.filter((option) =>
            option.label.toLowerCase().includes(search.toLowerCase()),
        ), [options, search]);

    const handleUnselect = (value: string) => {
        onChange(selected.filter((v) => v !== value));
    };

    const handleSelect = (option: MultiSelectOption) => {
        if (selected.includes(option.value)) {
            onChange(selected.filter((v) => v !== option.value));
        } else {
            onChange([...selected, option.value]);
        }
    };

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={open}
                    className={cn(
                        "w-full min-h-10 h-auto justify-between hover:bg-background",
                        className,
                    )}
                    disabled={disabled}
                >
                    <div className="flex max-w-[calc(100%-2rem)] flex-wrap gap-1 py-1">
                        {selectedOptions.length === 0 && (
                            <span className="text-muted-foreground">
                                {placeholder}
                            </span>
                        )}
                        {selectedOptions.map((option) => (
                            <Badge
                                key={option.value}
                                variant="secondary"
                                className="mr-1 max-w-full px-1 py-0"
                            >
                                <span className="truncate">{option.label}</span>
                                <Button
                                    variant="ghost"
                                    size="sm"
                                    className="ml-1 h-auto shrink-0 p-0 text-muted-foreground hover:text-foreground"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleUnselect(option.value);
                                    }}
                                >
                                    <X className="h-3 w-3" />
                                </Button>
                            </Badge>
                        ))}
                    </div>
                    <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
            </DialogTrigger>
            <DialogContent className="max-w-[min(calc(100vw-2rem),400px)] p-0">
                <div className="flex h-[min(calc(100vh-4rem),400px)] flex-col">
                    <div className="flex items-center border-b bg-background px-3 py-2">
                        <Search className="mr-2 h-4 w-4 shrink-0 opacity-50" />
                        <Input
                            placeholder={searchPlaceholder}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            className="border-0 p-0 shadow-none focus-visible:ring-0"
                        />
                    </div>
                    <div className="flex-1 overflow-hidden">
                        <ScrollArea className="h-full">
                            <div className="p-2">
                                {filteredOptions.length === 0 ? (
                                    <p className="p-4 text-center text-sm text-muted-foreground">
                                        {emptyMessage}
                                    </p>
                                ) : (
                                    <div className="space-y-1">
                                        {filteredOptions.map((option) => {
                                            const isSelected =
                                                selected.includes(option.value);
                                            return (
                                                <div
                                                    key={option.value}
                                                    className={cn(
                                                        "flex items-center gap-2 rounded-sm px-2 py-1.5 text-sm cursor-pointer hover:bg-accent overflow-hidden",
                                                        option.disabled &&
                                                            "opacity-50 cursor-not-allowed",
                                                        isSelected &&
                                                            "bg-accent",
                                                    )}
                                                    onClick={() => {
                                                        if (!option.disabled) {
                                                            handleSelect(
                                                                option,
                                                            );
                                                        }
                                                    }}
                                                >
                                                    <div
                                                        className={cn(
                                                            "flex h-4 w-4 items-center justify-center rounded border border-primary shrink-0",
                                                            isSelected
                                                                ? "bg-primary text-primary-foreground"
                                                                : "opacity-50 [&_svg]:invisible",
                                                        )}
                                                    >
                                                        <Check
                                                            className={cn(
                                                                "h-3 w-3",
                                                            )}
                                                        />
                                                    </div>
                                                    <span className="flex-1 truncate">
                                                        {option.label.length >
                                                        34
                                                            ? option.label.substring(
                                                                  0,
                                                                  34,
                                                              ) + ".."
                                                            : option.label}
                                                    </span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        </ScrollArea>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}
