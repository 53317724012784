import { useMemo } from "react";
import { Building } from "lucide-react";

import { useOrders } from "../../orders-context";
import { getDashboardTransactionChannelOptions } from "src/redux/slices";
import { useSelector } from "react-redux";
import { FilterSelect2 } from "src/@/components/ui/filter-select-2";
import { Provider, PROVIDER_CONFIG } from "#sales-report/types";

export const OrdersProviderPicker = () => {
    const { providers, setProviders } = useOrders();

    const selectedProviders = useMemo(
        () => new Set(providers as Provider[]),
        [providers],
    );
    const channelOptions = useSelector(getDashboardTransactionChannelOptions);

    const options = useMemo(
        () =>
            channelOptions.map((e) => ({
                label: PROVIDER_CONFIG[e]?.friendlyName || e,
                value: e,
            })),
        [channelOptions],
    );

    return (
        <FilterSelect2
            title="Provider"
            icon={Building}
            selectedValues={selectedProviders}
            options={options}
            onOptionSelected={function (value: string): void {
                const channelValue = value as Provider;
                if (selectedProviders.has(channelValue)) {
                    selectedProviders.delete(channelValue);
                } else {
                    selectedProviders.add(channelValue);
                }
                setProviders(Array.from(selectedProviders) as Provider[]);
            }}
        />
    );
};
