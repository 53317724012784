import { useFormContext } from "react-hook-form";
import { memo, useCallback } from "react";

import { CreatePromotion } from "src/api/graphql/generated/types";
import { invariant } from "src/lib/invariant";

import { ConditionScopeInput } from "./ConditionScopeInput";
import { ItemsCountInput } from "./ItemsCountInput";

export type MaxItemsConditionInputProps = {
    index: number;
};

export const MaxItemsConditionInput = memo(
    ({ index }: MaxItemsConditionInputProps) => {
        const { watch, setValue } = useFormContext<CreatePromotion>();

        const condition = watch(`conditions.${index}.maxItemsCondition`);

        invariant(
            condition,
            "MaxItemsConditionInput should be used with a maxItemsCondition",
        );

        const onChangeMaxItems = useCallback(
            (maxItems: number) => {
                setValue("conditions.0.maxItemsCondition.maxItems", maxItems);
            },
            [setValue],
        );

        return (
            <div className="space-y-2">
                <ConditionScopeInput
                    condition="maxItemsCondition"
                    index={index}
                />
                <div className="flex items-center justify-between">
                    <span>Limit</span>
                    <ItemsCountInput
                        value={condition.maxItems}
                        onChange={onChangeMaxItems}
                    />
                </div>
            </div>
        );
    },
);
