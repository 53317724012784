import { useMemo } from "react";
import { match, P } from "ts-pattern";
import { NumberInput } from "@tremor/react";

import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "src/@/components/ui/select";
import { CreatePromotionPriceAdjustment } from "src/api/graphql/generated/types";

enum AdjustmentOption {
    Price = "Set Price",
    DiscountPercent = "Percent Off",
    DiscountFlat = "Flat Discount",
}

export type AdjustmentInputProps = {
    value: CreatePromotionPriceAdjustment;
    onChange: (value: CreatePromotionPriceAdjustment) => void;
};

export function AdjustmentInput({ value, onChange }: AdjustmentInputProps) {
    const adjustmentOption = useMemo(
        () =>
            match(value)
                .with({ price: P.number }, () => AdjustmentOption.Price)
                .with(
                    { discountFlat: P.number },
                    () => AdjustmentOption.DiscountFlat,
                )
                .with(
                    { discountPercent: P.number },
                    () => AdjustmentOption.DiscountPercent,
                )
                .otherwise(() => {
                    throw new Error(
                        `Invalid price adjustment option: ${value}`,
                    );
                }),
        [value],
    );

    const onChangeAdjustment = (option: AdjustmentOption) =>
        onChange(
            match(option)
                .with(AdjustmentOption.Price, () => ({
                    price: 0,
                }))
                .with(AdjustmentOption.DiscountFlat, () => ({
                    discountFlat: 0,
                }))
                .with(AdjustmentOption.DiscountPercent, () => ({
                    discountPercent: 0,
                }))
                .exhaustive(),
        );

    return (
        <div className="flex items-center gap-4">
            <Select value={adjustmentOption} onValueChange={onChangeAdjustment}>
                <SelectTrigger className="w-40">
                    <SelectValue placeholder="Select Discount" />
                </SelectTrigger>
                <SelectContent>
                    {Object.entries(AdjustmentOption).map(([key, value]) => (
                        <SelectItem key={key} value={value}>
                            {value}
                        </SelectItem>
                    ))}
                </SelectContent>
            </Select>
            {match(value)
                .with({ price: P.number }, ({ price }) => (
                    <NumberInput
                        icon={() => <span className="pl-3 text-sm">$</span>}
                        value={price / 100}
                        onChange={(e) =>
                            onChange({
                                price: Math.floor(
                                    parseFloat(e.target.value) * 100,
                                ),
                            })
                        }
                        placeholder="0.00"
                        min={0}
                        step={0.01}
                        enableStepper={false}
                        className="max-w-20"
                    />
                ))
                .with({ discountFlat: P.number }, ({ discountFlat }) => (
                    <NumberInput
                        icon={() => <span className="pl-3 text-sm">$</span>}
                        value={discountFlat / 100}
                        onChange={(e) =>
                            onChange({
                                discountFlat: Math.floor(
                                    parseFloat(e.target.value) * 100,
                                ),
                            })
                        }
                        placeholder="0.00"
                        min={0}
                        step={0.01}
                        enableStepper={false}
                        className="max-w-20"
                    />
                ))
                .with({ discountPercent: P.number }, ({ discountPercent }) => (
                    <NumberInput
                        icon={() => (
                            <span className="absolute right-20">%</span>
                        )}
                        value={discountPercent}
                        onChange={(e) =>
                            onChange({
                                discountPercent: parseInt(e.target.value),
                            })
                        }
                        placeholder="0"
                        min={0}
                        step={1}
                        className="max-w-20"
                    />
                ))
                .otherwise(() => {
                    throw new Error(
                        `Invalid price adjustment option: ${value}`,
                    );
                })}
        </div>
    );
}
