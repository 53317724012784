import { useEffect } from "react";
import { Toaster } from "sonner";
import _ from "lodash";
import { RouterProvider } from "react-router-dom";

import { useAppSelector } from "src/redux/hooks";
import { getUser } from "src/redux/selectors";
import SignIn from "#sign-in";
import { useFirebaseAuth } from "#hooks/initial/use-firebase-auth";
import { useHideIntercomLauncher } from "#hooks/initial/use-hide-intercom-launcher";
import { useMFA } from "#hooks/initial/use-mfa";
import { usePersistStoreId } from "#hooks/initial/use-persist-store-id";
import { useLoadSnackpassStores } from "#hooks/initial/use-load-snackpass-stores";
import { useSyncActiveStore } from "#hooks/initial/use-sync-active-store";
import { useQueryPageAccess } from "#hooks/initial/use-query-page-access";
import { useIdentify } from "#hooks/initial/use-identify";
import { useInvites } from "#navigation/hooks";
import { NotAdminPage } from "#utility-pages/not-admin-of-store-page";
import { router } from "#navigation/router-dom";
import { pathBeginsWithRoute } from "#app/helpers";
import { Routes } from "#navigation/routes";
import { Component as AcceptInvite } from "#accept-invite";

import { GlobalProviders } from "./providers/global-providers";

/**
 * This function will produce one of 2 outcomes:
 * 1. If user hasn't authed in a while they will be prompted for MFA
 * 2. If they pass the first check they will see our normal <Navigation>
 */

// TODO: clean this up. this is the remaining scattered logic that has accumulated over time
const DetermineNavigation = () => {
    const { isInitialized } = useFirebaseAuth();
    const { setHasVerifiedEmail, requireEmailVerification } = useMFA();
    const user = useAppSelector(getUser);

    // remove loading spinner when we ready to render the app
    useEffect(() => {
        if (user || isInitialized) {
            document.getElementById("rai-preloader-container")?.remove();
            window.PartnerAppJsApi?.signalReady();
        }
    }, [user, isInitialized]);

    // special exception for invite acceptance - doesn't load the rest of our application
    const path = window.location.pathname;
    if (pathBeginsWithRoute(path, Routes.NewUserAcceptInvite)) {
        const nonce = path.slice(Routes.NewUserAcceptInvite.length + 1);
        return <AcceptInvite nonce={nonce} />;
    }

    if (!user || requireEmailVerification) {
        return (
            <SignIn
                showEmailVerification={requireEmailVerification}
                setHasVerifiedEmail={setHasVerifiedEmail}
            />
        );
    }

    // TODO: move this to route handler later
    if (
        !user?.snackpassPermissions.isSnackpassEmployee &&
        _.isEmpty(user?.snackpassPermissions.storeIds)
    ) {
        return <NotAdminPage />;
    }

    return <RouterProvider router={router} />;
};

const Hooks = () => {
    useHideIntercomLauncher();
    useIdentify();
    useLoadSnackpassStores();
    usePersistStoreId();
    useSyncActiveStore();
    useQueryPageAccess();
    useInvites();

    return null;
};

const App = () => (
    <GlobalProviders>
        <Toaster />
        <Hooks />
        <DetermineNavigation />
    </GlobalProviders>
);

export default App;
