import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import api from "src/api/rest";
import { getStoreTimezone } from "#utils/helpers";
import { useSelector } from "react-redux";
import { getActiveStore } from "src/redux/selectors";
import { useDashboardContext } from "./use-dashboard-context";
import { logAndSendError } from "src/utils/errors";

export interface TopItemData {
    name: string;
    displayName: string;
    fullName: string;
    total: number;
    revenue: number;
    orders: number;
    color: string;
    categoryName?: string;
}

export interface CategoryData {
    name: string;
    value: number;
    color: string;
    orders: number;
    percent?: number;
}

const COLORS = [
    "hsl(var(--chart-1))", // blue
    "hsl(var(--chart-2))", // yellow
    "hsl(var(--chart-3))", // purple
    "hsl(var(--chart-4))", // orange
    "hsl(var(--chart-5))", // green
];

const truncateName = (name: string, maxLength = 14) => {
    if (name.length <= maxLength) return name;
    return `${name.slice(0, maxLength)}...`;
};

export const useQueryTopItems = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [topItems, setTopItems] = useState<TopItemData[]>([]);
    const [categoryData, setCategoryData] = useState<CategoryData[]>([]);

    const store = useSelector(getActiveStore);
    const { currentPeriodStartEnd, channels, providers, paymentMethods } =
        useDashboardContext();

    const fetchTopItems = useCallback(async () => {
        if (
            !store?._id ||
            !currentPeriodStartEnd?.startDate ||
            !currentPeriodStartEnd?.endDate
        ) {
            return;
        }

        try {
            setIsLoading(true);
            setError(null);

            const params = {
                storeId: store._id,
                startDate: currentPeriodStartEnd.startDate.format("YYYY-MM-DD"),
                endDate: currentPeriodStartEnd.endDate.format("YYYY-MM-DD"),
                timezone: getStoreTimezone(store),
                channel: JSON.stringify(providers),
                source: JSON.stringify(channels),
                paymentProviders: JSON.stringify(paymentMethods),
                fulfillment: JSON.stringify([]),
            };

            const response = await api.reports.getMenuItemInsights(params);

            if (!response.data?.items) {
                setTopItems([]);
                setCategoryData([]);
                return;
            }

            // Create maps for both items and categories
            const itemTotals = new Map<
                string,
                { orders: number; revenue: number }
            >();
            const categoryTotals = new Map<string, number>();

            // Loop through each day
            response.data.items.forEach((day: any) => {
                if (Array.isArray(day.items)) {
                    day.items.forEach((item: any) => {
                        if (
                            item.name &&
                            typeof item.orders === "number" &&
                            typeof item.sales === "number"
                        ) {
                            // Aggregate item data
                            const current = itemTotals.get(item.name) || {
                                orders: 0,
                                revenue: 0,
                            };
                            itemTotals.set(item.name, {
                                orders: current.orders + item.orders,
                                revenue: current.revenue + item.sales,
                            });

                            // Aggregate category data using categoryName from item
                            const categoryName =
                                item.categoryName || "Uncategorized";
                            const currentCategoryTotal =
                                categoryTotals.get(categoryName) || 0;
                            categoryTotals.set(
                                categoryName,
                                currentCategoryTotal + item.sales,
                            );
                        }
                    });
                }
            });

            // Transform item data
            const transformedItemData = Array.from(itemTotals.entries())
                .map(([name, data]) => ({
                    name,
                    displayName: truncateName(name),
                    fullName: name,
                    total: data.revenue,
                    revenue: data.revenue,
                    orders: data.orders,
                    color: "",
                }))
                .filter((item) => item.revenue > 0)
                .sort((a, b) => b.revenue - a.revenue)
                .slice(0, 7)
                .map((item, index) => ({
                    ...item,
                    color: COLORS[index % COLORS.length],
                }));

            // Transform category data
            const transformedCategoryData = Array.from(categoryTotals.entries())
                .map(([name, value]) => ({
                    name,
                    value,
                    color: COLORS[0],
                    orders: value,
                }))
                .filter((category) => category.value > 0)
                .sort((a, b) => b.value - a.value)
                .map((category, index) => ({
                    ...category,
                    color: COLORS[index % COLORS.length],
                }));

            setTopItems(transformedItemData);
            setCategoryData(transformedCategoryData);
        } catch (err) {
            if (!axios.isCancel(err)) {
                console.error("Error fetching top items:", err);
                logAndSendError(err);
                setError(err as Error);
            }
        } finally {
            setIsLoading(false);
        }
    }, [store, currentPeriodStartEnd, channels, providers, paymentMethods]);

    useEffect(() => {
        fetchTopItems();
    }, [fetchTopItems]);

    return {
        topItems,
        categoryData,
        isLoading,
        error,
        refetch: fetchTopItems,
    };
};
