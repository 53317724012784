import axios from "axios";
import { ErrorWithCause } from "src/utils/errors";
import { client } from "../client";
import config from "#config";

export const REPORTS_REQUEST_TIMEOUT = 120 * 1000;

interface SignedUploadParams {
    folder: string;
    resourceType: string; // "image" | "video" | "raw" | "auto"
    preset: string;
}

interface CloudinarySignature {
    signature: string;
    timestamp: number;
    folder: string;
    resource_type: string;
    upload_preset: string;
}

interface CloudinaryUploadResponse {
    secure_url: string;
    public_id: string;
}

/**
 * Get signed upload params to upload to cloudinary.
 * This is so we don't upload large data to our server directly
 */
async function getSignedUploadParams(
    params: SignedUploadParams,
): Promise<CloudinarySignature> {
    try {
        const response = await client.post<CloudinarySignature>(
            "/uploads/cloudinary/signature",
            {
                folder: params.folder,
                resourceType: params.resourceType,
                preset: params.preset,
            },
        );
        return response.data;
    } catch (cause) {
        throw new ErrorWithCause(
            "api.Uploads.getSignedUploadParams: POST to /uploads/cloudinary/signature failed",
            cause,
        );
    }
}

async function uploadToCloudinary(
    file: File,
    params: CloudinarySignature,
): Promise<CloudinaryUploadResponse> {
    const formData = new FormData();

    // Order matters for signature validation
    // Match the exact order used in server's paramsToSign
    formData.append("timestamp", params.timestamp.toString());
    formData.append("folder", params.folder);
    formData.append("upload_preset", params.upload_preset);

    // Add file and remaining parameters
    formData.append("file", file);
    formData.append("api_key", config.cloudinary.apiKey);
    formData.append("cloud_name", config.cloudinary.cloudName);
    formData.append("resource_type", params.resource_type);
    formData.append("signature", params.signature);

    try {
        const response = await axios.post<CloudinaryUploadResponse>(
            config.cloudinary.uploadUrl(
                config.cloudinary.cloudName,
                params.resource_type,
            ),
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            },
        );
        return response.data;
    } catch (cause) {
        if (axios.isAxiosError(cause)) {
            console.error("Cloudinary upload error:", {
                status: cause.response?.status,
                data: cause.response?.data,
                config: {
                    url: cause.config?.url,
                    params: cause.config?.params,
                },
            });
        }
        throw new ErrorWithCause(
            "api.Uploads.uploadToCloudinary: Direct upload to Cloudinary failed",
            cause,
        );
    }
}

export class Uploads {
    static getSignedUploadParams = getSignedUploadParams;
    static uploadToCloudinary = uploadToCloudinary;

    /**
     * @deprecated legacy upload endpoints
     */
    static async uploadMenuPhoto(formData: FormData, path: string) {
        return client
            .post(`/uploads/image`, formData, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                },
                params: {
                    path,
                },
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Uploads.uploadMenuPhoto: POST to /uploads/image failed`,
                    cause,
                );
            });
    }

    // The uploadMenuPhoto function above uses a datestring to create unique filenames so nothing is overwritten.
    // Here, we purposely want to overwrite any existing files so any time a new file is uploaded,
    // we dont have to do any sort of cleanup on outdated items, thus keeping our storage costs down,
    // since these files can be a larger file size compared to menu photos, which get heavily compressed.
    /**
     * @deprecated legacy upload endpoints
     */
    static async uploadAndReplaceImage(formData: FormData, path: string) {
        return client.post(`/uploads/uploadAndReplace`, formData, {
            headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
            },
            params: {
                path,
            },
        });
    }
    /**
     * @deprecated legacy upload endpoints
     */
    static async uploadVideo(formData: FormData, path: string) {
        return client
            .post(`/uploads/video`, formData, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                },
                params: {
                    path,
                },
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Uploads.uploadVideo: POST to /uploads/video failed`,
                    cause,
                );
            });
    }
}
