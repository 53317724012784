import { debounce } from "radash";
import { createContext, useMemo, useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { buildFetchVerification } from "#onboarding/utils/api/verification";
import { StoreVerification } from "#payouts/domain/types";
import { getActiveStore } from "src/redux/selectors";

type OnboardingContextType = {
    verification: StoreVerification | null;
    verificationFetched: boolean;
};

export const OnboardingContext = createContext<OnboardingContextType>(
    {} as OnboardingContextType,
);

const OnboardingProvider = ({ children }: { children: JSX.Element }) => {
    const store = useSelector(getActiveStore);

    const [verification, setVerification] = useState<StoreVerification | null>(
        null,
    );

    const [verificationFetched, setVerificationFetched] = useState(false);

    const handleSetVerification = (v: StoreVerification) => {
        setVerification(v);
        setVerificationFetched(true);
    };

    const fetchVerification = debounce(
        { delay: 300 },
        buildFetchVerification(handleSetVerification),
    );

    useEffect(() => {
        if (store?._id) {
            fetchVerification(store._id);
        }
    }, [store?._id]);

    // Note: Make sure this memo is updated appropriately when values change
    const value = useMemo(
        () => ({
            verification,
            verificationFetched,
        }),
        // Note: https://react.dev/reference/react/useMemo
        //       useMemo uses Object.is(), which would not correctly
        //       update this value if only `verification` is provided
        //       as the sole dependency.
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            verification?.id,
            verification?.taxId,
            verification?.merchantCategoryCode,
            verification?.phone,
            verificationFetched,
        ],
    );

    return (
        <OnboardingContext.Provider value={value}>
            {children}
        </OnboardingContext.Provider>
    );
};

export default OnboardingProvider;
