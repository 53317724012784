import { Navigate } from "react-router-dom";
import { ReactNode, Suspense } from "react";

import { useRoutePermissions } from "#navigation/sidebar/hooks/use-route-permissions";
import { Routes as AppRoutes } from "#navigation/routes";
import { Spinner } from "src/@/components/ui/spinner";

/**
 * Checks that matching routes are allowed before rendering their content.
 * If a permission violation is detected, redirects to NotAllowed page
 */
export const RouteProtection = ({ children }: { children: ReactNode }) => {
    const { hasMatchingPermissions } = useRoutePermissions();

    if (!hasMatchingPermissions()) {
        return <Navigate to={AppRoutes.NotAllowed} replace />;
    }

    return (
        <Suspense
            fallback={
                <div className="flex h-[50vh] w-full items-center justify-center">
                    <Spinner size="lg" className="h-6 w-6" />
                </div>
            }
        >
            {children}
        </Suspense>
    );
};
