import { useEffect, useState } from "react";
import { toast } from "sonner";
import { SystemColors } from "@snackpass/design-system";
import { useSelector } from "react-redux";
// import { useParams } from "react-router-dom";

import api from "src/api/rest";
import { UserInvite } from "src/core/types";
import { ReactComponent as Logo } from "src/assets/icons/snackpass-logo.svg";
import { AcceptInviteLanding } from "#accept-invite/accept-invite";
import { CompleteProfile } from "#accept-invite/complete-profile";
import { getUser } from "src/redux/selectors";

// TODO: reconnect this to router
const AcceptInvite = ({ nonce }: { nonce: string }) => {
    // const { nonce } = useParams();
    const [invite, setInvite] = useState<UserInvite>();
    const [fetching, setFetching] = useState(true);
    const [showCompleteProfile, setShowCompleteProfile] = useState(false);
    const user = useSelector(getUser);

    useEffect(() => {
        const fetchInvite = async () => {
            if (!nonce) return;

            try {
                const response = await api.adminUserInvite.getInvite(nonce);
                setInvite(response.data.invite);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (e: any) {
                toast.error("Failed to fetch", {
                    description: e.response.data.message,
                });
            }
            setFetching(false);
        };

        void fetchInvite();
    }, [nonce]);

    const proceedToCompleteProfile = () => {
        setShowCompleteProfile(true);
    };

    return (
        <div className="flex h-full w-full flex-col items-center justify-center">
            {showCompleteProfile ? (
                <CompleteProfile invite={invite} />
            ) : (
                <AcceptInviteLanding
                    fetching={fetching}
                    handleAccept={proceedToCompleteProfile}
                    invite={invite}
                    user={user}
                />
            )}
            <div className="absolute bottom-6">
                <Logo fill={SystemColors.v2.salt80.light} />
            </div>
        </div>
    );
};

export const Component = AcceptInvite;
