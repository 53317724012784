import * as React from "react";
import { Loader2 } from "lucide-react";
import { cn } from "src/@/lib/utils";

type SpinnerSize = "sm" | "md" | "lg";

const sizeClasses: Record<SpinnerSize, string> = {
    sm: "h-3 w-3",
    md: "h-4 w-4",
    lg: "h-6 w-6",
};

interface SpinnerProps extends React.HTMLAttributes<HTMLDivElement> {
    size?: SpinnerSize;
}

const Spinner = ({ className, size = "lg", ...props }: SpinnerProps) => (
        <div
            className={cn("flex items-center justify-center", className)}
            {...props}
        >
            <Loader2 className={cn(sizeClasses[size], "animate-spin")} />
        </div>
    );

Spinner.displayName = "Spinner";

export { Spinner };
