import { useSelector } from "react-redux";
import { IProduct, IProductCategory, IStore } from "@snackpass/snackpass-types";
import { useCallback, useEffect } from "react";
import { toast } from "sonner";

import {
    setDashboardFilterTransactionChannelOptions,
    setActiveProductCategory,
    setActiveStore,
    setIsLoading,
    setLegacyProducts,
    getActiveStoreIdSliceId,
} from "src/redux/slices";
import api from "src/api/rest";
import { getUser } from "src/redux/selectors";
import { multiMenuThunks } from "#menu-editor/multi-menus/redux/thunks";
import { sendError, logAndSendError } from "src/utils/errors";
import { useAppDispatch } from "src/redux/hooks";
import { fetchStoreProducts } from "#menu/api";
import { Provider } from "#sales-report/types";

export const useSyncActiveStore = () => {
    const activeStoreId = useSelector(getActiveStoreIdSliceId);
    const dispatch = useAppDispatch();
    const user = useSelector(getUser);

    const getStore = async (storeId: string): Promise<IStore | undefined> => {
        try {
            const res = await api.stores.getOne(storeId);
            return res.data.store;
        } catch (err) {
            toast.error("failed to get store");
        } finally {
            dispatch(setIsLoading(false));
        }
    };

    const getStoreProducts = async (
        storeId: string,
    ): Promise<IProduct[] | undefined> => {
        try {
            return fetchStoreProducts(storeId).then((res) => res.products);
        } catch (err) {
            logAndSendError(err);
            toast.error("failed to get store products");
        }
    };

    const getProviders = async (
        storeId: string,
    ): Promise<Provider[] | undefined> => {
        try {
            return api.reports
                .getSalesChannels({ storeId })
                .then((res) => res.data?.channels);
        } catch (err) {
            logAndSendError(err);
            toast.error("failed to get purchase channels");
        }
    };

    const syncStore = useCallback(async () => {
        // checking for the user prevents a pre-emptive 403
        if (!activeStoreId || !user?._id) return;
        dispatch(setIsLoading(true));
        try {
            const store = await getStore(activeStoreId);
            if (!store) {
                return;
            }
            dispatch(setActiveStore(store));

            const products = await getStoreProducts(activeStoreId);
            if (products) {
                dispatch(setLegacyProducts(products));
            }

            const providers = await getProviders(activeStoreId);
            dispatch(
                setDashboardFilterTransactionChannelOptions(providers ?? []),
            );

            const firstCategory: IProductCategory | undefined =
                store.productCategories[0];
            if (firstCategory) {
                dispatch(setActiveProductCategory(firstCategory.name));
            }
            void dispatch(multiMenuThunks.fetchStoreMultiMenus(activeStoreId));
        } catch (err) {
            sendError(err);
        }
        dispatch(setIsLoading(false));
    }, [activeStoreId, user?._id]);

    useEffect(() => {
        void syncStore();
    }, [syncStore]);
};
