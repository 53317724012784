import {
    CateringStatusType,
    IPurchase,
    PurchaseStatus,
    RefundSource,
} from "@snackpass/snackpass-types";
import { AxiosResponse } from "axios";
import { ErrorWithCause } from "src/utils/errors";

import { client } from "../client";

export const REPORTS_REQUEST_TIMEOUT = 120 * 1000;

export type GetPurchasesForStoreParams = {
    count: number; // required, even with since
    since?: Date;
    before?: Date;
    includeKioskPurchases?: boolean;
    isCatering?: boolean;
    kioskOnly?: boolean;
    transactionSource?: string | null;
};

export type GetRestaurantDashboardPurchasesForStoreParams =
    GetPurchasesForStoreParams & { onlyIncludeRestaurantDashboardFields: true };

type RefundParams = {
    reason: string;
    partialRefund?: number;
    refundedProductIds?: string[];
    refundedItemIds?: string[];
    pin?: string;
    refundSource?: RefundSource;
};

type UpchargeParams = {
    upcharge?: number;
    productAdjustments?: string[];
    pin?: string;
};

export type UpdateStatusParams = {
    status: PurchaseStatus;
    isDelayed?: boolean;
    delayDuration?: number;
    pickupTimeDuration?: number;
    isKioskPurchase?: boolean;
    finishedEarly?: boolean;
};

export class Purchases {
    static async getById(
        purchaseId: string,
        params?: { includeReceiptUrl?: boolean },
    ) {
        return client
            .get(`/purchases/${purchaseId}`, {
                params,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Purchases.getById: GET to /purchases/${purchaseId} failed`,
                    cause,
                );
            });
    }

    static async getForStore(
        storeId: string,
        params: GetPurchasesForStoreParams,
    ): Promise<
        AxiosResponse<{ purchases: IPurchase[]; kioskPurchases?: IPurchase[] }>
    > {
        return client
            .get(`/purchases`, {
                params: { ...params, storeId },
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Purchase.getForStore: GET to /purchases failed`,
                    cause,
                );
            });
    }

    static async getForStoreByUser(
        storeId: string,
        userId: string,
        params: GetPurchasesForStoreParams,
    ): Promise<
        AxiosResponse<{ purchases: IPurchase[]; kioskPurchases?: IPurchase[] }>
    > {
        return client
            .get(`/purchases`, {
                params: { ...params, storeId, userId },
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Purchase.getForStoreByUser: GET to /purchases failed`,
                    cause,
                );
            });
    }

    static async updateCateringStatus(
        purchaseId: string,
        cateringStatus: CateringStatusType,
    ) {
        return client
            .put(`/purchases/${purchaseId}/catering-status`, {
                cateringStatus,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Purchase.updateCateringStatus: PUT to /purchases/${purchaseId}/catering-status failed`,
                    cause,
                );
            });
    }

    static async sendFax(purchaseId: string, queryParams: unknown) {
        return client
            .post(
                `/purchases/${purchaseId}/fax`,
                {},
                {
                    params: queryParams,
                },
            )
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Purchase.sendFax: POST to /purchases/${purchaseId}/fax failed`,
                    cause,
                );
            });
    }

    static async htmlReceipt(purchaseId: string, queryParams: unknown) {
        return client
            .get(`/purchases/${purchaseId}/receipt`, {
                params: queryParams,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Purchase.htmlReceipt: GET to /purchases/${purchaseId}/receipt failed`,
                    cause,
                );
            });
    }

    static async refund(purchaseId: string, body: RefundParams) {
        return client
            .put(`/purchases/${purchaseId}/refund`, body)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Purchase.refund: PUT to /purchases/${purchaseId}/refund failed`,
                    cause,
                );
            });
    }

    static async upcharge(purchaseId: string, body: UpchargeParams) {
        return client
            .put(`/purchases/${purchaseId}/upcharge`, body)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Purchase.upcharge: PUT to /purchases/${purchaseId}/upcharge failed`,
                    cause,
                );
            });
    }

    static async updateStatus(purchaseId: string, params: UpdateStatusParams) {
        return client
            .put(`/purchases/${purchaseId}/status`, params)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Purchase.updateStatus: PUT to /purchases/${purchaseId}/status failed`,
                    cause,
                );
            });
    }

    static async getPurchaseReceipt(
        purchaseId: string,
        token: string,
    ): Promise<string> {
        return client
            .get(`/purchases/${purchaseId}/purchase-receipt`, {
                params: { token },
            })
            .then((res) => res.data.url)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Purchases.getPurchaseReceipt: GET to /purchases/${purchaseId}/purchase-receipt failed`,
                    cause,
                );
            });
    }
}
