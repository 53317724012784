// migrate all reporting types to this file!

// providers

export enum Provider {
    SNACKPASS = "snackpass",
    DOORDASH = "doordash",
    UBEREATS = "uberEats",
    POSTMATES = "uberPostmates",
    DELIVERECT = "deliverect",
    FANTUAN = "fantuan",
    GRUBHUB = "grubhub",
    OTHER = "other",
    DELIVERYCOM = "deliverycom",
    RICEPO = "ricepo",
    EZCATER = "ezcater",
    CHOWNOW = "chownow",
    HUNGRYPANDA = "hungrypanda",
    ALLSET = "allset",
}

interface ProviderConfig {
    friendlyName: string;
    mongoName: string;
}

export const PROVIDER_CONFIG: Record<Provider, ProviderConfig> = {
    [Provider.SNACKPASS]: {
        friendlyName: "Snackpass",
        mongoName: "SNACKPASS",
    },
    [Provider.DOORDASH]: {
        friendlyName: "DoorDash",
        mongoName: "DOORDASH",
    },
    [Provider.UBEREATS]: {
        friendlyName: "Uber Eats",
        mongoName: "UBEREATS",
    },
    [Provider.POSTMATES]: {
        friendlyName: "Postmates",
        mongoName: "POSTMATES",
    },
    [Provider.DELIVERECT]: {
        friendlyName: "Deliverect",
        mongoName: "DELIVERECT",
    },
    [Provider.FANTUAN]: {
        friendlyName: "Fantuan",
        mongoName: "FANTUAN",
    },
    [Provider.GRUBHUB]: {
        friendlyName: "Grubhub",
        mongoName: "GRUBHUB",
    },
    [Provider.OTHER]: {
        friendlyName: "Other",
        mongoName: "OTHER",
    },
    [Provider.DELIVERYCOM]: {
        friendlyName: "Delivery.com",
        mongoName: "DELIVERYCOM",
    },
    [Provider.RICEPO]: {
        friendlyName: "Ricepo",
        mongoName: "RICEPO",
    },
    [Provider.EZCATER]: {
        friendlyName: "ezCater",
        mongoName: "EZCATER",
    },
    [Provider.CHOWNOW]: {
        friendlyName: "ChowNow",
        mongoName: "CHOWNOW",
    },
    [Provider.HUNGRYPANDA]: {
        friendlyName: "Hungry Panda",
        mongoName: "HUNGRYPANDA",
    },
    [Provider.ALLSET]: {
        friendlyName: "allset",
        mongoName: "ALLSET",
    },
};

// payment methods

export enum PaymentMethod {
    creditDebit = "creditDebit",
    cash = "cash",
    unpaid = "unpaid",
    other = "thirdParty",
}

export const PaymentMethodOptions = [
    {
        label: "Card",
        value: PaymentMethod.creditDebit,
    },
    {
        label: "Cash",
        value: PaymentMethod.cash,
    },
    {
        label: "Third Party",
        value: PaymentMethod.other,
    },
    {
        label: "Unpaid",
        value: PaymentMethod.unpaid,
    },
];
// fulfillments

import {
    FulfillmentTypeEnum,
    PurchaseReportTransactionSource,
} from "@snackpass/snackpass-types";
export type Fulfillment = FulfillmentTypeEnum;

export const FULFILLMENT_MAP = {
    [FulfillmentTypeEnum.Pickup]: {
        readable: "Pickup",
        camelCase: "pickup",
    },
    [FulfillmentTypeEnum.Delivery]: {
        readable: "Delivery",
        camelCase: "delivery",
    },
    [FulfillmentTypeEnum.DineIn]: {
        readable: "Dine In",
        camelCase: "dineIn",
    },
} as const;

// Generate FulfillmentOptions from the map
export const FulfillmentOptions = Object.entries(FULFILLMENT_MAP).map(
    ([value, config]) => ({
        label: config.readable,
        value: value as FulfillmentTypeEnum,
    }),
);

// channels

export type Channel = PurchaseReportTransactionSource;
