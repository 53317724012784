import { EmployeePermissions, IUser } from "@snackpass/snackpass-types";
import { ErrorWithCause } from "src/utils/errors";
import {
    AdminFields,
    SnackpassPermissions,
} from "#settings/settings-permissions/types";

import { client } from "../client";

export class Admins {
    static async update(userId: string, update: Partial<IUser>) {
        return client
            .put(`/users/${userId}`, {
                user: { ...update },
                communicationPreferences: [],
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Admins.update: PUT to /users/${userId} failed`,
                    cause,
                );
            });
    }

    static async updateSelf(
        userId: string,
        update: {
            firstName?: string;
            lastName?: string;
            profilePicUrl?: string;
        },
    ) {
        const updates = {
            firstName: update.firstName,
            lastName: update.lastName,
            profilePicUrl: update.profilePicUrl,
        };
        return client
            .put(`/users/${userId}/update-self`, updates)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Admins.updateSelf: PUT to /users/${userId}/update-self failed`,
                    cause,
                );
            });
    }

    static async updateAdmin(
        userId: string,
        updates: { user: AdminFields },
        storeId: string,
        permissionUpdates?: SnackpassPermissions,
        employeePin?: string,
        employeePermissions?: EmployeePermissions,
    ) {
        const { pin, wage, ...userWithoutPin } = updates.user;
        const put = {
            user: {
                ...userWithoutPin,
                wage: wage?.toString() || "0",
            },
            storeId,
            permissionUpdates,
            employeePin,
            employeePermissions,
        };
        console.log("put", put);
        return client
            .put(`/users/${userId}/update-admin-and-password`, put)
            .catch((cause) => {
                console.log("cause", cause);
                throw new ErrorWithCause(
                    `api.Admins.updateWithPassword: PUT to /users/${userId}/update-admin-and-password failed`,
                    cause,
                );
            });
    }

    static async removeAdminFromStore(userId: string, storeId: string) {
        return client
            .patch(`/users/remove-admin`, {
                userId,
                storeId,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Users.removeAdminFromStore: PATCH to /users/remove-admin failed`,
                    cause,
                );
            });
    }

    static async create(
        body: { user: AdminFields },
        storeId: string,
        permissionUpdates?: SnackpassPermissions,
        employeePermissions?: EmployeePermissions,
        employeePin?: string,
    ) {
        return client
            .post(`/users/create-admin`, {
                ...body,
                storeId,
                permissionUpdates,
                employeePermissions,
                employeePin,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Admins.create: POST to /users/create-admin failed`,
                    cause,
                );
            });
    }

    static async sendInvite(
        storeId: string,
        email: string,
        permissions: SnackpassPermissions,
        employeePermissions: EmployeePermissions,
        wage: string,
        role: string,
        identifier?: string,
        pin?: string,
    ) {
        return client
            .post("/admin-invite/create", {
                storeId,
                email: email.toLowerCase(),
                permissions,
                employeePermissions,
                wage,
                jobTitle: role,
                identifier,
                pin,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    "api.Admins.sendInvite: POST to /user-invite/create failed",
                    cause,
                );
            });
    }
}
