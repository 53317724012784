import { useCallback } from "react";
import { ControllerRenderProps, FieldError } from "react-hook-form";
import { match, P } from "ts-pattern";

import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "src/@/components/ui/select";
import {
    CreatePromotion,
    CreatePromotionTrigger,
} from "src/api/graphql/generated/types";
import { Separator } from "src/@/components/ui/separator";

import { CartItemTriggerInput } from "./CartItemTriggerInput";

export type TriggerInputProps = ControllerRenderProps<
    CreatePromotion,
    "trigger"
> & {
    error?: FieldError;
};

export function TriggerInput({
    value = {},
    onChange,
    error,
}: TriggerInputProps) {
    const onChangeTrigger = useCallback(
        (trigger: keyof CreatePromotionTrigger) => {
            if (trigger) onChange({ [trigger]: {} });
            else onChange(undefined);
        },
        [onChange],
    );

    return (
        <div>
            <div className="flex items-center gap-6">
                <Select
                    value={Object.keys(value)[0] ?? ""}
                    onValueChange={onChangeTrigger}
                >
                    <SelectTrigger className="w-60">
                        <SelectValue placeholder="Select Trigger Type" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="">Select Trigger Type</SelectItem>
                        <SelectItem
                            key="cartItemTrigger"
                            value="cartItemTrigger"
                        >
                            Cart Item
                        </SelectItem>
                    </SelectContent>
                </Select>
                {error ? (
                    <span className="text-sm text-critical-light">
                        {error.message}
                    </span>
                ) : null}
            </div>
            <Separator className="my-4" />
            {match(value)
                .with({ cartItemTrigger: P.not(P.nullish) }, () => (
                    <CartItemTriggerInput />
                ))
                .with({}, () => (
                    <p className="w-full text-center text-neutral-500">
                        No trigger selected
                    </p>
                ))
                .otherwise(() => {
                    throw new Error(`Invalid trigger: ${value}`);
                })}
        </div>
    );
}
