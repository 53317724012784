import { useMemo } from "react";
import { match, P } from "ts-pattern";

import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "src/@/components/ui/select";
import { CreatePromotionAdjustmentScope } from "src/api/graphql/generated/types";

enum AdjustmentScopeOption {
    Menu = "Full Menu",
    Products = "Select Products",
    Categories = "Select Categories",
}

export type ScopeInputProps = {
    value: CreatePromotionAdjustmentScope;
    onChange: (value: CreatePromotionAdjustmentScope) => void;
};

export function ScopeInput({ value, onChange }: ScopeInputProps) {
    const scopeOption = useMemo(
        () =>
            match(value)
                .with({ menu: P.boolean }, () => AdjustmentScopeOption.Menu)
                .with(
                    { products: P.array(P.string) },
                    () => AdjustmentScopeOption.Products,
                )
                .with(
                    { categories: P.array(P.string) },
                    () => AdjustmentScopeOption.Categories,
                )
                .otherwise(() => {
                    throw new Error(`Invalid scope option: ${value}`);
                }),
        [value],
    );

    const onChangeScope = (option: AdjustmentScopeOption) =>
        onChange(
            match(option)
                .with(AdjustmentScopeOption.Menu, () => ({
                    menu: true,
                }))
                .with(AdjustmentScopeOption.Products, () => ({
                    products: [],
                }))
                .with(AdjustmentScopeOption.Categories, () => ({
                    categories: [],
                }))
                .exhaustive(),
        );

    return (
        <Select value={scopeOption} onValueChange={onChangeScope}>
            <SelectTrigger className="max-w-60">
                <SelectValue placeholder="Apply to..." />
            </SelectTrigger>
            <SelectContent>
                {Object.entries(AdjustmentScopeOption).map(([key, value]) => (
                    <SelectItem key={key} value={value}>
                        {value}
                    </SelectItem>
                ))}
            </SelectContent>
        </Select>
    );
}
