import { FulfillmentType } from "@snackpass/snackpass-types";
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { match } from "ts-pattern";

import { MultiSelect } from "src/@/components/ui/multi-select";
import { CreatePromotion } from "src/api/graphql/generated/types";

const fulfillmentOptions = Object.values(FulfillmentType).map((type) => ({
    label: match(type)
        .with(FulfillmentType.Pickup, () => "Pick up")
        .with(FulfillmentType.DineIn, () => "Dine in")
        .with(FulfillmentType.Delivery, () => "Delivery")
        .exhaustive(),
    value: type,
}));

export function FulfillmentSelection() {
    const { watch, setValue } = useFormContext<CreatePromotion>();

    const fulfillments = watch("fulfillments");

    const onChangeFulfillments = useCallback(
        (fulfillments: string[]) =>
            setValue("fulfillments", fulfillments as FulfillmentType[]),
        [setValue],
    );

    return (
        <div className="flex items-center justify-between">
            <span>Supported Fulfillments</span>
            <MultiSelect
                selected={fulfillments}
                onChange={onChangeFulfillments}
                options={fulfillmentOptions}
                placeholder="Select Fulfillments"
                searchPlaceholder="Search Fulfillments"
                className="max-w-80"
            />
        </div>
    );
}
