import { PlusIcon } from "@radix-ui/react-icons";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { Routes } from "#navigation/routes";
import { Button } from "src/@/components/ui/button";

export function CreatePromotionButton() {
    const navigate = useNavigate();

    const onClick = useCallback(
        () => navigate(Routes.CreateNewPromotion),
        [navigate],
    );

    return (
        <Button onClick={onClick}>
            <PlusIcon />
            Create
        </Button>
    );
}
