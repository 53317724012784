import { useState } from "react";
import { useRouteError } from "react-router-dom";
import { captureException } from "@sentry/react";
import { Turtle } from "lucide-react";

import { Alert, AlertDescription, AlertTitle } from "src/@/components/ui/alert";

export const ErrorBoundary = () => {
    const error = useRouteError();
    const [sentryId, setStentryId] = useState<string>();

    if (error && !sentryId) {
        console.log(error);
        setStentryId(captureException(error));
    }

    return (
        <div>
            <Alert className="mx-auto mt-8 w-fit">
                <Turtle className="h-4 w-4" />
                <AlertTitle>Something went wrong</AlertTitle>
                <AlertDescription className="mt-2">
                    Please contact support if the issue persists.
                    {sentryId && (
                        <>
                            <br />
                            Error ID: <code>{sentryId}</code>
                        </>
                    )}
                </AlertDescription>
            </Alert>
        </div>
    );
};
