import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

import api from "src/api/rest";
import { getActiveStore } from "src/redux/selectors";
import { logAndSendError } from "src/utils/errors";
import { useDashboardContext } from "./use-dashboard-context";
import {
    CustomerResponse,
    CustomerTotals,
} from "src/api/rest/modules/customers";

interface CustomerData {
    name: string;
    value: number;
    color: string;
    orders: number;
}

interface TransformedCustomer {
    name: string;
    value: number;
    orders: number;
    isRepeatCustomer: boolean;
    isNewCustomer: boolean;
}

const COLORS = [
    "hsl(var(--chart-1))", // blue
    "hsl(var(--chart-2))", // yellow
    "hsl(var(--chart-3))", // purple
    "hsl(var(--chart-4))", // orange
    "hsl(var(--chart-5))", // green
];

export const useQueryCustomers = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [customerData, setCustomerData] = useState<CustomerData[]>([]);
    const [comparisonTotals, setComparisonTotals] = useState<CustomerTotals>({
        app: 0,
        new: 0,
        repeat: 0,
    });

    const store = useSelector(getActiveStore);
    const { currentPeriodStartEnd, comparisonPeriodStartEnd } =
        useDashboardContext();

    const fetchCustomers = useCallback(async () => {
        if (
            !store?._id ||
            !currentPeriodStartEnd?.startDate ||
            !currentPeriodStartEnd?.endDate
        ) {
            return;
        }

        try {
            setIsLoading(true);
            setError(null);

            const response = await api.reports.customers.getCustomers(
                store._id,
                {
                    startDate:
                        currentPeriodStartEnd.startDate.format("YYYY-MM-DD"),
                    endDate: currentPeriodStartEnd.endDate.format("YYYY-MM-DD"),
                    compareToStartDate:
                        comparisonPeriodStartEnd?.startDate.format(
                            "YYYY-MM-DD",
                        ),
                    compareToEndDate:
                        comparisonPeriodStartEnd?.endDate.format("YYYY-MM-DD"),
                    numRows: 20,
                },
            );

            const transformedData: CustomerData[] = response.data.customers
                ?.filter(
                    (customer: CustomerResponse) =>
                        customer.email !== "skippedphone@snackpass.co" &&
                        customer.totalSpent > 0,
                )
                .map(
                    (customer: CustomerResponse): TransformedCustomer => ({
                        name: customer.name || "Anonymous",
                        value: customer.totalSpent,
                        orders: customer.totalOrders,
                        isRepeatCustomer: !!customer.repeatCustomer,
                        isNewCustomer: !!customer.newCustomer,
                    }),
                )
                .sort(
                    (a: TransformedCustomer, b: TransformedCustomer) =>
                        b.value - a.value,
                )
                .slice(0, 18)
                .map((item: TransformedCustomer, index: number) => ({
                    ...item,
                    value: Math.round(item.value),
                    color: COLORS[index % COLORS.length],
                }));

            setCustomerData(transformedData || []);
            setComparisonTotals(response.data.aggregate.previous);
        } catch (err) {
            if (!axios.isCancel(err)) {
                console.error("[useCustomers] Error fetching customers:", err);
                logAndSendError(err);
                setError(err as Error);
            }
        } finally {
            setIsLoading(false);
        }
    }, [
        store,
        currentPeriodStartEnd?.startDate,
        currentPeriodStartEnd?.endDate,
        comparisonPeriodStartEnd?.startDate,
        comparisonPeriodStartEnd?.endDate,
    ]);

    useEffect(() => {
        fetchCustomers();
    }, [fetchCustomers]);

    return {
        customerData,
        isLoading,
        error,
        refetch: fetchCustomers,
        dateRange: currentPeriodStartEnd,
        comparisonTotals,
    };
};
