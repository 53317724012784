import { Page } from "src/@/components/ui/page";
import { PromotionsTable } from "#new-promotions/components";

export function PromotionsScreen() {
    return (
        <Page title="Promotions (Beta)">
            <PromotionsTable />
        </Page>
    );
}
