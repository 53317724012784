import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import moment from "moment";

import api from "src/api/rest";
import { getActiveStore } from "src/redux/selectors";
import { logAndSendError } from "src/utils/errors";

export interface MonthlyCustomerInsights {
    appPurchases: number;
    newCustomers: number;
    repeatCustomers: number;
    uniqueAppCustomers: number;
    uniqueCustomers: number;
}

export interface CustomerInsightsSummaryResponse {
    summary: MonthlyCustomerInsights[];
    success: boolean;
}

// The API response is the actual response data, not wrapped in a data property
type ApiResponse = CustomerInsightsSummaryResponse;

declare module "src/api/rest" {
    interface Reports {
        customers: {
            getCustomersMonthly(params: {
                storeId: string;
                startDate: string;
                endDate: string;
            }): Promise<{ data: ApiResponse }>;
        };
    }
}

export const useQueryCustomersMonthly = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [summary, setSummary] = useState<MonthlyCustomerInsights[] | null>(
        null,
    );

    const store = useSelector(getActiveStore);

    const fetchSummary = useCallback(async () => {
        if (!store?._id) {
            return;
        }

        try {
            setIsLoading(true);
            setError(null);

            // Calculate dates for last 13 months
            const endDate = moment().endOf("month");
            const startDate = moment().subtract(13, "months").startOf("month");

            const { data } = await api.reports.customers.getCustomersMonthly(
                store._id,
                {
                    startDate: startDate.format("YYYY-MM-DD"),
                    endDate: endDate.format("YYYY-MM-DD"),
                },
            );

            if (!data.success) {
                throw new Error("Failed to fetch customer insights summary");
            }

            setSummary(data.summary);
        } catch (err) {
            if (!axios.isCancel(err)) {
                console.error(
                    "[useCustomerInsightsSummary] Error fetching summary:",
                    err,
                );
                logAndSendError(err);
                setError(err as Error);
            }
        } finally {
            setIsLoading(false);
        }
    }, [store]);

    useEffect(() => {
        fetchSummary();
    }, [fetchSummary]);

    return {
        summary,
        isLoading,
        error,
        refetch: fetchSummary,
    };
};
