import { useCallback, useMemo } from "react";

import {
    CreatePromotionMutationVariables,
    GetPromotionsDocument,
    useCreatePromotionMutation,
} from "src/api/graphql/generated/types";

export function useCreatePromotion() {
    const [trigger, { loading, error, reset, data }] =
        useCreatePromotionMutation();

    const createPromotion = useCallback(
        async (variables: CreatePromotionMutationVariables) =>
            trigger({
                variables,
                refetchQueries: [GetPromotionsDocument],
            }).finally(reset),
        [reset, trigger],
    );

    return useMemo(
        () => ({
            createPromotion,
            loading,
            error,
            data,
        }),
        [createPromotion, loading, error, data],
    );
}
