import { Navigate, createBrowserRouter } from "react-router-dom";

import { Routes as AppRoutes } from "#navigation/routes";
import Dashboard from "#dashboard";
import { Layout } from "#app/layout";
import { SnackpassNonIndexRouteObject, StorePermission } from "#navigation/types";
import { WhatsNew } from "#whatsNew";
import Orders from "#orders";
import NoAccessPage from "#utility-pages/no-access-page";
import NotFoundPage from "#utility-pages/not-found-page";
import { SignOut } from "#sign-out";
import { ErrorBoundary } from "#app/ErrorBoundary";
import { PromotionsScreen, CreatePromotionScreen } from "#new-promotions/screens";

/**
 * By tightly coupling application information (see `handle`) with our route definitions, we can
 * take advantage of react-router "data APIs" to access said data in router contexts.
 *
 * Routes in this array should be ordered and nested in the way you want them to appear in breadcrumbs
 * and the navigation sidebar.
 *
 * URLs for nested routes need to match all of their parent URLs. This is not only good practice for
 * sitemapping, but also a requirement in react-router.
 */
const routes: SnackpassNonIndexRouteObject[] = [
  {
    element: <Layout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: AppRoutes.Onboarding,
        lazy: async () => import("#onboarding"),
        handle: { permission: StorePermission.HasDashboard },
      },
      {
        path: AppRoutes.WhatsNew,
        Component: WhatsNew,
        handle: { permission: StorePermission.HasDashboard },
      },

      // STORE
      {
        handle: { title: "Store" },
        children: [
          {
            index: true,
            element: <Dashboard />,
            handle: { title: "Dashboard", permission: StorePermission.HasDashboard },
          },
          {
            handle: { title: "Orders", permission: StorePermission.HasOrders },
            children: [
              {
                path: AppRoutes.BetaOrders,
                index: true,
                Component: Orders,
              },
              {
                path: AppRoutes.OrdersDetails,
                lazy: async () => import("#order-details"),
              },
              // TODO: this is a duplicate route - do we need it?
              {
                path: AppRoutes.PurchaseDetails,
                lazy: async () => import("#order-details"),
              },
            ],
          },
          {
            handle: { title: "Menu", permission: StorePermission.HasMenuEditing },
            children: [
              {
                path: AppRoutes.MenuEditor,
                lazy: async () => import("#menu-editor/mobile-friendly/items"),
                handle: { title: "Items" },
              },
              {
                path: AppRoutes.MenuManager,
                handle: { title: "Menus" },
                children: [
                  {
                    index: true,
                    lazy: async () => import("#menu-manager/screens/MenuTableScreen"),
                  },
                  {
                    path: AppRoutes.NEW_MENU,
                    lazy: async () => import("#menu-manager/screens/NewMenuScreen"),
                    handle: { title: "New Menu" },
                  },
                  {
                    path: AppRoutes.EDIT_MENU,
                    lazy: async () => import("#menu-manager/screens/EditMenuScreen"),
                    handle: { title: "Edit Menu" },
                  },
                ],
              },
              {
                path: AppRoutes.MenuManagerBeta,
                lazy: async () => import("#menu-manager/screens/MenuManager"),
                // TODO: rm internal gate!
                handle: { title: "Menu Manager", internal: true },
                children: [
                  {
                    index: true,
                    lazy: async () => import("#menu-manager/screens/MenuManager/tabs/Menus"),
                    handle: { title: "Menus", tab: "menus" },
                  },
                  {
                    path: AppRoutes.MenuManagerBetaCategories,
                    lazy: async () => import("#menu-manager/screens/MenuManager/tabs/Categories"),
                    handle: { title: "Categories", tab: "categories" },
                  },
                  {
                    path: AppRoutes.MenuManagerBetaItems,
                    handle: { title: "Items", tab: "items" },
                  },
                ],
              },
              // TODO: kill weird secondary set of breadcrumbs within multi menus
              {
                path: AppRoutes.MultiMenus,
                handle: { title: "Third Party" },
                children: [
                  {
                    index: true,
                    lazy: async () => import("#menu-editor/multi-menus/screens/menus-desktop"),
                  },
                  {
                    path: AppRoutes.MultiMenusCreate,
                    lazy: async () =>
                      import("#menu-editor/multi-menus/screens/multi-menu-settings/multi-menu-creation"),
                    handle: { title: "Create Menu" },
                  },
                  {
                    path: AppRoutes.MultiMenusOutline,
                    handle: { title: "Menu" },
                    children: [
                      {
                        index: true,
                        lazy: async () => import("#menu-editor/multi-menus/screens/menu-overview-desktop"),
                      },
                      {
                        path: AppRoutes.MultiMenusSettings,
                        lazy: async () =>
                          import("#menu-editor/multi-menus/screens/multi-menu-settings/multi-menu-edition"),
                        handle: { title: "Menu Settings" },
                      },
                      {
                        path: AppRoutes.MultiMenusEdit,
                        lazy: async () => import("#menu-editor/multi-menus/screens/select-items"),
                        handle: { title: "Edit Menu" },
                      },
                    ],
                  },
                ],
              },
              {
                path: AppRoutes.MenuInventoryManager,
                lazy: async () => import("#inventory"),
                handle: { title: "Ingredients" },
              },
            ],
          },
        ],
      },

      // MARKETING
      {
        handle: { title: "Marketing" },
        children: [
          {
            path: AppRoutes.GuestbookConversations,
            lazy: async () => import("#conversations"),
            handle: { title: "Conversations" },
          },
          {
            path: AppRoutes.GuestbookCustomers,
            handle: { title: "Guestbook" },
            children: [
              {
                index: true,
                lazy: async () => import("#guestbook"),
                handle: { title: "Customers" },
              },
              {
                path: AppRoutes.GuestbookAuditLog,
                lazy: async () => import("#audit-log"),
                handle: { title: "Audit Log" },
              },
            ],
          },
          {
            path: AppRoutes.Promotion,
            handle: { title: "Promotions", permission: StorePermission.HasPromos },
            children: [
              {
                index: true,
                lazy: async () => import("#promotion"),
              },
              // TODO: update routes for create/edit to be resource based
              {
                path: AppRoutes.CreatePromotionGeneric,
                lazy: async () => import("#promotion/forms/GenericPromotionFormSwitch"),
                handle: { title: "Create" },
              },
              {
                path: AppRoutes.CreatePromotionReward,
                lazy: async () => import("#promotion/forms/RewardPromotionForm"),
                handle: { title: "Create" },
              },
              {
                path: AppRoutes.CreatePromotionPromoCode,
                lazy: async () => import("#promotion/forms/PromoCodePromotionForm"),
                handle: { title: "Create" },
              },
              {
                path: AppRoutes.CreatePromotionGiftCard,
                lazy: async () => import("#promotion/forms/GiftCardPromotionForm"),
                handle: { title: "Create" },
              },
              // TODO: can we rm these old specific routes?
              {
                path: AppRoutes.CreatePromotionHappyHour,
                lazy: async () => import("#promotion/forms/happy-hour"),
              },
              {
                path: AppRoutes.CreatePromotionBogo,
                lazy: async () => import("#promotion/forms/bogo"),
              },
              {
                path: AppRoutes.CreatePromotionDealDrop,
                lazy: async () => import("#promotion/forms/deal-drop"),
              },
              {
                path: AppRoutes.CreatePromotionStudents,
                lazy: async () => import("#promotion/forms/audience-specific"),
              },
              {
                path: AppRoutes.CreatePromotionFirstTime,
                lazy: async () => import("#promotion/forms/audience-specific"),
              },
            ],
          },
          {
            path: AppRoutes.SettingsGiftCard,
            lazy: async () => import("#settings/settings-gift-card"),
            handle: { title: "Gift Cards", permission: StorePermission.HasPromos },
          },
          {
            path: AppRoutes.GuestbookCampaignsSMS,
            handle: { title: "SMS Campaigns", permission: StorePermission.HasGuestbook },
            children: [
              {
                index: true,
                lazy: async () => import("#sms-campaigns/SMSCampaignsPage"),
              },
              {
                path: AppRoutes.GuestbookCampaignsSMSDetails,
                lazy: async () => import("#sms-campaigns"),
              },
            ],
          },
          {
            path: AppRoutes.GuestbookCampaignsPush,
            handle: { title: "Push Campaigns", permission: StorePermission.HasGuestbook },
            children: [
              {
                index: true,
                lazy: async () => import("#push-campaigns"),
              },
              {
                path: AppRoutes.CreatePushCampaign,
                lazy: async () => import("#push-campaigns/pages/create"),
                handle: { internal: true, title: "Create Campaign" },
              },
            ],
          },
        ],
      },

      // DATA
      {
        handle: { title: "Data" },
        children: [
          {
            handle: { title: "Sales", permission: StorePermission.HasSalesReports },
            children: [
              {
                path: AppRoutes.FinancialReportsEssentials,
                lazy: async () => import("#sales-report"),
                handle: { title: "Reports" },
              },
              {
                path: AppRoutes.MinuteToMinute,
                lazy: async () => import("#minute-to-minute"),
                handle: { title: "Hourly" },
              },
              {
                path: AppRoutes.ReportsSalesSummary,
                lazy: async () => import("#reports-sales-comparison"),
                handle: { title: "Comparisons" },
              },
              {
                path: AppRoutes.FinancialReportsPayoutHistory,
                lazy: async () => import("#financial-reports/screens/payout-history/payout-history"),
                handle: { title: "Payouts", permission: StorePermission.HasFinancialReports },
              },
            ],
          },
          {
            handle: { title: "Labor", permission: StorePermission.HasReportsLabor },
            children: [
              {
                path: AppRoutes.EmployeeTimeCards,
                lazy: async () => import("#employees/employee-time-cards"),
                handle: { title: "Time Cards", permission: StorePermission.HasEmployees },
              },
              {
                path: AppRoutes.Tipping,
                lazy: async () => import("#tip-reporting"),
                handle: { title: "Tips" },
              },
            ],
          },
          {
            handle: { title: "More Reports" },
            children: [
              {
                path: AppRoutes.ReportsCustomerDirectoryInsights,
                lazy: async () => import("#reports-customers"),
                handle: { title: "Customers", permission: StorePermission.HasReportsCustomers },
              },
              {
                handle: { title: "Menu", permission: StorePermission.HasReportsMenu },
                children: [
                  {
                    path: AppRoutes.ReportsMenuItemInsights,
                    lazy: async () => import("#reports/menu-item-insights"),
                    handle: { title: "Items" },
                  },
                  {
                    path: AppRoutes.ReportsMenuCategoryInsights,
                    lazy: async () => import("#reports/menu-category-insights"),
                    handle: { title: "Categories" },
                  },
                ],
              },
              {
                path: AppRoutes.ReportsSalesChannels,
                lazy: async () => import("#reports/sales-channels"),
                handle: { title: "Third Parties", permission: StorePermission.HasSalesReports },
              },
              {
                path: AppRoutes.ReportsPromotions,
                lazy: async () => import("#reports/promotions"),
                handle: { title: "Promotions", permission: StorePermission.HasReportsPromotions },
              },
              {
                path: AppRoutes.ReportsGiftCardPurchases,
                handle: { title: "Gift Cards", permission: StorePermission.HasReportsGiftCards },
                children: [
                  {
                    index: true,
                    lazy: async () => import("#reports/gift-card-purchases"),
                    handle: { title: "Overview" },
                  },
                  {
                    path: AppRoutes.ReportsGiftCardBalances,
                    lazy: async () => import("#reports/gift-card-balances"),
                    handle: { title: "Balances" },
                  },
                  {
                    path: AppRoutes.ReportsGiftCardTransactions,
                    lazy: async () => import("#reports/gift-card-transactions"),
                    handle: { title: "Transactions" },
                  },
                  {
                    path: AppRoutes.ReportsGiftCardLiabilities,
                    lazy: async () => import("#reports/gift-card-liabilities"),
                    handle: { title: "Liabilities" },
                  },
                ],
              },
              {
                path: AppRoutes.FinancialReportsCash,
                lazy: async () => import("#financial-reports/screens/cash-report"),
                handle: { title: "Cash", permission: StorePermission.HasFinancialReports },
              },
              {
                handle: { title: "Locations", permission: StorePermission.HasReportsLocations },
                children: [
                  {
                    path: AppRoutes.ReportsLocationSales,
                    lazy: async () => import("#reports/location-sales"),
                    handle: { title: "Sales" },
                  },
                  {
                    path: AppRoutes.ReportsLocationMenuCategories,
                    lazy: async () => import("#reports/location-menu-categories"),
                    handle: { title: "Categories" },
                  },
                  {
                    path: AppRoutes.ReportsLocationMenuItems,
                    lazy: async () => import("#reports/location-menu-items"),
                    handle: { title: "Items" },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        handle: { title: "Tools", permission: StorePermission.HasDashboard },
        children: [
          // TODO: online ordering
          {
            path: AppRoutes.SettingsQrCode,
            lazy: async () => import("#qr-code"),
            handle: { title: "QR Codes" },
          },
          // TODO: custom app
          // TODO: website beta
          {
            path: AppRoutes.SettingsCatering,
            lazy: async () => import("#settings-catering"),
            handle: { title: "Catering" },
          },
          // TODO: Buy Hardware
          {
            path: AppRoutes.Invoices,
            lazy: async () => import("#invoices"),
            handle: { title: "Invoices" },
          },
          {
            path: AppRoutes.SettingsIntegrations,
            lazy: async () => import("#settings-integrations"),
            handle: { title: "Integrations", permission: StorePermission.HasSettings },
          },
        ],
      },
      // SETTINGS
      {
        handle: { title: "Settings", permission: StorePermission.HasSettings },
        children: [
          {
            path: AppRoutes.SettingsBusinessInfo,
            lazy: async () => import("#settings/settings-business-info"),
            handle: { title: "Store" },
          },
          {
            handle: { title: "Team", permission: StorePermission.HasEmployees },
            children: [
              {
                path: AppRoutes.SettingsPermissions,
                lazy: async () => import("#settings/settings-permissions/admins-table"),
                handle: { title: "Users" },
              },
              {
                path: AppRoutes.SettingsEmployees,
                lazy: async () => import("#time-and-attendance/settings/AttendanceSettings"),
                handle: { title: "Clock In and Out" },
              },
            ],
          },
          {
            path: AppRoutes.Devices,
            lazy: async () => import("#devices"),
            handle: { title: "Devices", permission: StorePermission.HasDevices },
          },
          {
            handle: { title: "Front of House" },
            children: [
              {
                path: AppRoutes.SettingsBrandAndAppearance,
                lazy: async () => import("#settings/settings-brand-and-appearance"),
                handle: { title: "Brand and Appearance" },
              },
              {
                path: AppRoutes.SettingsTipping,
                lazy: async () => import("#settings-tipping"),
                handle: { title: "Tipping" },
              },
              {
                path: AppRoutes.SettingsAllOrders,
                lazy: async () => import("#settings/settings-all-orders"),
                handle: { title: "All Orders" },
              },
              {
                path: AppRoutes.SettingsOnlineOrders,
                lazy: async () => import("#settings/settings-online-orders"),
                handle: { title: "Online Orders" },
              },
              {
                path: AppRoutes.SettingsPlatforms,
                lazy: async () => import("#settings/settings-platforms"),
                handle: { title: "Kiosk and Register" },
              },
              {
                path: AppRoutes.NewPromotion,
                handle: { title: "Promotions", permission: StorePermission.HasPromos },
                children: [
                  {
                    index: true,
                    Component: PromotionsScreen,
                  },
                  {
                    path: AppRoutes.CreateNewPromotion,
                    handle: { title: "Create Promotion" },
                    Component: CreatePromotionScreen,
                  },
                ],
              },
            ],
          },
          {
            handle: { title: "Back of House" },
            children: [
              {
                path: AppRoutes.SettingsBackOfHouse,
                lazy: async () => import("#settings/settings-back-of-house"),
                handle: { title: "Order Flow" },
              },
              {
                path: AppRoutes.PrepStations,
                lazy: async () => import("#prep-stations/prep-stations"),
                handle: { title: "Prep Stations", permission: StorePermission.HasDevices },
              },
              {
                path: AppRoutes.SettingsStoreTablesLayout,
                lazy: async () => import("#table-editor/components/TablesEditor"),
                handle: { title: "Table Editor" },
              },
            ],
          },
          {
            // TODO: nit - standardize "&" or "and"
            handle: { title: "Account & Tax", permission: StorePermission.HasPayouts },
            children: [
              {
                path: AppRoutes.Payouts,
                lazy: async () => import("#payouts"),
                handle: { title: "Bank Account" },
              },
              {
                path: AppRoutes.PayoutsSchedule,
                lazy: async () => import("#payouts/components/PolicyManagement"),
                handle: { title: "Payout Schedule" },
              },
              {
                path: AppRoutes.SettingsTax,
                lazy: async () => import("#settings/settings-tax"),
                handle: { title: "Tax Rates" },
              },
              {
                path: AppRoutes.TaxForms,
                lazy: async () => import("#tax-forms"),
                handle: { title: "Tax Forms" },
              },
              {
                path: AppRoutes.Billing,
                lazy: async () => import("#billing"),
                handle: { title: "Billing" },
              },
            ],
          },
          {
            handle: { title: "Other" },
            children: [
              {
                path: AppRoutes.SettingsProducts,
                lazy: async () => import("#settings/settings-products"),
                handle: { title: "Products" },
              },
              {
                path: AppRoutes.SettingsLegal,
                lazy: async () => import("#settings/settings-legal"),
                handle: { title: "Legal" },
              },
              {
                handle: { title: "Snackpass", internal: true },
                children: [
                  {
                    path: AppRoutes.InternalSettings,
                    lazy: async () => import("#settings/settings-internal"),
                    handle: { title: "General", internal: true },
                  },
                  {
                    path: AppRoutes.InternalFees,
                    lazy: async () => import("#settings/settings-fees"),
                    handle: { title: "Fee Settings", internal: true },
                  },
                  {
                    path: AppRoutes.InternalSeniorSupport,
                    lazy: async () => import("#settings/settings-senior-support"),
                    handle: { title: "Senior Support", internal: true },
                  },
                  {
                    path: AppRoutes.InternalDevices,
                    lazy: async () => import("#settings/settings-internal-devices"),
                    handle: { title: "Search Devices", internal: true },
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        path: AppRoutes.SettingsOrders,
        lazy: async () => import("#settings/settings-orders"),
        handle: { title: "Orders (Deprecated)", permission: StorePermission.HasSettings },
      },

      // ACCOUNT PAGES
      // TODO: update routing to support specific non-authed pages
      // {
      //   path: AppRoutes.NewUserAcceptInvite,
      //   lazy: async () => import("#accept-invite"),
      //   handle: { title: "Accept Invite" },
      // },
      {
        path: AppRoutes.SettingsAccount,
        lazy: async () => import("#settings/components/account-details"),
        handle: { title: "Account" },
      },
      {
        path: AppRoutes.MyAccountTeams,
        lazy: async () => import("#my-teams"),
        handle: { title: "Teams" },
      },

      // UTILITY PAGES
      { path: AppRoutes.NotAllowed, Component: NoAccessPage },
      { path: AppRoutes.NotFound, Component: NotFoundPage },
      { path: AppRoutes.Logout, Component: SignOut },

      // NOT FOUND
      {
        path: "*",
        element: <Navigate to={AppRoutes.NotFound} replace />,
      },
    ],
  },
];

export const router = createBrowserRouter(routes);
