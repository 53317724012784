import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";

import { cn } from "src/@/lib/utils";
import { Button } from "src/@/components/ui/button";
import { Calendar } from "src/@/components/ui/calendar";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "src/@/components/ui/popover";

interface DatePickerProps {
    date?: Date;
    onDateChange: (date: Date | undefined) => void;
    disabled?: (date: Date) => boolean;
    className?: string;
}

export function DatePicker({
    date,
    onDateChange,
    disabled,
    className,
}: DatePickerProps) {
    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button
                    variant="outline"
                    className={cn(
                        "justify-start text-left font-normal",
                        !date && "text-muted-foreground",
                        className,
                    )}
                    size="sm"
                    icon={CalendarIcon}
                >
                    {date ? format(date, "PPP") : <span>Pick a date</span>}
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
                <Calendar
                    mode="single"
                    selected={date}
                    onSelect={onDateChange}
                    disabled={disabled}
                    initialFocus
                />
            </PopoverContent>
        </Popover>
    );
}
