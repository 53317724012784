import { sidebarSections } from "#navigation/sidebar/sidebar-hierarchy";
import { Routes } from "#navigation/routes";
import { RouteItems } from "#navigation/route-data";

import type { RouteItem } from "../types";

export function isUrlActive(currentPath: string, itemPath: string): boolean {
    if (itemPath === Routes.Index) {
        return currentPath === Routes.Index;
    }
    if (!itemPath) return false;

    // Remove trailing slashes for consistent comparison
    const normalizedCurrentPath = currentPath.replace(/\/+$/, "");
    const normalizedItemPath = itemPath.replace(/\/+$/, "");

    // Special case for root path
    if (normalizedItemPath === "/") {
        return normalizedCurrentPath === "/";
    }

    // Check if paths match exactly or if current path starts with item path
    // and the next character is a forward slash (to prevent partial matches)
    return (
        normalizedCurrentPath === normalizedItemPath ||
        (normalizedCurrentPath.startsWith(normalizedItemPath) &&
            normalizedCurrentPath[normalizedItemPath.length] === "/")
    );
}

export function hasActiveNestedRoute(
    items: RouteItem[] | undefined,
    pathname: string,
): boolean {
    if (!items) return false;

    return items.some((item) => {
        // Check if current item's URL is active
        const isCurrentItemActive = isUrlActive(pathname, item.url || "");

        // Recursively check nested items
        const areNestedItemsActive = item.items
            ? hasActiveNestedRoute(item.items, pathname)
            : false;

        return isCurrentItemActive || areNestedItemsActive;
    });
}

function getAllSidebarTopLevelItems(): RouteItem[] {
    return sidebarSections.flatMap((section) => section.items);
}
// Static array of all sidebar items for performance optimization
export const ALL_SIDEBAR_TOP_LEVEL_ITEMS = getAllSidebarTopLevelItems();

// TODO: either update this to read directly from routes definition or delete it
// Static map of routes to breadcrumbs for performance optimization
export const ROUTE_TO_BREADCRUMB_MAP = (() => {
    const map = new Map<Routes, RouteItem[]>();

    function buildBreadcrumbMap(
        items: RouteItem[],
        currentBreadcrumb: RouteItem[] = [],
    ) {
        for (const item of items) {
            const breadcrumb = [...currentBreadcrumb, item];

            if (item.url) {
                map.set(item.url, breadcrumb);

                // Handle nonSidebarSubpages
                if (item.nonSidebarSubpages) {
                    for (const subpage of item.nonSidebarSubpages) {
                        const subpageItem = RouteItems[subpage];
                        if (subpageItem) {
                            map.set(subpage, [...breadcrumb, subpageItem]);
                        }
                    }
                }
            }

            if (item.items) {
                buildBreadcrumbMap(item.items, breadcrumb);
            }
        }
    }

    buildBreadcrumbMap(ALL_SIDEBAR_TOP_LEVEL_ITEMS);
    return map;
})();

export function getBreadcrumbReadable(route: Routes): string {
    return (
        ROUTE_TO_BREADCRUMB_MAP.get(route)
            ?.map((item) => item.title)
            .join("/") || ""
    );
}
