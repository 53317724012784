import {
    IHoursSchema,
    AddonOverrideFields,
    Menu as MultiMenu,
    ProductOverrideFields,
} from "@snackpass/snackpass-types";
import { client } from "src/api/rest/client";
import { ErrorWithCause } from "src/utils/errors";

export class MultiMenusApi {
    static inputMapper(menu: MultiMenu): MultiMenu {
        return {
            ...menu,
            menuOverrides: menu.menuOverrides && {
                products: menu.menuOverrides.products ?? {},
                addonGroups: menu.menuOverrides.addonGroups ?? {},
                addons: menu.menuOverrides.addons ?? {},
            },
        };
    }
    static async createMultiMenu(
        storeId: string,
        data: Partial<MultiMenu>,
    ): Promise<MultiMenu> {
        return client
            .post<{ menu: MultiMenu }>(`/stores/${storeId}/menus`, data)
            .then(({ data: { menu } }) => MultiMenusApi.inputMapper(menu))
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.MultiMenus.createMultiMenu: POST to /stores/${storeId}/menus failed`,
                    cause,
                );
            });
    }
    static async getStoreMultiMenus(storeId: string): Promise<MultiMenu[]> {
        return client
            .get<{ menus: MultiMenu[] }>(`/stores/${storeId}/menus`)
            .then(({ data: { menus } }) => menus.map(MultiMenusApi.inputMapper))
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.MultiMenus.getStoreMultiMenus: GET to /stores/${storeId}/menus failed`,
                    cause,
                );
            });
    }
    static async updateMultiMenu(
        storeId: string,
        menuId: string,
        partialMultiMenu: Partial<MultiMenu>,
    ): Promise<MultiMenu> {
        return client
            .patch<{ menu: MultiMenu }>(
                `/stores/${storeId}/menus/${menuId}`,
                partialMultiMenu,
            )
            .then(({ data: { menu } }) => MultiMenusApi.inputMapper(menu))
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.MultiMenus.updateMultiMenus: PATCH to /stores/${storeId}/menus/${menuId} failed`,
                    cause,
                );
            });
    }
    static async updateMultiMenuOverrides(
        storeId: string,
        menuId: string,
        params: {
            products?: Record<string, ProductOverrideFields | null>;
            addons?: Record<string, AddonOverrideFields | null>;
        },
    ): Promise<MultiMenu> {
        return client
            .patch<{ menu: MultiMenu }>(
                `/stores/${storeId}/menus/${menuId}/overrides`,
                params,
            )
            .then(({ data: { menu } }) => MultiMenusApi.inputMapper(menu))
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.MultiMenus.updateMultiMenuOverrides: PATCH to /stores/${storeId}/menus/${menuId}/overrides failed`,
                    cause,
                );
            });
    }
    static async deleteMultiMenu(
        storeId: string,
        menuId: string,
    ): Promise<string> {
        return client
            .delete<MultiMenu>(`/stores/${storeId}/menus/${menuId}`)
            .then(() => menuId)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.MultiMenus.deleteMultiMenu: DELETE to /stores/${storeId}/menus/${menuId} failed`,
                    cause,
                );
            });
    }
    static async checkConflicts(
        storeId: string,
        data: Omit<
            MultiMenu,
            | "id"
            | "name"
            | "enabled"
            | "storeId"
            | "categories"
            | "priceAdjustment"
            | "menuOverrides"
        > & { id?: string },
    ): Promise<{ menu: MultiMenu; conflictHours: IHoursSchema }[]> {
        return client
            .get<{
                conflicts: { menu: MultiMenu; conflictHours: IHoursSchema }[];
            }>(`/stores/${storeId}/menus/conflicts`, { params: data })
            .then(({ data: { conflicts } }) => conflicts)
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.MultiMenus.checkConflicts: GET to /stores/${storeId}/menus/conflicts failed`,
                    cause,
                );
            });
    }
    static async getMenu(storeId: string, menuId: string): Promise<MultiMenu> {
        return client
            .get<{ menu: MultiMenu }>(`/stores/${storeId}/menus/${menuId}`)
            .then(({ data }) => MultiMenusApi.inputMapper(data.menu))
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.MultiMenus.getMenu: GET to /stores/${storeId}/menus/${menuId} failed`,
                    cause,
                );
            });
    }
}
