import { combineReducers } from "redux";
import { reducer as modal } from "redux-modal";

import {
    campaignSlice,
    conversationInputReducer,
    messageInputReducer,
} from "#legacy-guestbook/redux";
import { multiMenusReducer } from "#menu-editor/multi-menus/redux/reducer";

import {
    activeMenuSlice,
    activeProductCategorySlice,
    activePurchaseSlice,
    activeStoreIdSlice,
    activeStoreNumUnreadsSlice,
    activeStoreSlice,
    authSlice,
    dashboardFilterSlice,
    inventorySlice,
    loadingSlice,
    legacyProductsSlice,
    userSlice,
    mfaPrecheckSlice,
} from "./slices";

export const rootReducer = combineReducers({
    [activeMenuSlice.name]: activeMenuSlice.reducer,
    [activeProductCategorySlice.name]: activeProductCategorySlice.reducer,
    [activePurchaseSlice.name]: activePurchaseSlice.reducer,
    [activeStoreSlice.name]: activeStoreSlice.reducer,
    [activeStoreIdSlice.name]: activeStoreIdSlice.reducer,
    [activeStoreNumUnreadsSlice.name]: activeStoreNumUnreadsSlice.reducer,
    [authSlice.name]: authSlice.reducer,
    [campaignSlice.name]: campaignSlice.reducer,
    [dashboardFilterSlice.name]: dashboardFilterSlice.reducer,
    [inventorySlice.name]: inventorySlice.reducer,
    [loadingSlice.name]: loadingSlice.reducer,
    [legacyProductsSlice.name]: legacyProductsSlice.reducer,
    [userSlice.name]: userSlice.reducer,
    modal,
    multiMenus: multiMenusReducer,
    messageInput: messageInputReducer,
    conversationInput: conversationInputReducer,
    [mfaPrecheckSlice.name]: mfaPrecheckSlice.reducer,
});
