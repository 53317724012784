import { useFormContext } from "react-hook-form";
import { useCallback } from "react";

import { useProducts } from "#menu/hooks";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "src/@/components/ui/select";
import { CreatePromotion } from "src/api/graphql/generated/types";
import { invariant } from "src/lib/invariant";

import { ConditionScopeInput } from "./ConditionScopeInput";
import { ItemsCountInput } from "./ItemsCountInput";

export type ItemCountConditionInputProps = {
    index: number;
};

export function ItemCountConditionInput({
    index,
}: ItemCountConditionInputProps) {
    const products = useProducts();
    const { watch, setValue } = useFormContext<CreatePromotion>();

    const condition = watch(`conditions.${index}.itemCountCondition`);

    invariant(
        condition,
        "ItemCountConditionInput should be used with a itemCountCondition",
    );

    const onChangeProduct = useCallback(
        (productId: string) =>
            setValue(
                `conditions.${index}.itemCountCondition.productId`,
                productId,
            ),
        [index, setValue],
    );

    const onChangeItemCount = useCallback(
        (count: number) =>
            setValue(`conditions.${index}.itemCountCondition.count`, count),
        [index, setValue],
    );

    return (
        <div className="space-y-2">
            <ConditionScopeInput condition="itemCountCondition" index={index} />
            <div className="flex items-center justify-between">
                <span>Product</span>
                <Select
                    value={condition.productId}
                    onValueChange={onChangeProduct}
                >
                    <SelectTrigger className="w-40">
                        <SelectValue placeholder="Select Discount" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="">Select Product</SelectItem>
                        {products.map(({ _id, name }) => (
                            <SelectItem key={_id} value={_id}>
                                {name}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
            </div>
            <div className="flex items-center justify-between">
                <span>Limit</span>
                <ItemsCountInput
                    value={condition.count}
                    onChange={onChangeItemCount}
                />
            </div>
        </div>
    );
}
