export enum Routes {
    // Quick Actions
    Onboarding = "/onboarding",
    Index = "/",
    WhatsNew = "/whats-new",
    Search = "#search",
    Support = "#support",

    // Store/Platform
    BetaOrders = "/orders",
    OrdersDetails = "/orders/:id",
    PurchaseDetails = "/purchases/:id",

    // Menu
    Menu = "#menu",
    MenuEditor = "/menu-editor",
    MenuInventoryManager = "/menu-inventory-manager",

    MenuManager = "/menu-manager",
    NEW_MENU = "/menu-manager/new",
    EDIT_MENU = "/menu-manager/:id",

    // Menu Manager
    MenuManagerBeta = "/menu-manager-beta/",
    MenuManagerBetaCategories = "/menu-manager-beta/categories",
    MenuManagerBetaItems = "/menu-manager-beta/items",
    MenuManagerBetaModifierGroups = "/menu-manager-beta/modifier-groups",

    // Multi-Menu ie Third Party Menu
    MultiMenus = "/multi-menus",
    MultiMenusCreate = "/multi-menus/create",
    MultiMenusOutline = "/multi-menus/:id",
    MultiMenusEdit = "/multi-menus/:id/edit",
    MultiMenusSettings = "/multi-menus/:id/settings",
    // TODO: these routes aren't implemented - do we need them?
    MultiMenusProductOverridesEdit = "/multi-menus/:id/overrides/products/:productId",
    MultiMenusAddonOverridesEdit = "/multi-menus/:id/overrides/products/:productId/addons/:addonId",

    // Growth
    // -- Guestbook
    GuestbookConversations = "/conversations",
    GuestbookCustomers = "/guestbook",
    GuestbookAuditLog = "/guestbook/audit-log",

    // -- Marketing
    Marketing = "#marketing",
    GuestbookCampaignsSMS = "/campaigns/sms",
    GuestbookCampaignsSMSDetails = "/campaigns/sms/:campaignId",
    GuestbookCampaignsPush = "/campaigns/push",
    GuestbookCampaignsPushDetails = "/campaigns/push/:campaignId",
    CreatePushCampaign = "/campaigns/push/create",

    // -- Promotions
    Promotion = "/promotion",
    CreatePromotionGeneric = "/promotion/generic",
    CreatePromotionReward = "/promotion/reward",
    CreatePromotionHappyHour = "/promotion/happy-hour",
    CreatePromotionBogo = "/promotion/bogo",
    CreatePromotionAudience = "/promotion/audience",
    CreatePromotionStudents = "/promotion/audience/students",
    CreatePromotionFirstTime = "/promotion/audience/first-time",
    CreatePromotionDealDrop = "/promotion/deal-drop",
    CreatePromotionPromoCode = "/promotion/promo-code",
    CreatePromotionGiftCard = "/promotion/gift-card",
    SettingsGiftCard = "/settings-gift-card",

    // -- New Promotions
    NewPromotion = "/settings/promotion-beta",
    CreateNewPromotion = "/settings/promotion-beta/create",

    // -- Tools
    BuyHardware = "/buy-hardware",
    Invoices = "/invoices",
    SettingsIntegrations = "/settings-integrations",
    DigitalPresenceOnlineOrdering = "/online-ordering",
    SettingsQrCode = "/settings-qr-codes",
    DigitalPresenceApp = "/app",
    DigitalPresenceWebsite = "/website",
    SettingsCatering = "/settings-catering",

    // Data/Reports
    // -- Financial Reports
    FinancialReports = "#financial-reports",
    FinancialReportsPayoutHistory = "/reports/payouts",
    FinancialReportsCash = "/financial-reports-cash",
    FinancialReportsEssentials = "/reports/sales",

    // -- ReportsuseSalesReportContext
    SalesReports = "#sales-reports",
    Reports = "#reports",
    ReportsSales = "#sales",
    ReportsSalesSummary = "/reports/sales-comparisons",
    ReportsSalesChannels = "/third-parties",
    ReportsMenu = "#reports-menu",
    ReportsMenuItemInsights = "/item-insights",
    ReportsMenuCategoryInsights = "/category-insights",
    ReportsCustomerDirectoryInsights = "/reports/customers",
    ReportsPromotions = "/promo-reports",
    ReportsGiftCard = "#reports/gift-card",
    ReportsGiftCardPurchases = "/gift-card",
    ReportsGiftCardBalances = "/gift-card/balances",
    ReportsGiftCardTransactions = "/gift-card/transactions",
    ReportsGiftCardLiabilities = "/gift-card/liabilities",
    ReportsLocation = "#reports/location",
    ReportsLocationSales = "/location-sales",
    ReportsLocationMenuCategories = "/location-menu-categories",
    ReportsLocationMenuItems = "/location-menu-items",

    // -- Time Cards
    Labor = "#labor",
    EmployeeTimeCards = "/employee-time-cards",
    Tipping = "/tipping",
    MinuteToMinute = "/reports/sales-hourly",

    // Settings
    Settings = "#settings",

    // -- Store
    SettingsBusinessInfo = "/settings/business-info",

    // -- Team
    SettingsPermissions = "/users",
    SettingsEmployees = "/settings/clock-in-out",

    // -- Devices
    Devices = "/devices",

    // -- Front of House
    SettingsFrontOfHouse = "#settings-front-of-house",
    SettingsBrandAndAppearance = "/settings/brand-and-appearance",
    SettingsTipping = "/settings/tipping",
    SettingsAllOrders = "/settings/all-orders",
    SettingsOnlineOrders = "/settings/online-orders",
    SettingsPlatforms = "/settings/platforms",

    // -- Back of House
    SettingsBOH = "#settings-back-of-house",
    PrepStations = "/prep-stations",
    SettingsBackOfHouse = "/settings/back-of-house",
    SettingsStoreTablesLayout = "/settings/store-tables-layout",

    // -- Account and Tax
    AccountAndTax = "#account-and-tax",
    Billing = "/billing",
    Payouts = "/payouts",
    PayoutsSchedule = "/payouts-schedule",
    SettingsTax = "/settings/tax",
    TaxForms = "/tax-forms",

    // -- Other Settings
    Other = "#other",
    SettingsProducts = "/settings/products",
    SettingsOrders = "/settings/orders",
    SettingsLegal = "/settings/legal",

    // -- Snackpass Admin
    SnackpassAdmin = "#snackpass-admin",
    InternalSettings = "/settings-internal",
    InternalDevices = "/settings/devices",
    InternalFees = "/settings/fees",
    InternalSeniorSupport = "/settings/senior-support",

    // Account
    SettingsAccount = "/settings/account",
    MyAccountTeams = "/my-teams",
    NewUserAcceptInvite = "/accept-invite",
    NotAllowed = "/not-allowed",
    NotFound = "/not-found",
    Logout = "/logout",
}
