import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { OrderStatusConfiguration } from "@snackpass/snackpass-types";
import { Alert, AlertDescription, AlertTitle } from "src/@/components/ui/alert";
import { Routes } from "#navigation/routes";
import { getActiveStore } from "src/redux/selectors";
import api from "src/api/rest";
import { PayoutChannels } from "#payouts/domain/types";
import { PaymentMethodStatus } from "#billing/domain/types";
import { Link } from "src/@/components/ui/link";
import { AlertCircle } from "lucide-react";

export const Warnings = () => {
    const store = useSelector(getActiveStore);
    const [payoutsWarning, setPayoutsWarning] = useState<
        null | "warning" | "destructive"
    >(null);
    const [billingWarning, setBillingWarning] = useState<
        null | "warning" | "destructive"
    >(null);

    useEffect(() => {
        const checkIfRoutable = async (storeId: string) =>
            api.verifications
                .channel(storeId)
                .then(
                    ({ data }) =>
                        data.success && data.channel !== PayoutChannels.stripe,
                )
                .catch(() => false);

        const checkIfVerified = async (storeId: string) =>
            api.verifications
                .status(storeId)
                .then(({ data }) =>
                    data.status === "none" ? null : data.status,
                )
                .catch(() => null);

        const checkSaasAccount = async (storeId: string) =>
            api.billing
                .getSaasAccount(storeId)
                .then(({ data }) => {
                    if (data.success) {
                        if (
                            [
                                PaymentMethodStatus.none,
                                PaymentMethodStatus.methodAdded,
                            ].includes(
                                data.account.onboardingPaymentMethodStatus,
                            )
                        ) {
                            return null;
                        } else if (
                            [
                                PaymentMethodStatus.microdepositFailed,
                                PaymentMethodStatus.microdepositPending,
                            ].includes(
                                data.account.onboardingPaymentMethodStatus,
                            )
                        ) {
                            return "warning";
                        }
                    }
                    return null;
                })
                .catch(() => null);

        const checkAndSetPayoutsWarnState = async () => {
            if (store?._id) {
                const isRoutable = await checkIfRoutable(store._id);
                if (isRoutable) {
                    setPayoutsWarning("destructive");
                } else {
                    const warning = await checkIfVerified(store._id);
                    setPayoutsWarning(warning);
                }
            }
        };

        const checkAndSetBillingWarnState = async () => {
            if (store?._id) {
                const warning = await checkSaasAccount(store._id);
                setBillingWarning(warning);
            }
        };

        void checkAndSetPayoutsWarnState();
        void checkAndSetBillingWarnState();
    }, [store?._id]);

    const warnables = {
        payouts: Boolean(payoutsWarning),
        billing: Boolean(billingWarning),
        autoComplete:
            store?.orderStatusConfiguration ===
                OrderStatusConfiguration.AutoAcceptAutoComplete ||
            store?.orderStatusConfiguration ===
                OrderStatusConfiguration.ManualAcceptAutoComplete,
        defaultPin: !store?.hasEmployeeAuditTracking,
        dynamicWaitTime: store?.waitTimeType === "DYNAMIC",
        specificPickupTime: store?.pickupTimeType === "specific",
    };

    if (Object.values(warnables).includes(true)) {
        return (
            <Alert variant={"destructive"} className="mb-6">
                <AlertTitle className="text-base">
                    <span className="flex items-center">
                        <AlertCircle className="h-4 w-4 mr-2" />
                        Attention Required
                    </span>
                </AlertTitle>
                <AlertDescription>
                    <ul className="mb-0 list-disc pl-4">
                        {warnables.payouts && (
                            <li>
                                Please review the Bank Account page&nbsp;
                                <Link to={Routes.Payouts}>here</Link> for more
                                information.
                            </li>
                        )}
                        {warnables.autoComplete && (
                            <li>
                                The 'Use ETA To Mark Orders Ready' setting is
                                now deprecated. For better accuracy please turn
                                off this feature&nbsp;
                                <Link to={Routes.SettingsBackOfHouse}>
                                    here
                                </Link>
                                &emsp;and manually mark orders as ready for
                                pickup.
                            </li>
                        )}
                        {warnables.defaultPin && (
                            <li>
                                The 'Use Default Pin' setting is now deprecated.
                                To enhance security, please enable 'Require
                                Employee Pin Entry'&nbsp;
                                <Link to={Routes.SettingsBackOfHouse}>
                                    here
                                </Link>
                                , ensuring employees use unique PINs for
                                auditable tasks and protecting your device from
                                easily guessed pins.
                            </li>
                        )}
                        {warnables.dynamicWaitTime && (
                            <li>
                                The 'Dynamic Wait Time' setting is now
                                deprecated. Please disable Dynamic Wait
                                Time&nbsp;
                                <Link to={Routes.SettingsAllOrders}>here</Link>
                                &emsp;and switch to Manual Time entry for more
                                accurate wait time management at your store.
                            </li>
                        )}
                        {warnables.specificPickupTime && (
                            <li>
                                The 'Specific Wait Time' setting is now
                                deprecated. Please disable Specific Wait
                                Time&nbsp;
                                <Link to={Routes.SettingsOnlineOrders}>
                                    here
                                </Link>
                                &emsp;and switch to a time range for more
                                accurate wait time management at your store.
                            </li>
                        )}
                        {warnables.billing && (
                            <li>
                                Please review your Billing Settings&nbsp;
                                <Link to={Routes.Billing}>here</Link> for more
                                information.
                            </li>
                        )}
                    </ul>
                </AlertDescription>
            </Alert>
        );
    }

    return null;
};
