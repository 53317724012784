import { Wallet } from "lucide-react";
import { PaymentMethod, PaymentMethodOptions } from "#sales-report/types";

import { useOrders } from "../../orders-context";
import { FilterSelect2 } from "src/@/components/ui/filter-select-2";

export const PaymentMethodPicker = () => {
    const { paymentMethods, setPaymentMethods } = useOrders();

    const selectedPaymentTypes = new Set(paymentMethods);

    return (
        <FilterSelect2
            icon={Wallet}
            title="Payment"
            selectedValues={selectedPaymentTypes}
            options={PaymentMethodOptions}
            onOptionSelected={(value: string) => {
                const paymentType = value as PaymentMethod;
                if (selectedPaymentTypes.has(paymentType)) {
                    selectedPaymentTypes.delete(paymentType);
                } else {
                    selectedPaymentTypes.add(paymentType);
                }
                setPaymentMethods(Array.from(selectedPaymentTypes));
            }}
        />
    );
};
