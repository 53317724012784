import { RefreshCcw } from "lucide-react";
import { Button } from "src/@/components/ui/button";
import { Switch } from "src/@/components/ui/switch";
import { useOrders } from "../../orders-context";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "src/@/components/ui/popover";
import { Label } from "src/@/components/ui/label";

export const OrdersRefundPicker = () => {
    const { refunded, setRefunded } = useOrders();

    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button
                    variant={refunded ? "default" : "outline"}
                    size="sm"
                    className="flex items-center"
                    icon={RefreshCcw}
                >
                    <span className="hidden lg:block ">Refunded</span>
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[200px] p-3">
                <div className="space-y-2 flex flex-col">
                    <Label htmlFor="refund-switch" className="text-sm">
                        Show refunded only
                    </Label>
                    <Switch
                        id="refund-switch"
                        checked={refunded}
                        onCheckedChange={setRefunded}
                    />
                </div>
            </PopoverContent>
        </Popover>
    );
};
