import { Users } from "src/api/rest/modules/users";
import { Stores } from "src/api/rest/modules/stores";
import { StoreDevices } from "src/api/rest/modules/store-devices";
import { Purchases } from "src/api/rest/modules/purchases";
import { Admins } from "src/api/rest/modules/admins";
import { Verifications } from "src/api/rest/modules/verifications";
import { CashDrawers, PrepStations } from "src/api/rest/modules/prep-stations";
import { Reports } from "src/api/rest/modules/reports";
import { Uploads } from "src/api/rest/modules/uploads";
import { MultiMenusApi } from "src/api/rest/modules/multiMenus";
import { Billing } from "src/api/rest/modules/billing";
import { Twilio } from "src/api/rest/modules/twilio";
import {
    Payouts,
    Products,
    Promotions,
    Kiosks,
    Tables,
    IntegrationsRequest,
    Deliverect,
    PunchCards,
    Gifts,
    Printers,
    Logs,
    Chains,
    Auth,
    Inventory,
    Conversations,
} from "src/api/rest/modules/miscellaneous";
import { AdminUserInvite } from "src/api/rest/modules/miscellaneous";

const api = {
    admins: Admins,
    auth: Auth,
    billing: Billing,
    chains: Chains,
    cashDrawers: CashDrawers,
    gifts: Gifts,
    inventory: Inventory,
    logs: Logs,
    prepStations: PrepStations,
    printers: Printers,
    products: Products,
    promotions: Promotions,
    purchases: Purchases,
    reports: Reports,
    stores: Stores,
    storeDevices: StoreDevices,
    kiosk: Kiosks,
    tables: Tables,
    uploads: Uploads,
    users: Users,
    integrations: {
        request: IntegrationsRequest,
    },
    deliverect: Deliverect,
    multiMenus: MultiMenusApi,
    conversations: Conversations,
    punchCards: PunchCards,
    verifications: Verifications,
    twilio: Twilio,
    adminUserInvite: AdminUserInvite,
    payouts: Payouts,
};

export default api;
export type {
    PayoutHistoryItem,
    PayoutHistoryItemStatus,
} from "src/api/rest/modules/stores";
export type { BaseStoreDeviceWithNetworkStatus } from "src/api/rest/modules/store-devices";
export type { UpdateStatusParams } from "src/api/rest/modules/purchases";
export type { SummarizedDevice } from "src/api/rest/modules/store-devices";
export const REPORTS_REQUEST_TIMEOUT = 120 * 1000;
