import { ChannelType } from "@snackpass/snackpass-types";
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { match } from "ts-pattern";

import { MultiSelect } from "src/@/components/ui/multi-select";
import { CreatePromotion } from "src/api/graphql/generated/types";

const channelOptions = Object.values(ChannelType).map((type) => ({
    label: match(type)
        .with(ChannelType.App, () => "App")
        .with(ChannelType.Catering, () => "Catering")
        .with(ChannelType.Kiosk, () => "SnackOS")
        .with(ChannelType.Online, () => "Online")
        .with(ChannelType.Register, () => "Register")
        .exhaustive(),
    value: type,
}));

export function ChannelSelection() {
    const { watch, setValue } = useFormContext<CreatePromotion>();

    const channels = watch("channels");

    const onChangeChannels = useCallback(
        (channels: string[]) => setValue("channels", channels as ChannelType[]),
        [setValue],
    );

    return (
        <div className="flex items-center justify-between">
            <span>Supported Channels</span>
            <MultiSelect
                selected={channels}
                onChange={onChangeChannels}
                options={channelOptions}
                placeholder="Select Channels"
                searchPlaceholder="Search Channels"
                className="max-w-80"
            />
        </div>
    );
}
