import { useCallback } from "react";
import { PurchaseReportData } from "@snackpass/snackpass-types";
import { useState } from "react";
import { Spinner } from "src/@/components/ui/spinner";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import clsx from "clsx";
import { VariantProps } from "class-variance-authority";
import { toast } from "sonner";

import { getStoreTimezone } from "#utils/helpers";
import { getActiveStore, getUserTeamPermission } from "src/redux/selectors";
import { Button, buttonVariants } from "src/@/components/ui/button";
import { Download } from "lucide-react";
import { ExportToCsv } from "#utils/export-to-csv";
import { useOrders } from "../orders-context";
import { formatForCSV } from "../lib";

enum LoadState {
    unloaded,
    loading,
    loaded,
}

const dateTimeFormat = "YYYY-MMM-DD";

interface Props extends VariantProps<typeof buttonVariants> {
    className?: string;
    hideLabel?: boolean;
}

export const OrdersDownloadButton = ({
    variant = "outline",
    hideLabel = false,
    className = "",
}: Props) => {
    const {
        paymentMethods,
        channels,
        providers,
        fulfillments,
        startDate,
        endDate,
        purchaseReportRows: { data: rows },
        purchaseReportTotals: { total: totals },
    } = useOrders();

    const activeStore = useSelector(getActiveStore);
    const isSnackTeam = useSelector(getUserTeamPermission);
    const [loadState, setLoad] = useState<LoadState>(LoadState.unloaded);

    const timezone = getStoreTimezone(activeStore);

    const downloadPurchases = useCallback(() => {
        setLoad(LoadState.loading);

        if (!totals || !rows || !rows?.length) {
            toast.info("No data to export.");
            return setLoad(LoadState.loaded);
        }

        // Filter rows by date range
        const filteredRows = rows.filter((purchase: PurchaseReportData) => {
            const dateReceived = moment(purchase.dateReceived).tz(timezone);
            return (
                dateReceived.isSameOrAfter(startDate) &&
                dateReceived.isBefore(endDate)
            );
        });

        const showRemittance = filteredRows.some(
            (summarizedPurchase: PurchaseReportData) =>
                summarizedPurchase.storeTaxesWithheld,
        );

        // Apply all filters when preparing CSV data
        let csvPurchases = formatForCSV(
            filteredRows,
            timezone,
            isSnackTeam,
            showRemittance,
        );

        const csvExporter = new ExportToCsv({
            filename: `${activeStore?.name} - Orders Report ${startDate.format(dateTimeFormat)}-${endDate.format(dateTimeFormat)}`,
            fieldSeparator: ",",
            useKeysAsHeaders: true,
        });

        if (csvPurchases.length > 1) {
            csvExporter.generateCsv(csvPurchases);
        } else {
            void toast.info("There are no orders that fit these filters");
        }

        setLoad(LoadState.loaded);
    }, [
        activeStore?.name,
        startDate,
        endDate,
        rows,
        totals,
        channels,
        providers,
        fulfillments,
        isSnackTeam,
        timezone,
        paymentMethods,
    ]);

    if (!activeStore?._id) return null;

    if (loadState === LoadState.loading) {
        return <Spinner color="black" size="sm" />;
    }

    return (
        <Button
            variant={variant}
            size="sm"
            onClick={downloadPurchases}
            className={clsx("flex space-x-1", className)}
        >
            <Download className="h-4 w-4" />
            <span className={clsx("hidden", !hideLabel && "md:block")}>
                Export
            </span>
        </Button>
    );
};
