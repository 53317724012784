import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { RecoilRoot } from "recoil";

import { ReportsContextProvider } from "#app/providers/reports-context-provider";
import { getActiveStoreId } from "src/redux/selectors";
import { SidebarInset, SidebarProvider } from "src/@/components/ui/sidebar";
import { AppSidebar } from "#navigation/sidebar";
import { PubnubContextProvider } from "#app/providers/pubnub-context-provider";
import { NavigationHeader } from "#navigation/sidebar/navigation-header";
import { useCustomLayoutStyles } from "#app/providers/use-custom-layout-styles";
import { RouteProtection } from "#navigation/components/RouteProtection";

export const Layout = () => {
    const activeStoreId = useSelector(getActiveStoreId);
    const { layoutClassName } = useCustomLayoutStyles();

    // a few more providers. the rest are in global-providers.tsx
    return (
        <PubnubContextProvider>
            <RecoilRoot key={activeStoreId || "unknown"}>
                <ReportsContextProvider>
                    <SidebarProvider>
                        <AppSidebar />
                        <SidebarInset>
                            <NavigationHeader />
                            <div id="app-layout" className={layoutClassName}>
                                <RouteProtection>
                                    <Outlet />
                                </RouteProtection>
                            </div>
                        </SidebarInset>
                    </SidebarProvider>
                </ReportsContextProvider>
            </RecoilRoot>
        </PubnubContextProvider>
    );
};
