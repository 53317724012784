import { CellContext } from "@tanstack/react-table";
import { memo } from "react";
import { match, P } from "ts-pattern";
import { useFormContext } from "react-hook-form";

import {
    CreatePromotionCondition,
    CreatePromotion,
} from "src/api/graphql/generated/types";

export const ConditionHeader = memo(
    ({ row }: CellContext<CreatePromotionCondition, unknown>) => {
        const { formState } = useFormContext<CreatePromotion>();

        const error = formState.errors?.conditions?.[row.index];

        return (
            <div>
                <p className="font-bold">
                    {match(row.original)
                        .with(
                            {
                                categoryCountCondition: P.not(P.nullish),
                            },
                            () => "Limit Per Category",
                        )
                        .with(
                            { itemCountCondition: P.not(P.nullish) },
                            () => "Limit Per Item",
                        )
                        .with(
                            { maxItemsCondition: P.not(P.nullish) },
                            () => "Max Items",
                        )
                        .otherwise(() => {
                            throw new Error(
                                `Invalid condition: ${row.original}`,
                            );
                        })}
                </p>
                {error ? (
                    <span className="text-sm font-normal text-critical-light">
                        {match(error)
                            .with(
                                {
                                    itemCountCondition: P.select({
                                        productId: P.not(P.nullish),
                                    }),
                                },
                                () => "Product is required",
                            )
                            .with(
                                {
                                    categoryCountCondition: P.select({
                                        category: P.not(P.nullish),
                                    }),
                                },
                                () => "Category is required",
                            )
                            .otherwise(() => error.message)}
                    </span>
                ) : null}
            </div>
        );
    },
);
