import { useMemo } from "react";
import { keyBy } from "lodash";

import { usePromotions } from "#new-promotions/hooks";
import { DataTable } from "src/@/components/ui/data-table";
import { Skeleton } from "src/@/components/ui/skeleton";
import { useProducts } from "#menu/hooks";
import { buildColumns } from "#new-promotions/utils/buildPromotionColumns";

import { CreatePromotionButton } from "./CreatePromotionButton";

export function PromotionsTable() {
    const products = useProducts();
    const { data, loading } = usePromotions();

    const productMap = useMemo(() => keyBy(products, "_id"), [products]);

    const columns = useMemo(() => buildColumns({ productMap }), [productMap]);

    if (loading)
        return <Skeleton aria-label="skeleton" className="h-96 w-full" />;

    return (
        <DataTable
            key={data.length}
            data={data}
            columns={columns}
            toolbar={{
                search: {
                    key: "name",
                    placeholder: "Search by name or discount",
                },
                customComponent: <CreatePromotionButton />,
            }}
        />
    );
}
