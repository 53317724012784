import { useCallback } from "react";
import { useMatches } from "react-router-dom";

import { useAppSelector } from "src/redux/hooks";
import {
    getActiveStore,
    getUser,
    getUserIsSnackpassEmployee,
} from "src/redux/selectors";
import { Routes } from "#navigation/routes";
import { StorePermissions, RouteHandle } from "#navigation/types";
import { INTERNAL_ROUTES, RouteItems } from "#navigation/route-data";

export function useRoutePermissions() {
    const matches = useMatches();
    const activeStore = useAppSelector(getActiveStore);
    const user = useAppSelector(getUser);
    const isSPEmployee = useAppSelector(getUserIsSnackpassEmployee);

    const storePermissions = (user?.permissions ?? {}) as StorePermissions;
    const activeStoreId = activeStore?._id || "";
    const isOwner = storePermissions?.isOwner?.includes(activeStoreId);
    // be careful about the order!
    const hasRoutePermission = useCallback(
        (route: Routes): boolean => {
            // Internal routes require SP Employee
            if (INTERNAL_ROUTES.includes(route)) {
                return isSPEmployee;
            }

            // SP Employee has access to everything after onboarding
            if (isSPEmployee) {
                return true;
            }

            // Owners have access to everything after onboarding
            if (isOwner) {
                return true;
            }

            // Check store permissions
            const requiredPermission = RouteItems[route].permission;

            // if no permission is required (set in route-data.ts), default to true
            if (!requiredPermission) return true;

            return Boolean(
                storePermissions[requiredPermission]?.includes(activeStoreId),
            );
        },
        [isSPEmployee, storePermissions, isOwner, activeStoreId],
    );

    /**
     * Check to see if current route matches are all permitted
     */
    const hasMatchingPermissions = useCallback((): boolean => {
        // If the route is marked as internal, confirm that user is SP employee
        if (matches.some(({ handle }) => (handle as RouteHandle)?.internal)) {
            return isSPEmployee;
        }

        // internal employees and store owners can access everything beyond this point
        if (isSPEmployee || isOwner) {
            return true;
        }

        // Check to see if any of the matches require a permission that would be violated
        const hasPermissionViolation = matches.some(({ handle }) => {
            const permission = (handle as RouteHandle)?.permission;

            // Can't violate a permission that isn't there
            if (!permission) {
                return false;
            }

            // If store id is permitted, no violation
            if (storePermissions[permission]?.includes(activeStoreId)) {
                return false;
            }

            return true;
        });

        return !hasPermissionViolation;
    }, [matches, isSPEmployee, storePermissions, isOwner, activeStoreId]);

    return {
        hasRoutePermission,
        hasMatchingPermissions,
    };
}
