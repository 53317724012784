import { useSelector } from "react-redux";
import { getActiveStore, getUser } from "src/redux/selectors";
import { useContext, useMemo } from "react";
import { OnboardingContext } from "#onboarding/utils/OnboardingContext";
import { Routes } from "#navigation/routes";
import { usePromotions } from "#promotion/hooks/usePromotions";
import { PromotionType } from "src/api/graphql/generated/types";
import { IStore } from "@snackpass/snackpass-types";

type OnboardingSection = {
    title: string;
    route?: string;
    fields: {
        label: string;
        value: string | undefined | null;
        required: boolean;
        setupAction?: {
            label: string;
            url?: string;
            onClick?: () => void;
        };
    }[];
};

export const useStoreOnboardingStatus = () => {
    const store = useSelector(getActiveStore);
    const user = useSelector(getUser);
    const { verification } = useContext(OnboardingContext);
    const { promotions } = usePromotions();

    const hasActivePromotionOfType = useMemo(
        () => (type: string) =>
            promotions?.some(
                (promo) =>
                    promo.type === type && promo.isRunning && !promo.isArchived,
            ),
        [promotions],
    );

    // Add later once we clean up promotions
    // const hasActiveSignUpPromotion = promotions?.some(
    //     (promo) =>
    //         promo.conditions?.pickupOnly &&
    //         promo.isRunning &&
    //         !promo.isArchived,
    // );

    const getDeliverySetupUrl = (store: IStore | null) => {
        const params = new URLSearchParams({
            "prefill_Store Name": store?.name || "",
            "prefill_Store Address": store?.address || "",
            "prefill_Snackpass Store ID": store?._id || "",
            "prefill_Contact Name": user?.name || "",
            "prefill_Contact Email": user?.email || "",
            "prefill_Contact Phone Number": user?.number || "",
        }).toString();
        return `https://airtable.com/appHS6tXYpP4rJYHb/pagWWpnmIQoq83IZv/form?${params}`;
    };

    const getWebsiteSetupUrl = (store: IStore | null) => {
        const params = new URLSearchParams({
            "prefill_Store Name": store?.name || "",
            "prefill_Snackpass Store ID": store?._id || "",
            "prefill_Contact Name": user?.name || "",
            "prefill_Contact Email": user?.email || "",
            "prefill_Contact Phone": user?.number || "",
        }).toString();
        return `https://airtable.com/appy8eQbMkVKrsnZ4/pagwhjpPkzA0a3vgS/form?${params}`;
    };

    const getFirstPartyDeliveryUrl = (store: IStore | null) => {
        const params = new URLSearchParams({
            "prefill_Contact Name": user?.name || "",
            "prefill_Contact Email": user?.email || "",
            "prefill_Contact Phone Number": user?.number || "",
            "prefill_Store Name": store?.name || "",
            "prefill_Snackpass Store ID": store?._id || "",
            "prefill_Store Address": store?.addressComponents?.full || "",
        }).toString();
        return `https://airtable.com/appvln2qhOIcUiwX3/pagSWGC5K3clRERlp/form?${params}`;
    };

    const sections = useMemo<OnboardingSection[]>(
        () => [
            {
                title: "Business Information",
                route: Routes.SettingsBusinessInfo,
                fields: [
                    { label: "Store Name", value: store?.name, required: true },
                    { label: "Email", value: store?.email, required: true },
                    {
                        label: "Phone",
                        value: store?.phoneNumber,
                        required: true,
                    },
                    {
                        label: "Address",
                        value: store?.addressComponents?.full,
                        required: true,
                    },
                    {
                        label: "Timezone",
                        value: store?.hours?.zone,
                        required: true,
                    },
                    { label: "Store Type", value: store?.kind, required: true },
                    {
                        label: "Store Hours",
                        value:
                            store?.hours?.local && store.hours.local.length > 0
                                ? "Set"
                                : undefined,
                        required: true,
                    },
                ],
            },
            {
                title: "Business Verification",
                route: Routes.Payouts,
                fields: [
                    {
                        label: "Legal Business Name",
                        value: verification?.name,
                        required: true,
                    },
                    {
                        label: "Employer Identification Number (EIN)",
                        value: verification?.taxId ? "Provided" : undefined,
                        required: true,
                    },
                    {
                        label: "Business Phone Number",
                        value: verification?.phone,
                        required: true,
                    },
                    {
                        label: "Bank Account",
                        value: verification?.accountLast4
                            ? "Connected"
                            : undefined,
                        required: true,
                    },
                ],
            },
            {
                title: "Brand and Appearance",
                route: Routes.SettingsBrandAndAppearance,
                fields: [
                    { label: "Username", value: store?.slug, required: true },
                    { label: "Logo", value: store?.logoUrl, required: true },
                    {
                        label: "Cover Photo",
                        value: store?.thumbnailUrl,
                        required: true,
                    },
                    {
                        label: "Brand Color",
                        value: store?.brandColor,
                        required: true,
                    },
                    {
                        label: "Store Emoji",
                        value: store?.emoji === "🍽️" ? undefined : store?.emoji,
                        required: false,
                    },
                    {
                        label: "Kiosk Splash Video",
                        value: store?.kioskPreferences?.attractScreenUrl,
                        required: false,
                    },
                    {
                        label: "Digital Receipt Background",
                        value: store?.loyaltyCardBackgroundImage,
                        required: false,
                    },
                    {
                        label: "Register Secondary Display",
                        value: store?.brandSplashUrl,
                        required: false,
                    },
                ],
            },
            {
                title: "Tax Settings",
                route: Routes.SettingsTax,
                fields: [
                    {
                        label: "Tax Rate",
                        value: store?.taxRate ? `${store.taxRate}%` : undefined,
                        required: true,
                    },
                ],
            },
            {
                title: "All Orders",
                route: Routes.SettingsAllOrders,
                fields: [
                    {
                        label: "Bag Fee",
                        value: "Set",
                        required: false,
                    },
                    {
                        label: "Yelp/Google Review URL",
                        value: store?.externalReviewUrl,
                        required: false,
                    },
                ],
            },
            {
                title: "Tipping",
                route: Routes.SettingsTipping,
                fields: [
                    {
                        label: "Tip Image",
                        value: store?.tipImageUrl,
                        required: false,
                    },
                ],
            },
            {
                title: "Promotions",
                route: Routes.Promotion,
                fields: [
                    {
                        label: "Rewards",
                        value: hasActivePromotionOfType(PromotionType.Reward)
                            ? "Enabled"
                            : undefined,
                        required: false,
                    },
                ],
            },
            {
                title: "Gift Cards",
                route: Routes.SettingsGiftCard,
                fields: [
                    {
                        label: "Digital Gift Cards",
                        value: store?.digitalGiftCardAvailable
                            ? "Enabled"
                            : undefined,
                        required: false,
                    },
                    {
                        label: "Physical Gift Cards",
                        value: store?.physicalGiftCardAvailable
                            ? "Enabled"
                            : undefined,
                        required: false,
                    },
                ],
            },
            {
                title: "Delivery",
                fields: [
                    {
                        label: "First Party Delivery",
                        value: store?.delivery ? "Set" : undefined,
                        required: false,
                        setupAction: {
                            label: "Set Up",
                            url: getFirstPartyDeliveryUrl(store),
                        },
                    },
                    {
                        label: "Third Party Delivery Integration",
                        value: store?.deliveryMarketplaceIntegrationEnabled
                            ? "Set"
                            : undefined,
                        required: false,
                        setupAction: {
                            label: "Set Up",
                            url: getDeliverySetupUrl(store),
                        },
                    },
                ],
            },
            {
                title: "Other Products",
                fields: [
                    {
                        label: "Custom Website",
                        value: store?.websiteEnabled ? "Set" : undefined,
                        required: false,
                        setupAction: {
                            label: "Set Up",
                            url: getWebsiteSetupUrl(store),
                        },
                    },

                    {
                        label: "Catering",
                        value: store?.cateringEnabled ? "Set" : undefined,
                        required: false,
                        setupAction: {
                            label: "Set Up",
                            url: `https://airtable.com/appy8eQbMkVKrsnZ4/pagp4buwJY6pjmqxT/form?${new URLSearchParams(
                                {
                                    "prefill_Snackpass Store ID":
                                        store?._id || "",
                                    "prefill_Store Name": store?.name || "",
                                    "prefill_Contact Name": user?.name || "",
                                    "prefill_Contact Email": user?.email || "",
                                    "prefill_New Store": "false",
                                    "prefill_Upload Catering Menu": "",
                                },
                            ).toString()}`,
                        },
                    },
                    {
                        label: "Snackpass App",
                        value: store?.snackpassAppEnabled ? "Set" : undefined,
                        required: false,
                        setupAction: {
                            label: "Set Up",
                            url: Routes.SettingsProducts,
                        },
                    },
                ],
            },
        ],
        [store, verification, hasActivePromotionOfType, user],
    );

    const getMissingFields = useMemo(() => {
        const missing: { section: string; fields: string[] }[] = [];

        sections.forEach((section) => {
            const missingInSection = section.fields
                .filter((field) => field.required && !field.value)
                .map((field) => field.label);

            if (missingInSection.length > 0) {
                missing.push({
                    section: section.title,
                    fields: missingInSection,
                });
            }
        });

        return missing;
    }, [sections]);

    const isFullyOnboarded = useMemo(
        () =>
            sections.every((section) =>
                section.fields.every(
                    (field) => !field.required || Boolean(field.value),
                ),
            ),
        [sections],
    );

    return {
        isFullyOnboarded,
        missingFields: getMissingFields,
        sections,
    };
};
