import { toDollar } from "@snackpass/accounting";
import { match, P } from "ts-pattern";

import { PromotionAdjustment } from "src/api/graphql/generated/types";

export const formatAdjustment = (adjustment: PromotionAdjustment) =>
    match(adjustment.adjustment)
        .with(
            { discountFlat: P.number },
            ({ discountFlat }) => `${toDollar(discountFlat / 100)} off `,
        )
        .with(
            { discountPercent: P.number },
            ({ discountPercent }) => `${discountPercent}% off `,
        )
        .with({ price: P.number }, ({ price }) => `${toDollar(price / 100)} `)
        .exhaustive() +
    match(adjustment.scope)
        .with({ menu: P.boolean }, () => "all items")
        .with({ products: P.array(P.string) }, () => "select products")
        .with({ categories: P.array(P.string) }, () => "select categories")
        .exhaustive();
