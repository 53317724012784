import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

import api from "src/api/rest";
import { getActiveStore } from "src/redux/selectors";
import { logAndSendError } from "src/utils/errors";
import { useDashboardContext } from "./use-dashboard-context";
import {
    CustomerTotals,
    CustomerSummary,
} from "src/api/rest/modules/customers";

interface CustomerChanges {
    appCustomersChange: number;
    newCustomersChange: number;
    repeatCustomersChange: number;
}

export const useQueryCustomersSummary = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [summary, setSummary] = useState<CustomerSummary | null>(null);
    const [totals, setTotals] = useState<CustomerTotals>({
        app: 0,
        new: 0,
        repeat: 0,
    });
    const [comparisonTotals, setComparisonTotals] = useState<CustomerTotals>({
        app: 0,
        new: 0,
        repeat: 0,
    });

    const store = useSelector(getActiveStore);
    const { currentPeriodStartEnd, comparisonPeriodStartEnd } =
        useDashboardContext();

    const calculateChanges = useCallback((): CustomerChanges => {
        const calculatePercentChange = (
            current: number,
            previous: number,
        ): number =>
            previous > 0
                ? Math.round(((current - previous) / previous) * 100)
                : 0;

        return {
            appCustomersChange: calculatePercentChange(
                totals.app,
                comparisonTotals.app,
            ),
            newCustomersChange: calculatePercentChange(
                totals.new,
                comparisonTotals.new,
            ),
            repeatCustomersChange: calculatePercentChange(
                totals.repeat,
                comparisonTotals.repeat,
            ),
        };
    }, [totals, comparisonTotals]);

    const fetchSummary = useCallback(async () => {
        if (
            !store?._id ||
            !currentPeriodStartEnd?.startDate ||
            !currentPeriodStartEnd?.endDate
        ) {
            return;
        }

        try {
            setIsLoading(true);
            setError(null);

            // Fetch both current and comparison period data
            const [currentResponse, comparisonResponse] = await Promise.all([
                api.reports.customers.getCustomersSummary(store._id, {
                    startDate:
                        currentPeriodStartEnd.startDate.format("YYYY-MM-DD"),
                    endDate: currentPeriodStartEnd.endDate.format("YYYY-MM-DD"),
                }),
                comparisonPeriodStartEnd
                    ? api.reports.customers.getCustomersSummary(store._id, {
                          startDate:
                              comparisonPeriodStartEnd.startDate.format(
                                  "YYYY-MM-DD",
                              ),
                          endDate:
                              comparisonPeriodStartEnd.endDate.format(
                                  "YYYY-MM-DD",
                              ),
                      })
                    : Promise.resolve({ data: { customersSummary: null } }),
            ]);

            setSummary(currentResponse.data.customersSummary);
            setTotals({
                new: currentResponse.data.customersSummary.newCustomers,
                repeat: currentResponse.data.customersSummary.repeatCustomers,
                app: currentResponse.data.customersSummary.uniqueAppCustomers,
            });

            if (comparisonResponse.data.customersSummary) {
                setComparisonTotals({
                    new: comparisonResponse.data.customersSummary.newCustomers,
                    repeat: comparisonResponse.data.customersSummary
                        .repeatCustomers,
                    app: comparisonResponse.data.customersSummary
                        .uniqueAppCustomers,
                });
            }
        } catch (err) {
            if (!axios.isCancel(err)) {
                console.error(
                    "[useCustomersSummary] Error fetching summary:",
                    err,
                );
                logAndSendError(err);
                setError(err as Error);
            }
        } finally {
            setIsLoading(false);
        }
    }, [
        store,
        currentPeriodStartEnd?.startDate,
        currentPeriodStartEnd?.endDate,
        comparisonPeriodStartEnd?.startDate,
        comparisonPeriodStartEnd?.endDate,
    ]);

    useEffect(() => {
        fetchSummary();
    }, [fetchSummary]);

    return {
        summary,
        totals,
        comparisonTotals,
        changes: calculateChanges(),
        isLoading,
        error,
        refetch: fetchSummary,
    };
};
