import { memo, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { match } from "ts-pattern";

import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "src/@/components/ui/select";
import {
    ConditionScope,
    CreatePromotionCondition,
    CreatePromotion,
} from "src/api/graphql/generated/types";

export type ConditionScopeInputProps = {
    condition: keyof CreatePromotionCondition;
    index: number;
};

export const ConditionScopeInput = memo(
    ({ condition, index }: ConditionScopeInputProps) => {
        const { watch, setValue } = useFormContext<CreatePromotion>();

        const scope = watch(`conditions.${index}.${condition}.scope`);

        const onChangeScope = useCallback(
            (scope: ConditionScope) =>
                setValue(`conditions.${index}.${condition}.scope`, scope),
            [condition, index, setValue],
        );

        return (
            <div className="flex items-center justify-between">
                <span>Condition applies every</span>
                <Select value={scope} onValueChange={onChangeScope}>
                    <SelectTrigger className="w-40">
                        <SelectValue placeholder="Select Discount" />
                    </SelectTrigger>
                    <SelectContent>
                        {Object.values(ConditionScope).map((scope) => (
                            <SelectItem key={scope} value={scope}>
                                {match(scope)
                                    .with(
                                        ConditionScope.Session,
                                        () => "Session",
                                    )
                                    .with(ConditionScope.Round, () => "Round")
                                    .exhaustive()}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
            </div>
        );
    },
);
