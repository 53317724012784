import { IUser } from "@snackpass/snackpass-types";
import axios from "axios";
import { z } from "zod";
import { ErrorWithCause, UserFacingError } from "src/utils/errors";
import { StoreRelationshipInfo } from "#my-teams/types";
import { SeniorSupportUser } from "#settings/settings-senior-support/utils";

import { client } from "../client";

export class Users {
    static async getMe(idToken: string) {
        return client
            .get<{ user: IUser; hash?: string }>("/users/me", {
                headers: { Authorization: `Bearer ${idToken}` },
                params: {
                    includeDashboardIntercomHashFor: "web",
                    noPunchcards: true,
                },
            })
            .catch((error) => {
                throw new Error("GET to /users/me failed", error);
            });
    }

    static async sendVerificationCode() {
        return client
            .post<void>("/users/send-verification-code", undefined, {
                withCredentials: true,
            })
            .catch((cause) => {
                if (axios.isAxiosError(cause)) {
                    const parseResult = z
                        .object({
                            status: z.literal(400),
                            data: z.object({ message: z.string() }),
                        })
                        .safeParse(cause.response);
                    if (parseResult.success) {
                        throw new UserFacingError(
                            parseResult.data.data.message,
                        );
                    }
                }

                throw new ErrorWithCause(
                    "POST /api/v4/users/send-verification-code failed",
                    cause,
                );
            });
    }

    static async verifyCode(args: { passcode: string }) {
        return client
            .post<void>("/users/verify-code", args, {
                withCredentials: true,
            })
            .catch((cause) => {
                if (axios.isAxiosError(cause)) {
                    const parseResult = z
                        .union([
                            z
                                .object({
                                    status: z.literal(400),
                                    data: z.object({ message: z.string() }),
                                })
                                .transform(({ data }) => data.message),
                            z
                                .object({
                                    status: z.literal(422),
                                })
                                .transform(() => "Invalid code"),
                        ])
                        .safeParse(cause);
                    if (parseResult.success) {
                        throw new UserFacingError(parseResult.data);
                    }
                }

                throw new ErrorWithCause(
                    "POST /api/v4/users/verify-code failed",
                    cause,
                );
            });
    }

    static async checkVerification() {
        return client
            .get<void>("/users/check-verification", {
                withCredentials: true,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    "POST /api/v4/users/check-verification failed",
                    cause,
                );
            });
    }

    static async clearVerification() {
        return client
            .post<void>("/users/clear-verification", undefined, {
                withCredentials: true,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    "POST /api/v4/users/clear-verification failed",
                    cause,
                );
            });
    }

    static async getMyStoreRelationships() {
        return client
            .get<{
                myStoreInfo: StoreRelationshipInfo[];
            }>("/users/me/store-relationships")
            .catch((cause) => {
                throw new ErrorWithCause(
                    "GET /api/v4/users/me/store-relationships failed",
                    cause,
                );
            });
    }

    static async checkPhoneNumberAvailable(number: string) {
        return client
            .post<{ available: boolean }>("/users/is-phone-available", {
                phoneNumber: number,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    "GET /api/v4/users/is-phone-available failed",
                    cause,
                );
            });
    }

    static async getEmployees() {
        return client
            .get<{ employees: SeniorSupportUser[] }>("/users/employees")
            .catch((cause) => {
                throw new ErrorWithCause(
                    "GET /api/v4/users/employees failed",
                    cause,
                );
            });
    }

    static async updateEmployee(
        userId: string,
        request: {
            isSeniorSupport: boolean;
        },
    ) {
        return client
            .patch<{
                user: SeniorSupportUser;
            }>(`/users/employees/${userId}`, request)
            .catch((cause) => {
                throw new ErrorWithCause(
                    "PATCH /api/v4/users/employees/:id failed",
                    cause,
                );
            });
    }
}
