import { useLocation } from "react-router-dom";
import { useMemo } from "react";

import { CardDescription, CardTitle } from "src/@/components/ui/card";
import { cn } from "src/@/lib/utils";
import { INTERNAL_ROUTES } from "#navigation/route-data";
import { Alert, AlertDescription } from "src/@/components/ui/alert";

interface PageProps {
    title?: string | React.ReactNode;
    description?: string;
    children: React.ReactNode;
    className?: string;
    action?: React.ReactNode;
}

export function Page({
    title,
    description,
    children,
    className,
    action,
}: PageProps) {
    const location = useLocation();
    const isInternalRoute = useMemo(
        () =>
            INTERNAL_ROUTES.some((route) =>
                location.pathname.startsWith(route),
            ),
        [location.pathname],
    );

    return (
        <div
            className={cn(
                "max-w-5xl mb-8 space-y-6 grid grid-cols-1",
                className,
            )}
        >
            {isInternalRoute && (
                <Alert variant="warning" className="mb-4">
                    <AlertDescription>
                        This is an internal route only accessible to Snackpass
                        employees.
                    </AlertDescription>
                </Alert>
            )}

            {title || description || action ? (
                <div className="flex flex-row items-start justify-between">
                    <div className="space-y-2">
                        {title ? (
                            <CardTitle className="max-w-5xl text-2xl font-bold">
                                {title}
                            </CardTitle>
                        ) : null}
                        {description ? (
                            <CardDescription className="text-base text-muted-foreground">
                                {description}
                            </CardDescription>
                        ) : null}
                    </div>
                    <div>{action}</div>
                </div>
            ) : null}
            {children}
        </div>
    );
}
