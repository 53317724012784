import {
    Send,
    Bell,
    Mail,
    Gift,
    Download,
    Settings,
    MessageSquare,
} from "lucide-react";
import { Button } from "src/@/components/ui/button";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "src/@/components/ui/select";
import { useLoyaltyTiers } from "#dashboard/hooks/use-loyalty-tiers";
import { SectionHeader } from "./section-header";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "src/@/components/ui/table";
import { ChartContainer, ChartTooltip } from "src/@/components/ui/chart";
import {
    PieChart,
    Pie,
    Legend,
    CartesianGrid,
    XAxis,
    YAxis,
    Area,
    AreaChart,
} from "recharts";
import { useState } from "react";
import { ChartCard } from "./chart-card";

const loyaltyTiersChartConfig = {
    total: {
        label: "Loyalty Tier Distribution",
        color: `var(--chart-1)`,
    },
};

const loyaltyGrowthChartConfig = {
    all: {
        label: "All",
        color: `hsl(var(--chart-1))`,
    },
    basic: {
        label: "Basic",
        color: `hsl(var(--chart-2))`,
    },
    inspire: {
        label: "Inspire",
        color: `hsl(var(--chart-3))`,
    },
    indulge: {
        label: "Indulge",
        color: `hsl(var(--chart-4)  )`,
    },
    mindful: {
        label: "Mindful",
        color: `hsl(var(--chart-5))`,
    },
};

export function LoyaltyTiers() {
    const { tiers, isLoading: isLoyaltyLoading } = useLoyaltyTiers();
    const [selectedTier, setSelectedTier] = useState("all");

    // Format growth data for the chart
    const growthData =
        tiers?.growth?.map((item) => ({
            period: new Date(item.date).toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
            }),
            ...item,
        })) ?? [];

    // Create pie chart data
    const distributionData = [
        {
            name: "Basic",
            value: tiers?.basic ?? 0,
            fill: `hsl(var(--chart-2))`,
        },
        {
            name: "Inspire",
            value: tiers?.inspire ?? 0,
            fill: `hsl(var(--chart-3))`,
        },
        {
            name: "Indulge",
            value: tiers?.indulge ?? 0,
            fill: `hsl(var(--chart-4))`,
        },
        {
            name: "Mindful",
            value: tiers?.mindful ?? 0,
            fill: `hsl(var(--chart-5))`,
        },
    ];

    const TierActions = () => (
        <div className="flex flex-wrap gap-2">
            <Button
                variant="outline"
                size="sm"
                className="flex items-center gap-2"
                onClick={() => console.log(`Send campaign to ${selectedTier}`)}
            >
                <Send className="h-4 w-4" />
                Campaign
            </Button>
            <Button
                variant="outline"
                size="sm"
                className="flex items-center gap-2"
                onClick={() => console.log(`Send push to ${selectedTier}`)}
            >
                <Bell className="h-4 w-4" />
                Push
            </Button>
            <Button
                variant="outline"
                size="sm"
                className="flex items-center gap-2"
                onClick={() => console.log(`Send SMS to ${selectedTier}`)}
            >
                <MessageSquare className="h-4 w-4" />
                SMS
            </Button>
            <Button
                variant="outline"
                size="sm"
                className="flex items-center gap-2"
                onClick={() => console.log(`Send email to ${selectedTier}`)}
            >
                <Mail className="h-4 w-4" />
                Email
            </Button>
            <Button
                variant="outline"
                size="sm"
                className="flex items-center gap-2"
                onClick={() => console.log(`Send promo to ${selectedTier}`)}
            >
                <Gift className="h-4 w-4" />
                Promo
            </Button>
            <Button
                variant="outline"
                size="sm"
                className="flex items-center gap-2"
                onClick={() => console.log(`Export ${selectedTier} data`)}
            >
                <Download className="h-4 w-4" />
                Export
            </Button>
            <Button
                variant="outline"
                size="sm"
                className="flex items-center gap-2"
                onClick={() =>
                    console.log(`Configure ${selectedTier} settings`)
                }
            >
                <Settings className="h-4 w-4" />
                Settings
            </Button>
        </div>
    );

    return (
        <>
            <SectionHeader title="Loyalty Program" className="mt-6" />
            <div className="space-y-4">
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead>Tier</TableHead>
                            <TableHead>Members</TableHead>
                            <TableHead>Last Campaign</TableHead>
                            <TableHead>Response Rate</TableHead>
                            <TableHead>Referrals</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        <TableRow>
                            <TableCell>Basic</TableCell>
                            <TableCell>
                                <div className="flex items-center gap-1">
                                    <span>
                                        {(tiers?.basic ?? 0).toLocaleString()}
                                    </span>
                                    <span className="text-xs text-emerald-600">
                                        +5%
                                    </span>
                                </div>
                            </TableCell>
                            <TableCell>2 days ago</TableCell>
                            <TableCell>24%</TableCell>
                            <TableCell>
                                <div className="flex items-center gap-1">
                                    <span>127</span>
                                    <span className="text-xs text-emerald-600">
                                        +12%
                                    </span>
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Inspire</TableCell>
                            <TableCell>
                                <div className="flex items-center gap-1">
                                    <span>
                                        {(tiers?.inspire ?? 0).toLocaleString()}
                                    </span>
                                    <span className="text-xs text-emerald-600">
                                        +8%
                                    </span>
                                </div>
                            </TableCell>
                            <TableCell>1 day ago</TableCell>
                            <TableCell>32%</TableCell>
                            <TableCell>
                                <div className="flex items-center gap-1">
                                    <span>345</span>
                                    <span className="text-xs text-emerald-600">
                                        +28%
                                    </span>
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Indulge</TableCell>
                            <TableCell>
                                <div className="flex items-center gap-1">
                                    <span>
                                        {(tiers?.indulge ?? 0).toLocaleString()}
                                    </span>
                                    <span className="text-xs text-emerald-600">
                                        +12%
                                    </span>
                                </div>
                            </TableCell>
                            <TableCell>3 days ago</TableCell>
                            <TableCell>28%</TableCell>
                            <TableCell>
                                <div className="flex items-center gap-1">
                                    <span>234</span>
                                    <span className="text-xs text-emerald-600">
                                        +18%
                                    </span>
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Mindful</TableCell>
                            <TableCell>
                                <div className="flex items-center gap-1">
                                    <span>
                                        {(tiers?.mindful ?? 0).toLocaleString()}
                                    </span>
                                    <span className="text-xs text-emerald-600">
                                        +15%
                                    </span>
                                </div>
                            </TableCell>
                            <TableCell>5 days ago</TableCell>
                            <TableCell>35%</TableCell>
                            <TableCell>
                                <div className="flex items-center gap-1">
                                    <span>189</span>
                                    <span className="text-xs text-emerald-600">
                                        +15%
                                    </span>
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow className="font-medium border-t-2">
                            <TableCell>All Tiers</TableCell>
                            <TableCell>
                                <div className="flex items-center gap-1">
                                    <span>
                                        {(tiers?.total ?? 0).toLocaleString()}
                                    </span>
                                    <span className="text-xs text-emerald-600">
                                        +10%
                                    </span>
                                </div>
                            </TableCell>
                            <TableCell>1 day ago</TableCell>
                            <TableCell>30%</TableCell>
                            <TableCell>
                                <div className="flex items-center gap-1">
                                    <span>895</span>
                                    <span className="text-xs text-emerald-600">
                                        +18%
                                    </span>
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

                <div className="flex items-center gap-4 mt-6">
                    <Select
                        value={selectedTier}
                        onValueChange={setSelectedTier}
                    >
                        <SelectTrigger className="w-[180px]">
                            <SelectValue placeholder="Select tier" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="all">All Tiers</SelectItem>
                            <SelectItem value="basic">Basic Tier</SelectItem>
                            <SelectItem value="inspire">
                                Inspire Tier
                            </SelectItem>
                            <SelectItem value="indulge">
                                Indulge Tier
                            </SelectItem>
                            <SelectItem value="mindful">
                                Mindful Tier
                            </SelectItem>
                        </SelectContent>
                    </Select>
                    <TierActions />
                </div>
            </div>

            <div className="grid gap-4 md:grid-cols-2 mt-4">
                <ChartCard
                    title="Loyalty Tier Distribution"
                    isLoading={isLoyaltyLoading}
                    to="/guestbook/customers"
                >
                    <ChartContainer config={loyaltyTiersChartConfig}>
                        <PieChart height={300}>
                            <Pie
                                data={distributionData}
                                cx="50%"
                                cy="50%"
                                labelLine={false}
                                innerRadius="50%"
                                outerRadius="80%"
                                dataKey="value"
                            />
                            <Legend
                                layout="vertical"
                                align="right"
                                verticalAlign="middle"
                                iconType="circle"
                            />
                            <ChartTooltip
                                content={({ active, payload }) => {
                                    if (!active || !payload?.length)
                                        return null;
                                    const data = payload[0].payload;
                                    return (
                                        <div className="rounded-lg bg-popover p-2 shadow-md border">
                                            <div className="font-medium">
                                                {data.name}
                                            </div>
                                            <div className="text-muted-foreground">
                                                {data.value.toLocaleString()}{" "}
                                                members
                                            </div>
                                            <div className="text-muted-foreground">
                                                {Math.round(
                                                    (data.value /
                                                        distributionData.reduce(
                                                            (sum, item) =>
                                                                sum +
                                                                item.value,
                                                            0,
                                                        )) *
                                                        100,
                                                )}
                                                % of total
                                            </div>
                                        </div>
                                    );
                                }}
                            />
                        </PieChart>
                    </ChartContainer>
                </ChartCard>

                <ChartCard
                    title="Sales Volume by Tier"
                    isLoading={isLoyaltyLoading}
                    to="/guestbook/customers"
                >
                    <ChartContainer config={loyaltyGrowthChartConfig}>
                        <AreaChart
                            data={growthData}
                            margin={{
                                top: 20,
                                right: 12,
                                left: 12,
                                bottom: 20,
                            }}
                            height={300}
                        >
                            <CartesianGrid vertical={false} />
                            <XAxis
                                dataKey="period"
                                tickLine={false}
                                axisLine={false}
                                tickMargin={8}
                                tick={{ fontSize: 12 }}
                            />
                            <YAxis
                                tickLine={false}
                                axisLine={false}
                                tickMargin={8}
                                tickFormatter={(value) =>
                                    `$${(value / 1000).toFixed(1)}k`
                                }
                            />
                            <ChartTooltip
                                content={({ active, payload }) => {
                                    if (!active || !payload?.length)
                                        return null;
                                    return (
                                        <div className="rounded-lg bg-background p-2 shadow-md border border-border space-y-2">
                                            <div className="font-medium text-foreground">
                                                {payload[0].payload.period}
                                            </div>
                                            {payload.map((entry) => (
                                                <div
                                                    key={entry.dataKey}
                                                    className="text-muted-foreground flex items-center justify-between gap-4"
                                                >
                                                    <span
                                                        className="capitalize"
                                                        style={{
                                                            color: entry.color,
                                                        }}
                                                    >
                                                        {entry.dataKey}:
                                                    </span>
                                                    <span>
                                                        $
                                                        {(
                                                            entry.value ?? 0
                                                        ).toLocaleString()}
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    );
                                }}
                            />
                            <Area
                                type="monotone"
                                dataKey="all"
                                stackId="1"
                                stroke={`hsl(var(--chart-1))`}
                                fill={`hsl(var(--chart-1))`}
                                fillOpacity={0.6}
                            />
                            <Area
                                type="monotone"
                                dataKey="basic"
                                stackId="1"
                                stroke={`hsl(var(--chart-2))`}
                                fill={`hsl(var(--chart-2))`}
                                fillOpacity={0.6}
                            />
                            <Area
                                type="monotone"
                                dataKey="inspire"
                                stackId="1"
                                stroke={`hsl(var(--chart-3))`}
                                fill={`hsl(var(--chart-3))`}
                                fillOpacity={0.6}
                            />
                            <Area
                                type="monotone"
                                dataKey="indulge"
                                stackId="1"
                                stroke={`hsl(var(--chart-4))`}
                                fill={`hsl(var(--chart-4))`}
                                fillOpacity={0.6}
                            />
                            <Area
                                type="monotone"
                                dataKey="mindful"
                                stackId="1"
                                stroke={`hsl(var(--chart-5))`}
                                fill={`hsl(var(--chart-5))`}
                                fillOpacity={0.6}
                            />
                            <Legend />
                        </AreaChart>
                    </ChartContainer>
                </ChartCard>
            </div>
        </>
    );
}
