import { NumberInput } from "@tremor/react";

export type ItemsCountInputProps = {
    value: number;
    onChange: (value: number) => void;
};

export function ItemsCountInput({ value, onChange }: ItemsCountInputProps) {
    return (
        <NumberInput
            icon={() => <span className="absolute right-5">items</span>}
            value={value}
            onChange={(e) => onChange(parseInt(e.target.value || "0"))}
            placeholder="0"
            min={0}
            step={1}
            enableStepper={false}
            className="max-w-20"
        />
    );
}
