import { AxiosResponse } from "axios";
import { ErrorWithCause } from "src/utils/errors";
import {
    DecryptedPersonVerification,
    PayoutChannels,
    Statuses,
    StatusInfo,
    StatusReasons,
    StatusType,
    VerificationsResponse,
} from "#payouts/domain/types";

import { client } from "../client";

export const REPORTS_REQUEST_TIMEOUT = 120 * 1000;
const verificationsEndpoint = (
    storeId: string,
    route = "",
    type: "account" | "person" = "account",
) => `/verifications/${storeId}/${type}${route}`;

export class Verifications {
    static async status(storeId: string): Promise<
        AxiosResponse<{
            status: StatusInfo;
            type: StatusType;
        }>
    > {
        return client
            .get(`/verifications/${storeId}/status`, { withCredentials: true })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Verifications.status: GET to /verifications/:storeId/status failed`,
                    cause,
                );
            });
    }

    static async channel(
        storeId: string,
    ): VerificationsResponse<{ channel: PayoutChannels }> {
        const endpoint = `/verifications/${storeId}/payout/channel`;
        return client
            .get(endpoint, { withCredentials: true })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Verifications.channel: GET to ${endpoint} failed`,
                    cause,
                );
            });
    }

    static async create(storeId: string): VerificationsResponse {
        const endpoint = verificationsEndpoint(storeId);
        return client
            .post(endpoint, undefined, { withCredentials: true })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Verifications.create: POST to ${endpoint} failed`,
                    cause,
                );
            });
    }

    static async retrieve(storeId?: string): VerificationsResponse {
        return client
            .get(`/verifications/${storeId}/account`, { withCredentials: true })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Verifications.retrieve: GET to /verifications/:storeId/account failed`,
                    cause,
                );
            });
    }

    static async update(
        storeId: string,
        formData: FormData,
    ): VerificationsResponse {
        const endpoint = verificationsEndpoint(storeId);
        return client
            .patch(endpoint, formData, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                },
                withCredentials: true,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Verifications.update: PATCH to ${endpoint} failed`,
                    cause,
                );
            });
    }

    static async updateBusinessInformation(
        storeId: string,
        data: {
            name?: string;
            taxId?: string;
            merchantCategoryCode?: string;
            phone?: string;
        },
    ): VerificationsResponse {
        const endpoint = `/verifications/${storeId}/business-information`;
        return client
            .patch(endpoint, data, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                },
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Verifications.updateBusinessInformation: PATCH to ${endpoint} failed`,
                    cause,
                );
            });
    }

    static async finish(
        storeId: string,
        formData: FormData,
    ): VerificationsResponse {
        const endpoint = verificationsEndpoint(storeId, "/finish");
        return client
            .patch(endpoint, formData, { withCredentials: true })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Verifications.finish: PATCH to ${endpoint} failed`,
                    cause,
                );
            });
    }

    static async updatePerson(
        storeId: string,
        personId: string,
        formData: FormData,
    ): VerificationsResponse {
        const endpoint = verificationsEndpoint(
            storeId,
            `/${personId}`,
            "person",
        );
        return client
            .patch(endpoint, formData, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                },
                withCredentials: true,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Verifications.updatePerson: PATCH to ${endpoint} failed`,
                    cause,
                );
            });
    }

    static async createPerson(
        storeId: string,
        formData: FormData,
    ): VerificationsResponse {
        const endpoint = verificationsEndpoint(storeId, "", "person");
        return client
            .post(endpoint, formData, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                },
                withCredentials: true,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Verifications.createPerson: POST to ${endpoint} failed`,
                    cause,
                );
            });
    }

    static async removePerson(
        storeId: string,
        id: DecryptedPersonVerification["id"],
    ): VerificationsResponse {
        const endpoint = `/verifications/${storeId}/person/${id}`;
        return client
            .delete(endpoint, { withCredentials: true })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Verifications.removePerson: DELETE to ${endpoint} failed`,
                    cause,
                );
            });
    }

    static async setNewPrimary(
        storeId: string,
        id: DecryptedPersonVerification["id"],
    ): VerificationsResponse {
        const endpoint = `/verifications/${storeId}/person/${id}/new-primary`;
        return client
            .post(endpoint, { withCredentials: true })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Verifications.setNewPrimary: POST to ${endpoint} failed`,
                    cause,
                );
            });
    }

    static async sendVerificationCode(storeId: string) {
        return client
            .post<void>(
                "/verifications/auth/send-verification-code",
                { storeId },
                { withCredentials: true },
            )
            .catch((cause) => {
                throw new ErrorWithCause(
                    "POST /api/v4/verifications/send-verification-code failed",
                    cause,
                );
            });
    }

    static async verifyCode(args: {
        storeId: string;
        passcode: string;
        ttlHours: number;
    }) {
        return client
            .post<void>("/verifications/auth/verify-code", args, {
                withCredentials: true,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    "POST /api/v4/verifications/verify-code failed",
                    cause,
                );
            });
    }

    static async checkAuthToken(storeId: string) {
        return client
            .get<void>(`/verifications/auth/${storeId}/check`, {
                withCredentials: true,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    "POST /api/v4/verifications/verify-code failed",
                    cause,
                );
            });
    }

    static async removeBankAccount(storeId: string) {
        return client
            .patch<void>(`/verifications/${storeId}/account/remove-bank`)
            .catch((cause) => {
                throw new ErrorWithCause(
                    "PATCH /api/v4/verifications/remove-bank failed",
                    cause,
                );
            });
    }

    static async updateAccountStatus(
        storeId: string,
        status: Statuses,
        reason: StatusReasons,
        adminNote?: string,
    ): VerificationsResponse {
        return client
            .patch(`/verifications/${storeId}/payouts/status`, {
                status,
                reason,
                adminNote,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    "PATCH /api/v4/verifications/:storeId/payouts/status failed",
                    cause,
                );
            });
    }

    static async listTaxForms(storeId: string, taxYear: number) {
        return client
            .get<{ forms: string[] }>(`/verifications/${storeId}/tax-forms`, {
                withCredentials: true,
                params: { taxYear },
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    "GET /api/v4/verifications/:storeId/tax-forms failed",
                    cause,
                );
            });
    }

    static async downloadTaxForm(storeId: string, formId: string) {
        return client
            .get<Blob>(`/verifications/${storeId}/tax-forms/${formId}`, {
                responseType: "blob",
                withCredentials: true,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    "GET /api/v4/verifications/:storeId/tax-forms/:formId failed",
                    cause,
                );
            });
    }
}
