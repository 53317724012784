import { useMemo } from "react";
import { useOrders } from "../../orders-context";
import { SalesSummary } from "src/@/components/ui/sales-summary";
import { formatPurchaseProvider } from "#utils/helpers";

export const OrderSalesSummary = () => {
    const {
        startDate,
        endDate,
        purchaseReportTotals: { total: report, isLoading: reportLoading },
        purchaseReportRows: { data: rows, isLoading: rowsLoading },
    } = useOrders();

    const { topItems, categoryData, providerData } = useMemo(() => {
        if (!rows?.length) {
            return { topItems: [], categoryData: [], providerData: [] };
        }

        const itemMap = new Map<string, { orders: number; revenue: number }>();
        const categoryMap = new Map<
            string,
            { orders: number; revenue: number }
        >();
        const providerMap = new Map<
            string,
            { orders: number; revenue: number }
        >();

        // TODO: This is a temporary implementation to get the top items and category data
        // Loop through each order and its items
        rows.forEach((order) => {
            // Add provider aggregation using source instead of provider
            const providerName = formatPurchaseProvider(order.purchase);
            const existingProvider = providerMap.get(providerName) || {
                orders: 0,
                revenue: 0,
            };

            // Calculate total order revenue
            const orderRevenue = order.items.reduce(
                (sum, item) => sum + (item.totalPrice || 0),
                0,
            );

            // Update provider data
            providerMap.set(providerName, {
                orders: existingProvider.orders + 1,
                revenue: existingProvider.revenue + orderRevenue,
            });

            // Existing item and category aggregation
            order?.items.forEach((item) => {
                // Aggregate item data
                const existingItem = itemMap.get(item.name) || {
                    orders: 0,
                    revenue: 0,
                };
                const itemRevenue = item.totalPrice || 0;

                itemMap.set(item.name, {
                    orders: existingItem.orders + 1,
                    revenue: existingItem.revenue + itemRevenue,
                });

                // Aggregate category data
                const categoryName = item.category || "Uncategorized";
                const existingCategory = categoryMap.get(categoryName) || {
                    orders: 0,
                    revenue: 0,
                };

                categoryMap.set(categoryName, {
                    orders: existingCategory.orders + 1,
                    revenue: existingCategory.revenue + itemRevenue,
                });
            });
        });

        // Convert maps to sorted arrays
        const topItems = Array.from(itemMap.entries())
            .map(([name, data]) => ({
                name,
                count: data.orders,
                value: data.revenue,
            }))
            .sort((a, b) => b.value - a.value);

        const categoryData = Array.from(categoryMap.entries())
            .map(([name, data]) => ({
                name,
                count: data.orders,
                value: data.revenue,
            }))
            .sort((a, b) => b.value - a.value);

        const providerData = Array.from(providerMap.entries())
            .map(([name, data]) => ({
                name,
                count: data.orders,
                value: data.revenue,
            }))
            .sort((a, b) => b.value - a.value);

        return { topItems, categoryData, providerData };
    }, [rows]);

    // Create enhanced report with additional calculated fields
    const enhancedReport = useMemo(() => {
        if (!report) return undefined;

        return {
            ...report,
            cardCollected: report.netCardPayments,
            expectedCashCollected: report.netCashPayments,
            refundedAmount: report.grossSalesRefunds,
            amountDiscounted: report.grossSalesDiscounts,
        };
    }, [report]);

    const loadingStates = {
        sales: reportLoading,
        items: rowsLoading,
        categories: rowsLoading,
        providers: rowsLoading,
    };

    const getDateString = () => {
        if (startDate.year() !== endDate.year()) {
            return `${startDate.format("ll")} – ${endDate.format("ll")}`;
        } else if (startDate.isSame(endDate, "days")) {
            return `${endDate.format("LL")}`;
        }
        return `${startDate.format("MMM DD")} – ${endDate.format("ll")}`;
    };

    return (
        <SalesSummary
            dateString={getDateString()}
            report={enhancedReport}
            topItems={topItems}
            categoryData={categoryData}
            providerData={providerData}
            isLoading={loadingStates}
        />
    );
};
