import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { ReloadIcon } from "@radix-ui/react-icons";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "sonner";
import { ChannelType, FulfillmentType } from "@snackpass/snackpass-types";

import { useCreatePromotion } from "#new-promotions/hooks";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
} from "src/@/components/ui/form";
import { Input } from "src/@/components/ui/input";
import {
    ConditionScope,
    CreatePromotion,
} from "src/api/graphql/generated/types";
import { getActiveStoreId } from "src/redux/selectors";
import { Textarea } from "src/@/components/ui/textarea";
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "src/@/components/ui/card";
import { Button } from "src/@/components/ui/button";
import { Separator } from "src/@/components/ui/separator";
import { Routes } from "#navigation/routes";

import { SpecialConditionsInput } from "./ConditionsInput";
import { AdjustmentsInput } from "./AdjustmentsInput";
import { FulfillmentSelection } from "./FulfillmentSelection";
import { ChannelSelection } from "./ChannelSelection";
import { TriggerInput } from "./TriggerInput";

const formSchema = z.object({
    name: z.string(),
    description: z.string().optional(),
    instruction: z.string().optional(),
    imageUrl: z.string().optional(),
    fulfillments: z.array(z.nativeEnum(FulfillmentType)),
    channels: z.array(z.nativeEnum(ChannelType)),
    adjustments: z.array(
        z.object({
            scope: z.object({
                menu: z.boolean().optional(),
                categories: z.array(z.string()).optional(),
                products: z.array(z.string()).optional(),
            }),
            adjustment: z.object({
                price: z.number().int().optional(),
                discountPercent: z.number().int().optional(),
                discountFlat: z.number().int().optional(),
            }),
        }),
    ),
    conditions: z.array(
        z.object({
            maxItemsCondition: z
                .object({
                    scope: z.nativeEnum(ConditionScope),
                    blockOrdering: z.boolean(),
                    maxItems: z.number().int().min(0),
                })
                .optional(),
            itemCountCondition: z
                .object({
                    scope: z.nativeEnum(ConditionScope),
                    blockOrdering: z.boolean(),
                    productId: z.string().min(1),
                    count: z.number().int().min(0),
                })
                .optional(),
            categoryCountCondition: z
                .object({
                    scope: z.nativeEnum(ConditionScope),
                    blockOrdering: z.boolean(),
                    category: z.string().min(1),
                    count: z.number().int().min(0),
                })
                .optional(),
        }),
    ),
    trigger: z.object({
        cartItemTrigger: z.object({
            productId: z.string().min(1),
        }),
    }),
    isRunning: z.boolean(),
});

export function PromotionForm() {
    const { createPromotion, loading } = useCreatePromotion();
    const navigate = useNavigate();
    const storeId = useSelector(getActiveStoreId);
    const promotionForm = useForm<CreatePromotion>({
        defaultValues: {
            fulfillments: [],
            channels: [],
            adjustments: [{ scope: { menu: true }, adjustment: { price: 0 } }],
            conditions: [],
            isRunning: true,
        },
        resolver: zodResolver(formSchema),
    });

    const onSubmit = useCallback(
        async (input: CreatePromotion) =>
            (() => {
                console.log(input);
                createPromotion({ storeId, input })
                    .then(() => navigate(Routes.NewPromotion))
                    .catch((err) => toast.error(err.message));
            })(),
        [createPromotion, storeId, navigate],
    );

    return (
        <Form {...promotionForm}>
            <form
                onSubmit={promotionForm.handleSubmit(onSubmit)}
                className="flex flex-col gap-4"
            >
                <Card>
                    <CardHeader>
                        <CardTitle className="text-lg">
                            Promotion Options
                        </CardTitle>
                    </CardHeader>
                    <CardContent className="space-y-4">
                        <FormField
                            control={promotionForm.control}
                            name="name"
                            render={({ field, fieldState }) => (
                                <FormItem className="flex items-center justify-between">
                                    <CardTitle>
                                        <p>Name</p>
                                        {fieldState.error ? (
                                            <span className="text-sm font-normal text-critical-light">
                                                {fieldState.error.message}
                                            </span>
                                        ) : null}
                                    </CardTitle>
                                    <FormControl>
                                        <Input
                                            {...field}
                                            placeholder="Name"
                                            className="max-w-80"
                                        />
                                    </FormControl>
                                </FormItem>
                            )}
                        />
                        <Separator />
                        <FormField
                            control={promotionForm.control}
                            name="description"
                            render={({ field }) => (
                                <FormItem>
                                    <CardTitle>Description</CardTitle>
                                    <FormControl>
                                        <Textarea
                                            {...field}
                                            value={field.value ?? ""}
                                            placeholder="Description"
                                            className="resize-none outline-none"
                                            rows={3}
                                        />
                                    </FormControl>
                                </FormItem>
                            )}
                        />
                        <Separator />
                        <FulfillmentSelection />
                        <Separator />
                        <ChannelSelection />
                    </CardContent>
                </Card>
                <Card>
                    <CardHeader>
                        <CardTitle className="text-lg">Discounts</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <FormField
                            control={promotionForm.control}
                            name="adjustments"
                            render={({ field }) => (
                                <AdjustmentsInput {...field} />
                            )}
                        />
                    </CardContent>
                </Card>
                <Card>
                    <CardHeader>
                        <CardTitle className="text-lg">Conditions</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <FormField
                            control={promotionForm.control}
                            name="conditions"
                            render={({ field }) => (
                                <SpecialConditionsInput {...field} />
                            )}
                        />
                    </CardContent>
                </Card>
                <Card>
                    <CardHeader>
                        <CardTitle className="text-lg">Trigger</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <FormField
                            control={promotionForm.control}
                            name="trigger"
                            render={({ field, fieldState }) => (
                                <TriggerInput
                                    {...field}
                                    error={fieldState.error}
                                />
                            )}
                        />
                    </CardContent>
                </Card>
                <Button type="submit">
                    {loading ? (
                        <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                    ) : null}
                    Create
                </Button>
            </form>
        </Form>
    );
}
