import { useMemo } from "react";
import { Info, ChevronDown } from "lucide-react";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "src/@/components/ui/tooltip";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "src/@/components/ui/table";
import {
    Collapsible,
    CollapsibleContent,
    CollapsibleTrigger,
} from "src/@/components/ui/collapsible";
import { formatCurrency } from "src/@/lib/format-currency";
import { Button } from "src/@/components/ui/button";
import { Skeleton } from "src/@/components/ui/skeleton";
import { InfoCircledIcon } from "@radix-ui/react-icons";
import { useAppSelector } from "src/redux/hooks";
import { getUserIsSnackpassEmployee } from "src/redux/selectors";

type TableRow = {
    title: string;
    value: string;
    count?: string;
    toolTipText?: string;
    bold?: boolean;
    indented?: boolean;
};

// Create a new type that only includes the fields we need
export type SalesSummaryFields = {
    count: number;
    grossSalesAmount: number;
    refundedAmount: number;
    amountDiscounted: number;
    netSales: number;

    storeTaxesToRemit: number;
    store3PTaxesToRemit: number;
    storeTaxesWithheld: number;
    tip: number;
    expectedCashCollected: number;
    cardCollected: number;
    giftCardCredit: number;
    storeCredit: number;
    processingFee: number;
    snackpassFees: number;
    netCollected: number;
    totalCollected: number;
    totalSales: number;
    thirdPartyUnaccountedSales: number;
    unaccountedSales: number;
};

interface SalesReportProps {
    dateString: string;
    report?: SalesSummaryFields;
    topItems?: {
        name: string;
        count: number;
        value: number;
    }[];
    categoryData?: {
        name: string;
        count: number;
        value: number;
    }[];
    providerData?: {
        name: string;
        count: number;
        value: number;
    }[];
    isLoading?: {
        sales?: boolean;
        items?: boolean;
        categories?: boolean;
        providers?: boolean;
    };
}

const CollapsibleSection = ({
    title,
    children,
}: {
    title: string;
    children: React.ReactNode;
}) => {
    return (
        <Collapsible className="w-full rounded-md border h-fit">
            <CollapsibleTrigger asChild>
                <Button
                    variant="ghost"
                    className="w-full justify-between rounded-none border-b h-10 px-3"
                >
                    <span className="text-sm font-medium text-left">
                        {title}
                    </span>
                    <ChevronDown className="h-4 w-4 opacity-50" />
                </Button>
            </CollapsibleTrigger>
            <CollapsibleContent>
                <div className="max-h-[300px] overflow-y-auto">
                    <Table>
                        <TableBody>{children}</TableBody>
                    </Table>
                </div>
            </CollapsibleContent>
        </Collapsible>
    );
};

export const SalesSummary = ({
    dateString,
    report,
    topItems,
    categoryData,
    providerData,
    isLoading,
}: SalesReportProps) => {
    const salesRows = useSalesRows(report);
    const paymentsRows = usePaymentsRows(report);
    const categoryRows = useCategoryRows(categoryData);
    const itemRows = useItemRows(topItems);
    const providerRows = useProviderRows(providerData);

    const hasDiscrepancy = useMemo(() => {
        if (!report) return false;

        const expectedTotal =
            report.grossSalesAmount -
            report.refundedAmount -
            report.amountDiscounted;
        const actualTotal = report.netSales;

        // Check if there's more than a $0.01 difference to account for rounding
        return Math.abs(expectedTotal - actualTotal) > 0.01;
    }, [report]);

    const hasTotalSalesDiscrepancy = useMemo(() => {
        if (!report) return false;

        const expectedTotal =
            report.netSales +
            report.storeTaxesToRemit +
            report.store3PTaxesToRemit +
            report.tip;
        const actualTotal = report.totalSales;

        // Check if there's more than a $0.01 difference to account for rounding
        return Math.abs(expectedTotal - actualTotal) > 0.01;
    }, [report]);

    return (
        <div className="space-y-6">
            <div className="space-y-2">
                <h4 className="text-xl">Sales Summary</h4>
                <p className="text-sm text-muted-foreground">{dateString}</p>
            </div>

            {hasDiscrepancy && (
                <div className="flex items-center gap-2 px-3 py-2 text-sm border rounded-md bg-muted">
                    <InfoCircledIcon className="h-4 w-4 flex-shrink-0" />
                    <p className="text-muted-foreground">
                        Reported refund and discount amounts may be affected in
                        special cases from partial refunds and special
                        discounts. We are currently fixing this — thank you for
                        your patience. Your gross, net sales and other fields
                        are accurate and not affected.
                    </p>
                </div>
            )}

            {hasTotalSalesDiscrepancy && (
                <div className="flex items-center gap-2 px-3 py-2 text-sm border rounded-md bg-muted">
                    <InfoCircledIcon className="h-4 w-4 flex-shrink-0" />
                    <p className="text-muted-foreground">
                        There appears to be a discrepancy in the total sales
                        calculation. The sum of net sales, taxes, and tips does
                        not match the total sales amount. Our team is
                        investigating this issue.
                    </p>
                </div>
            )}

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 w-full auto-rows-min">
                {/* Sales Section */}
                <div className="w-full rounded-md border h-fit">
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableHead>Sales</TableHead>
                                <TableHead className="text-right"></TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {isLoading?.sales ? (
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        <Skeleton className="h-[200px]" />
                                    </TableCell>
                                </TableRow>
                            ) : (
                                salesRows.map((row) => (
                                    <TableRow key={row.title}>
                                        <TableCell className="min-w-[200px]">
                                            <div className="flex items-center gap-1">
                                                <span
                                                    className={`truncate ${
                                                        row.bold
                                                            ? "font-medium"
                                                            : ""
                                                    } ${
                                                        row.indented
                                                            ? "pl-4"
                                                            : ""
                                                    }`}
                                                >
                                                    {row.title}
                                                </span>
                                                {row.toolTipText && (
                                                    <TooltipProvider>
                                                        <Tooltip>
                                                            <TooltipTrigger>
                                                                <Info className="h-3 w-3" />
                                                            </TooltipTrigger>
                                                            <TooltipContent>
                                                                <p className="max-w-xs">
                                                                    {
                                                                        row.toolTipText
                                                                    }
                                                                </p>
                                                            </TooltipContent>
                                                        </Tooltip>
                                                    </TooltipProvider>
                                                )}
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            className={`text-right ${
                                                row.bold ? "font-medium" : ""
                                            }`}
                                        >
                                            {row.value}
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </div>

                {/* Payments Section */}
                <div className="w-full rounded-md border h-fit">
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableHead>Payments</TableHead>
                                <TableHead className="text-right"></TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {isLoading?.sales ? (
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        <Skeleton className="h-[200px]" />
                                    </TableCell>
                                </TableRow>
                            ) : (
                                paymentsRows.map((row) => (
                                    <TableRow key={row.title}>
                                        <TableCell className="min-w-[200px]">
                                            <div className="flex items-center gap-1">
                                                <span
                                                    className={`truncate ${
                                                        row.bold
                                                            ? "font-medium"
                                                            : ""
                                                    } ${
                                                        row.indented
                                                            ? "pl-4"
                                                            : ""
                                                    }`}
                                                >
                                                    {row.title}
                                                </span>
                                                {row.toolTipText && (
                                                    <TooltipProvider>
                                                        <Tooltip>
                                                            <TooltipTrigger>
                                                                <Info className="h-3 w-3" />
                                                            </TooltipTrigger>
                                                            <TooltipContent>
                                                                <p className="max-w-xs">
                                                                    {
                                                                        row.toolTipText
                                                                    }
                                                                </p>
                                                            </TooltipContent>
                                                        </Tooltip>
                                                    </TooltipProvider>
                                                )}
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            className={`text-right ${
                                                row.bold ? "font-medium" : ""
                                            }`}
                                        >
                                            {row.value}
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </div>

                {/* Category Sales Section */}
                <CollapsibleSection title="Category Sales">
                    <TableRow>
                        <TableHead>Category</TableHead>
                        <TableHead className="text-right">Count</TableHead>
                        <TableHead className="text-right">Sales</TableHead>
                    </TableRow>
                    {isLoading?.categories ? (
                        <TableRow>
                            <TableCell colSpan={3}>
                                <Skeleton className="h-[200px]" />
                            </TableCell>
                        </TableRow>
                    ) : (
                        categoryRows.map((row) => (
                            <TableRow key={row.title}>
                                <TableCell className="min-w-[200px]">
                                    {row.title}
                                </TableCell>
                                <TableCell className="text-right">
                                    {row.count}
                                </TableCell>
                                <TableCell className="text-right">
                                    {row.value}
                                </TableCell>
                            </TableRow>
                        ))
                    )}
                </CollapsibleSection>

                {/* Item Sales Section */}
                <CollapsibleSection title="Item Sales">
                    <TableRow>
                        <TableHead>Item</TableHead>
                        <TableHead className="text-right">Count</TableHead>
                        <TableHead className="text-right">Sales</TableHead>
                    </TableRow>
                    {isLoading?.items ? (
                        <TableRow>
                            <TableCell colSpan={3}>
                                <Skeleton className="h-[200px]" />
                            </TableCell>
                        </TableRow>
                    ) : (
                        itemRows.map((row) => (
                            <TableRow key={row.title}>
                                <TableCell className="min-w-[200px]">
                                    {row.title}
                                </TableCell>
                                <TableCell className="text-right">
                                    {row.count}
                                </TableCell>
                                <TableCell className="text-right">
                                    {row.value}
                                </TableCell>
                            </TableRow>
                        ))
                    )}
                </CollapsibleSection>

                {/* Provider Sales Section */}
                <CollapsibleSection title="Provider Sales">
                    <TableRow>
                        <TableHead>Provider</TableHead>
                        <TableHead className="text-right">Count</TableHead>
                        <TableHead className="text-right">Sales</TableHead>
                    </TableRow>
                    {isLoading?.providers ? (
                        <TableRow>
                            <TableCell colSpan={3}>
                                <Skeleton className="h-[200px]" />
                            </TableCell>
                        </TableRow>
                    ) : (
                        providerRows.map((row) => (
                            <TableRow key={row.title}>
                                <TableCell className="min-w-[200px]">
                                    {row.title}
                                </TableCell>
                                <TableCell className="text-right">
                                    {row.count}
                                </TableCell>
                                <TableCell className="text-right">
                                    {row.value}
                                </TableCell>
                            </TableRow>
                        ))
                    )}
                </CollapsibleSection>
            </div>
        </div>
    );
};
const useSalesRows = function (report?: SalesSummaryFields) {
    const isSnackpassEmployee = useAppSelector(getUserIsSnackpassEmployee);
    return useMemo(
        () =>
            report
                ? [
                      {
                          title: "Orders",
                          value: report.count.toLocaleString(),
                          toolTipText: "Total number of orders in this period",
                      },
                      {
                          title: "Gross Sales",
                          value: formatCurrency(report.grossSalesAmount),
                          toolTipText:
                              "Total sales before any discounts, refunds, or adjustments",
                          bold: true,
                      },
                      {
                          title: "Refunds",
                          value: formatCurrency(report.refundedAmount),
                          toolTipText: "Total amount refunded to customers",
                      },
                      {
                          title: "Discounts & Comps",
                          value: formatCurrency(report.amountDiscounted),
                          toolTipText:
                              "Total discounts and complimentary items given to customers",
                      },
                      {
                          title: "Net Sales",
                          value: formatCurrency(report.netSales),
                          toolTipText:
                              "Gross sales minus refunds and discounts",
                          bold: true,
                      },
                      {
                          title: "Taxes",
                          value: formatCurrency(report.storeTaxesToRemit),
                          toolTipText:
                              "Taxes you are responsible for remitting to the state. This does not include taxes from third party orders.",
                      },
                      // Only show taxes withheld if non-zero
                      ...(report.storeTaxesWithheld !== 0
                          ? [
                                {
                                    title: "Taxes Withheld",
                                    value: formatCurrency(
                                        report.storeTaxesWithheld,
                                    ),
                                    toolTipText:
                                        "Taxes withheld from sales by the state.",
                                },
                            ]
                          : []),
                      // Only show third party taxes if non-zero
                      ...(report.store3PTaxesToRemit !== 0
                          ? [
                                {
                                    title: "Third Party Taxes",
                                    value: formatCurrency(
                                        report.store3PTaxesToRemit,
                                    ),
                                    toolTipText:
                                        "Taxes from integrated third party orders.",
                                },
                            ]
                          : []),
                      {
                          title: "Tips",
                          value: formatCurrency(report.tip),
                          toolTipText: "Total tips received from customers",
                          indented: false,
                      },
                      {
                          title: "Total Sales",
                          value: formatCurrency(report.totalSales),
                          toolTipText:
                              "Net sales plus tips and taxes collected",
                          bold: true,
                      },
                      ...(report.thirdPartyUnaccountedSales &&
                      isSnackpassEmployee
                          ? [
                                {
                                    title: "Uncategorized Third Party Sales [SP Only",
                                    value: formatCurrency(
                                        report.thirdPartyUnaccountedSales,
                                    ),
                                    toolTipText:
                                        "Unaccounted sales from delivery integrations through Deliverect",
                                },
                            ]
                          : []),
                      ...(report.unaccountedSales && isSnackpassEmployee
                          ? [
                                {
                                    title: "Uncategorized Sales [SP Only]",
                                    value: formatCurrency(
                                        report.unaccountedSales,
                                    ),
                                    toolTipText:
                                        "Sales that don't fit into any other category",
                                },
                            ]
                          : []),
                      // {
                      //     title: "Customer → Snackpass Fees",
                      //     value: `${formatCurrency(
                      //         report?.customerToSnackpassFees,
                      //     )}`,
                      //     toolTipText:
                      //         "Any fees charged directly to your customer by Snackpass.",
                      // },
                  ]
                : [],
        [report],
    );
};

const usePaymentsRows = function (report?: SalesSummaryFields) {
    return useMemo(
        () =>
            report
                ? [
                      {
                          // TODO: ADD BACK!
                          title: "Total Collected",
                          //   value: formatCurrency(report?.totalCollected),
                          value: formatCurrency(report?.totalSales),
                          toolTipText: "Your net sales plus tips and taxes.",
                          bold: true,
                      },
                      {
                          title: "Card",
                          //   value: formatCurrency(report?.cardCollected),
                          value: formatCurrency(
                              report?.totalSales -
                                  report?.expectedCashCollected -
                                  report?.giftCardCredit -
                                  report?.storeCredit,
                          ),
                          toolTipText:
                              "Total payments received via credit/debit cards",
                          indented: true,
                      },
                      {
                          title: "Cash",
                          value: formatCurrency(
                              report?.expectedCashCollected ?? 0,
                          ),
                          toolTipText:
                              "The amount of money we expect you to have accepted in cash in your store for sales occurring in this time period.",
                          indented: true,
                      },
                      {
                          title: "Gift Card Redemption",
                          value: formatCurrency(report?.giftCardCredit ?? 0),
                          toolTipText: "The amount of gift card redemptions",
                          indented: true,
                      },
                      {
                          title: "Store Credit Redemption",
                          value: formatCurrency(report?.storeCredit ?? 0),
                          toolTipText:
                              "The amount of store credit given to customers",
                          indented: true,
                      },
                      {
                          title: "Processing Fees",
                          value: `(${formatCurrency(report?.processingFee ?? 0)})`,
                          toolTipText:
                              "Processing fees charged by payment providers",
                      },
                      {
                          title: "Snackpass Fees",
                          value: `(${formatCurrency(report?.snackpassFees)})`,
                          toolTipText:
                              "Fees charged by Snackpass, such as commission, fax, and delivery fees.",
                      },
                      {
                          title: "Net Collected",
                          //   value: formatCurrency(report?.netCollected),
                          value: formatCurrency(
                              report?.totalSales -
                                  report?.snackpassFees -
                                  report?.processingFee,
                          ),
                          toolTipText: "Total collected minus fees.",
                          bold: true,
                      },
                      // TODO: add expected payout back
                  ]
                : [],
        [report],
    );
};

const useCategoryRows = function (
    categoryData?: {
        name: string;
        count: number;
        value: number;
    }[],
) {
    return useMemo(
        () =>
            categoryData?.map((category) => ({
                title: category.name,
                count: category.count?.toLocaleString() ?? "0",
                value: formatCurrency(category.value),
            })) ?? [],
        [categoryData],
    );
};

const useItemRows = function (
    topItems?: {
        name: string;
        count: number;
        value: number;
    }[],
) {
    return useMemo(
        () =>
            topItems?.map((item) => ({
                title: item.name,
                count: item.count.toLocaleString(),
                value: formatCurrency(item.value),
            })) ?? [],
        [topItems],
    );
};

const useProviderRows = function (
    providerData?: {
        name: string;
        count: number;
        value: number;
    }[],
) {
    return useMemo(
        () =>
            providerData?.map((provider) => ({
                title: provider.name,
                count: provider.count.toLocaleString(),
                value: formatCurrency(provider.value),
            })) ?? [],
        [providerData],
    );
};
