import { useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "sonner";

import { getActiveStoreId } from "src/redux/selectors";
import { useGetPromotionsQuery } from "src/api/graphql/generated/types";

export function usePromotions() {
    const storeId = useSelector(getActiveStoreId);

    const { data, loading, error } = useGetPromotionsQuery({
        variables: { storeId },
    });

    useEffect(() => {
        if (error) {
            toast.error(`Error fetching promotions`, {
                description: error.message,
            });
        }
    }, [error]);

    return { data: data?.promotions ?? [], loading, error };
}
